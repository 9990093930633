import { Button, ButtonGroup, Spinner, Table, OverlayTrigger } from 'react-bootstrap';
import { Deck } from '../types/AppState';
import { CardType } from '../types/ExodusCard';
import Updates from '../Updates';
import CardDetailTooltip from './CardDetailTooltip';

export interface DeckListViewProps {
  cards: Deck;
  updates: Updates;
}

export default function DeckListView({ cards, updates }: DeckListViewProps) {
  return (
    <span>
      <Table size="sm" striped>
        <thead>
          <tr>
            <td />
            <td>#</td>
            <td>Name</td>
            <td>Type</td>
            <td>Rarity</td>
          </tr>
        </thead>
        <tbody>
          {cards?.size ? (
            Array.from(cards.values()).map((card) => (
              <tr key={card.card_id}>
                <td>
                  <ButtonGroup aria-label="Add or remove cards">
                    <Button
                      size="sm"
                      variant="outline-secondary"
                      onClick={(e) => {
                        updates.removeCard(card);
                      }}
                    >
                      -
                    </Button>
                    <Button
                      size="sm"
                      variant="outline-secondary"
                      onClick={(e) => {
                        updates.addCard(card);
                      }}
                    >
                      +
                    </Button>
                  </ButtonGroup>
                </td>
                <td>{card.quantity}</td>
                <OverlayTrigger
                  placement="bottom"
                  trigger={['click', 'hover']}
                  overlay={(props) => <CardDetailTooltip popOverProps={props} card={card} />}
                >
                  <td>
                    <span>{card.name}</span>
                  </td>
                </OverlayTrigger>
                <td>
                  {card.card_type && card.card_type.join(' / ')}
                  {card.card_type && card.card_type.indexOf(CardType.Creature) >= 0
                    ? ' - ' + card.creature_type?.join(', ')
                    : ''}
                </td>
                <td>{card.rarity}</td>
              </tr>
            ))
          ) : cards ? (
            <tr>
              <td />
              <td />
              <td>
                <i>Cards you add will show here.</i>
              </td>
              <td />
              <td />
            </tr>
          ) : undefined}
        </tbody>
      </Table>
      {!cards && (
        <div className="d-flex justify-content-center">
          <Spinner animation="border" role="status">
            <span className="sr-only">Loading...</span>
          </Spinner>
        </div>
      )}
    </span>
  );
}
