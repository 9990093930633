import { indiciesForLength } from '../functional/ranges';
// Note: Logic assumes this is odd, to center active index.
// Test *will* throw if it's not odd.
export const MAX_NUM_BUTTONS = 7;

export class Item {
  readonly type = 'Item';
  constructor(public readonly index: number) {}
}
export class Ellipsis {
  readonly type = 'Ellipsis';
  constructor(public readonly key: string) {}
}
export function DefinePaginationForNPages({
  activePageIndex,
  numPages,
}: {
  activePageIndex: number;
  numPages: number;
}): Array<Item | Ellipsis> {
  const lastIndex = numPages - 1;
  const numButtons = Math.min(numPages, MAX_NUM_BUTTONS);
  const lastButton = numButtons - 1;
  const center = numButtons >> 1;
  const showLeftEllipsis = numButtons >= 5 && activePageIndex > center;
  const showRightEllipsis = numButtons >= 5 && lastIndex - activePageIndex > center;
  return indiciesForLength(numButtons).map((i) =>
    i === 0
      ? new Item(0)
      : i === lastButton
      ? new Item(lastIndex)
      : (i === 1 && showLeftEllipsis)
      ? new Ellipsis('left')
      : (i === lastButton - 1 && showRightEllipsis)
      ? new Ellipsis('right')
      : new Item(
          Math.min(
            numPages + (i - numButtons),
            Math.max(i, activePageIndex + (i - center))
          )
        )
  );
}
