import { Edition, Rarity, SetName } from '../types/ExodusCard';
import {
  TTSScrapedSet,
  TTSScrapedFlag,
  TTSScrapedCard,
} from '../types/TabletopSimulatorScraped';
import { ExodusCardsJson } from './ExodusCards';
import { TTSScrapedJson } from './TabletopSimulatorScrapedJson';
import {
  ExpectEmptySearch,
  ExpectEmptyTTS,
  Filter,
  GroupBy,
  IsMatch,
  MatchAllToOneTTS,
  runMatcher,
  sequence,
} from './ExodusCardToTTSMatcherCore';

const correctTTSNameMap = new Map()
  .set('Alana, the Star Formation', 'Alana the Star Formation')
  .set(`Eroda's Asendance`, `Eroda's Ascendance`)
  .set(`Eeventide’s Enclave`, `Eeventide's Enclave`)
  .set('Appariton Grove', 'Apparition Grove')
  .set('Teathered to Eeventide', 'Tethered to Eeventide')
  .set('Efil, the Everlasting Empress', 'Efil the Everlasting Empress')
  .set('Knight of the Frozen Claymore', 'Knight of Frozen Claymore')
  .set(
    `Stellarfalls, the Cardsmith's Haven`,
    `Stellarfalls, the Cardsmiths' Haven`
  )
  .set('Eruc, Archive’s Aegis', `Eruc, Archive's Aegis`);
const twoCardPromos: Array<{ name: string; id1: string; id2: string }> = [
  {
    name: 'Thoughts of Progress',
    id1: 'card_428',
    id2: 'card_429',
  },
];
const duplicatePromos: Set<string> = new Set([
  'Uncharted Territory',
  `Drifter's Beginning`,
  'Glimpse of the Sanctuary',
  `Victoria's Ultimatum`,
  'Uncommon Alliance',
  'Tethered to Eeventide',
]);
const promoDoesntExistOnTTSYet: Set<string> = new Set([
  'Draoh, Untold Liberator',
  'Tachyon Tourbillon',
  'Palisades the Eternal Ember',
  'Ghost Ship Galleon',
]);
const ManualMapping: Map<string, Map<number, Set<string>>> = new Map()
  .set(
    'Dimensional Dragon',
    new Map()
      .set(41500, new Set(['card_393']))
      .set(42900, new Set(['card_397']))
  )
  .set(
    'Dimensional Desperado',
    new Map()
      .set(41400, new Set(['card_394']))
      .set(42800, new Set(['card_396']))
  )
  .set(
    'Invoke',
    new Map()
      // Dragon
      .set(1300, new Set(['card_113', 'card_134']))
      // Angel
      .set(
        3700,
        new Set([
          'card_154',
          'card_93',
          // Default these pre-dragon/angel arts to Angel
          'card_17',
          'card_55',
        ])
      )
  )
  .set(
    'Leech',
    new Map()
      // Dragon
      .set(1400, new Set(['card_114', 'card_135']))
      // Angel
      .set(
        3800,
        new Set([
          'card_155',
          'card_94',
          // Default these pre-dragon/angel arts to Angel
          'card_18',
          'card_56',
        ])
      )
  )
  .set(
    'Chains',
    new Map()
      // Dragon
      .set(700, new Set(['card_111', 'card_132']))
      // Angel
      .set(
        2800,
        new Set([
          'card_152',
          'card_91',
          // Default these pre-dragon/angel arts to Angel
          'card_15',
          'card_53',
        ])
      )
  );
const IsPromoOrLimited = GroupBy(
  {
    searchBy: (c): 'Promo' | 'Limited' | 'Neither' =>
      c.edition === Edition.Promo
        ? 'Promo'
        : c.edition === Edition.Limited
        ? 'Limited'
        : 'Neither',
    ttsBy: (c) =>
      c.set === TTSScrapedSet.PROMOS
        ? 'Promo'
        : c.set === TTSScrapedSet['SKETCH CARDS']
        ? 'Limited'
        : 'Neither',
  },
  IsMatch
);
export const ExodusCardToTTSMatcher = sequence(
  GroupBy(
    {
      searchBy: (c) => c.name,
      ttsBy: (c) => correctTTSNameMap.get(c.name) ?? c.name,
    },
    sequence(
      IsMatch,
      Filter(
        {
          search: (c) =>
            twoCardPromos.some(
              (promo) => c.name === promo.name && c.card_id === promo.id1
            ),
          tts: (c) => c.flags.some((f) => f === 'Promo'),
        },
        IsMatch
      ),
      Filter(
        {
          search: (c) =>
            twoCardPromos.some(
              (promo) => c.name === promo.name && c.card_id === promo.id2
            ),
          tts: (c) => c.flags.some((f) => f === 'Promo 2'),
        },
        IsMatch
      ),
      Filter(
        {
          search: (c) =>
            c.edition === Edition.Promo && duplicatePromos.has(c.name),
          tts: (_c) => true,
        },
        MatchAllToOneTTS
      ),
      Filter(
        {
          search: (c) =>
            promoDoesntExistOnTTSYet.has(c.name) &&
            c.edition !== Edition.Limited,
          tts: (c) => c.flags.every((f) => f !== TTSScrapedFlag.Sketch),
        },
        MatchAllToOneTTS
      ),
      GroupBy(
        {
          searchBy: (c) => c.rarity === Rarity['Secret Rare'],
          ttsBy: (c) => c.flags.some((f) => f === TTSScrapedFlag.SR),
        },
        sequence(IsMatch, IsPromoOrLimited)
      ),
      IsPromoOrLimited,
      Filter(
        {
          search: (c) => c.name === 'Anathema',
          tts: (c) => c.flags.some((f) => f === TTSScrapedFlag['Double Sided']),
        },
        IsMatch
      ),
      Filter(
        {
          search: (_) => true,
          tts: (c) =>
            c.name === 'Anathema' || c.name === 'Oterecnoc, End of the World',
        },
        ExpectEmptySearch
      ),
      Filter(
        {
          search: (c) =>
            c.rarity === Rarity['Harmony Rare'] || c.name === 'Energy',
          tts: (_) => true,
        },
        ExpectEmptyTTS
      ),
      GroupBy(
        {
          searchBy: (c) => c.set_name.toString(),
          ttsBy: (c) => c.set.toString(),
        },
        sequence(IsMatch, IsPromoOrLimited)
      ),
      // Do this twice, before and after ManualMapping, to catch any
      // changes to ids in the ManualMapping before it tries to apply them.
      Filter(
        {
          search: (c) => c.set_name === SetName['Birth of Creation'],
          tts: (_c) => true,
        },
        MatchAllToOneTTS
      ),
      sequence(
        ...Array.from(ManualMapping.entries()).map(([name, idMap]) =>
          sequence(
            ...Array.from(idMap.entries()).map(([ttsID, searchIDs]) =>
              Filter(
                {
                  search: (c) => c.name === name && searchIDs.has(c.card_id),
                  tts: (c) => c.name === name && c.ttsID === ttsID,
                },
                MatchAllToOneTTS
              )
            )
          )
        )
      ),
      Filter(
        {
          search: (c) => c.set_name === SetName['Birth of Creation'],
          tts: (_c) => true,
        },
        MatchAllToOneTTS
      )
    )
  )
);

export const ExodusCardToTTS: Map<string, TTSScrapedCard> = new Map(
  runMatcher(ExodusCardToTTSMatcher, {
    searchCards: ExodusCardsJson,
    ttsCards: TTSScrapedJson,
  }).matches.map(({ searchCard, ttsCard }) => [searchCard.card_id, ttsCard])
);
