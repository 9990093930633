import { Button, Dropdown, DropdownButton, FormControl, InputGroup } from 'react-bootstrap';
import { getCardCount } from '../DeckState';
import exportDeck from '../services/TTSExportService';
import { Deck, SavedDecks } from '../types/AppState';
import Updates from '../Updates';

export interface DeckMenuProps {
  cards: Deck;
  savedDecks: SavedDecks;
  deckName: string;
  updates: Updates;
}

export default function DeckMenu({ cards, savedDecks, deckName, updates }: DeckMenuProps) {
  const activeSavedDeck = savedDecks.has(deckName) ? new Map(savedDecks.get(deckName)) : undefined;
  const areChangesSaved =
    cards &&
    activeSavedDeck &&
    Array.from(cards.values())
      .concat(Array.from(activeSavedDeck.values()))
      .every((card) => getCardCount(card, cards) === getCardCount(card, activeSavedDeck));
  return (
    <InputGroup>
      <InputGroup.Prepend>
        <Button onClick={updates.newDeck}>New Deck</Button>
      </InputGroup.Prepend>
      <FormControl
        type="text"
        placeholder="Enter a deck name..."
        value={deckName || ''}
        onChange={(e) => updates.setDeckName(e.target.value)}
      />
      <InputGroup.Append>
        <Button
          onClick={(e) => {
            updates.saveDeck(deckName);
          }}
          variant={cards && (cards.size || activeSavedDeck) && deckName && !areChangesSaved ? 'warning' : undefined}
        >
          Save Deck
        </Button>
      </InputGroup.Append>
      <DropdownButton as={InputGroup.Append} title="Load Deck...">
        {Array.from(savedDecks.keys()).map((deckName) => (
          <Dropdown.Item
            key={deckName}
            onSelect={() => {
              updates.loadDeck(deckName);
            }}
          >
            {deckName}
          </Dropdown.Item>
        ))}
      </DropdownButton>
      <DropdownButton as={InputGroup.Append} title="Export...">
        {cards && (
          <>
            <Dropdown.Item
              onSelect={() => {
                updates.createPermalink();
              }}
            >
              Share Deck
            </Dropdown.Item>
            <Dropdown.Item
              onSelect={() => {
                exportDeck(cards, deckName);
              }}
            >
              Export to TTS
            </Dropdown.Item>
          </>
        )}
      </DropdownButton>
    </InputGroup>
  );
}
