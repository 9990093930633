import { Form, Button, InputGroup } from 'react-bootstrap';
import repeat from '../functional/repeat';
import { Deck } from '../types/AppState';
import Updates from '../Updates';
import React from 'react';

export interface ShareDeckPermalinkProps {
  cards: Deck;
  deckName: string;
  version: number;
  updates: Updates;
}

export default function ShareDeckPermalink({ cards, deckName, version, updates }: ShareDeckPermalinkProps) {
  const url = new URL(window.location.href);
  url.search = '';
  url.searchParams.set('v', String(version));
  url.searchParams.set('dname', deckName);
  url.searchParams.set(
    'cards',
    Array.from(cards.values())
      .map((c) => repeat(c, c.quantity))
      .flat()
      .map((c) => c.card_id.match('card_(.*)')?.[1])
      .filter((c) => c)
      .toString()
  );

  const urlText = React.useRef<HTMLInputElement>(null);

  const copy = () => {
    urlText.current?.select();
    document.execCommand('copy');
    updates.hideModal();
  };

  return (
    <InputGroup>
      <Form.Control
        readOnly={true}
        ref={urlText}
        value={url.toString()}
      />
      <InputGroup.Append>
        <Button onClick={copy}>Copy to Clipboard</Button>
      </InputGroup.Append>
    </InputGroup>
  );
}
