import React from 'react';
import { Row, Col } from 'react-bootstrap';
import CardImageView from './CardImageView';

import { CardType, ExodusCard } from '../types/ExodusCard';

export interface CardDetailViewProps {
  card: ExodusCard;
}

export default function CardDetailView({ card }: CardDetailViewProps) {
  return (
    <Row className="flex-wrap">
      <Col md={6} className="d-flex flex-column align-content-center">
        <CardImageView card={card} style={{ width: '100%' }} isFlipped={false} />
        {card.s3_backside_large_image_name ? (
          <div className="mt-2 d-flex flex-column align-items-center">
            <h5>--Back--</h5>
            <CardImageView card={card} style={{ width: '100%' }} isFlipped={true} />
          </div>
        ) : undefined}
      </Col>
      <Col md={6} className="pt-3">
        <em>
          {card.card_type?.join(' / ')}
          {card.card_type && card.card_type.indexOf(CardType.Creature) >= 0
            ? ' - ' + card.creature_type?.join(', ')
            : ''}
        </em>
        <br />
        {card.effect}
        <br />
        <br />
        <div dangerouslySetInnerHTML={{ __html: card.ruling || '' }} />
      </Col>
    </Row>
  );
}
