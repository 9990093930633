export const AboutSection = () => (
  <>
    <i>
      This is an unofficial website for the Exodus TCG that supports searching
      and deckbuilding.
      <br />
      <br />
      If you have questions or comments about this site, bring it up in #general
      channel of the <a href="https://discord.gg/WTZ8Swv">official discord</a>.
    </i>
    <br />
    <br />
    <b>
      Exodus The Trading Card Game is owned by Existence Games. Take a look and
      support them at their (amazing) official website over at{' '}
      <a href="https://www.exodus-cards.com">exodus-cards.com</a>!
    </b>
  </>
);
export default AboutSection;
