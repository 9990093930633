import { Row, Col, Popover, PopoverProps } from 'react-bootstrap';
import CardImageView from './CardImageView';
import '../styles/CardDetailTooltip.css';
import { ExodusCard } from '../types/ExodusCard';

export interface CardDetailTooltipProps {
  card: ExodusCard;
  popOverProps: Omit<PopoverProps, 'id'>;
}

export default function CardDetailTooltip({ card, popOverProps }: CardDetailTooltipProps) {
  return (
    <Popover id={`detail-${card.name}`} {...popOverProps}>
      <Popover.Content>
        <Row>
          <Col xs={4}>
            <CardImageView card={card} isSmall={true} className="img-responsive" />
            {card.s3_backside_large_image_name ? (
              <div className="mt-2 d-flex flex-column align-items-center">
                <p className="mb-1">--Back--</p>
                <CardImageView card={card} isSmall={true} isFlipped={true} />
              </div>
            ) : undefined}
          </Col>
          <Col xs={8} className="cardText">
            <em>
              {card.card_type}
              {card.card_type?.[0] === 'Creature' ? ' - ' + card.creature_type : ''}
            </em>
            <br />
            {card.effect}
          </Col>
        </Row>
      </Popover.Content>
    </Popover>
  );
}
