// Responses
interface ExodusCardWithoutEnums {
  readonly is_default_edition: boolean;
  readonly artist?: string;
  readonly card_database_link?: string;
  readonly creature_type?: string[];
  readonly s3_large_image_name?: string;
  readonly card_id: string;
  readonly large_image_url: string;
  readonly ruling?: string;
  readonly small_image_url?: string;
  readonly name: string;
  readonly creature_type_count?: number;
  readonly effect?: string;
  readonly set_number?: string;
  readonly s3_backside_large_image_name?: string;
  readonly backside_large_image_url?: string;
}
export type RawExodusCard = ExodusCardWithoutEnums & {
  readonly edition: keyof typeof Edition;
  readonly card_type?: Array<keyof typeof CardType>;
  readonly foil_only?: keyof typeof FoilOnly;
  readonly set_name: keyof typeof SetName;
  readonly rarity?: keyof typeof Rarity;
};
export type ExodusCard = ExodusCardWithoutEnums & {
  readonly edition: Edition;
  readonly card_type?: CardType[];
  readonly foil_only?: FoilOnly;
  readonly set_name: SetName;
  readonly rarity?: Rarity;
};

export enum CardType {
  Creature = 'Creature',
  'Eeventide Field' = 'Eeventide Field',
  Energy = 'Energy',
  Symmetry = 'Symmetry',
  Drifter = 'Drifter',
};
export function toCardType(s: keyof typeof CardType): CardType {
  return CardType[s];
}

export enum Edition {
  First = 'First',
  Second = 'Second',
  Limited = 'Limited',
  Premiere = 'Premiere',
  Promo = 'Promo',
  Unlimited = 'Unlimited',
}
export function toEdition(s: keyof typeof Edition): Edition {
  return Edition[s];
}

export enum FoilOnly {
  No = 'No',
  Yes = 'Yes',
}
export function toFoilOnly(s: keyof typeof FoilOnly): FoilOnly {
  return FoilOnly[s];
}

export enum Rarity {
  Common = 'Common',
  'Harmony Rare' = 'Harmony Rare',
  Rare = 'Rare',
  'Secret Rare' = 'Secret Rare',
  Uncommon = 'Uncommon',
  Box = 'Box',
}
export function toRarity(s: keyof typeof Rarity): Rarity {
  return Rarity[s];
}

export enum SetName {
  'Birth of Creation' = 'Birth of Creation',
  'Converging Chasms' = 'Converging Chasms',
  'Crystal Forge' = 'Crystal Forge',
  'Galaxy Tides' = 'Galaxy Tides',
  'The Dimension That Disappeared' = 'The Dimension That Disappeared',
  'Threshold of Time' = 'Threshold of Time',
}
export function toSetName(s: keyof typeof SetName): SetName {
  return SetName[s];
}
