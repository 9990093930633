import React from 'react';
import { ProgressBar } from 'react-bootstrap';
import '../styles/CardSearchView.css';
import repeat from '../functional/repeat';
import { Deck } from '../types/AppState';

export interface DeckStatsViewProps {
  cards: Deck;
}

export default function DeckStatsView({ cards }: DeckStatsViewProps) {
  const barColors = ['#02b875', '#78c3fb', '#395c6b', '#b37ba4', '#d99ac5'];

  function exists<T>(x: T): Boolean {
    return x !== undefined;
  }

  function group<T>(list: T[]): Map<T, number> {
    const map = new Map();
    for (const key of list) {
      map.set(key, 1 + (map.get(key) ?? 0));
    }
    return map;
  }

  const dupCards = Array.from(cards.values())
    .map((c) => repeat(c, c.quantity))
    .flat();

  const creatureTypes = dupCards
    .map((c) => c.creature_type)
    .filter(exists)
    .flat();

  const cardTypes = dupCards.map((c) => c.card_type).flat();
  const rarities = dupCards.map((c) => c.rarity);

  return (
    <span>
      Total Cards: {dupCards.length}
      <br />
      Creature Types:
      <ProgressBar>
        {Array.from(group(creatureTypes).entries())
          .filter((e): e is [string, number] => e[0] !== undefined)
          .map((k, i) => (
            <ProgressBar
              key={i}
              style={{ backgroundColor: barColors[i % 5] }}
              now={(k[1] * 100) / creatureTypes.length}
              label={k[0] + ': ' + k[1]}
            ></ProgressBar>
          ))}
      </ProgressBar>
      <br />
      Card Types:
      <ProgressBar>
        {Array.from(group(cardTypes).entries()).map((k, i) => (
          <ProgressBar
            key={i}
            style={{ backgroundColor: barColors[i + (1 % 5)] }}
            now={(k[1] * 100) / cardTypes.length}
            label={k[0] + ': ' + k[1]}
          ></ProgressBar>
        ))}
      </ProgressBar>
      <br />
      Rarities:
      <ProgressBar>
        {Array.from(group(rarities).entries()).map((k, i) => (
          <ProgressBar
            key={i}
            style={{ backgroundColor: barColors[i + (2 % 5)] }}
            now={(k[1] * 100) / dupCards.length}
            label={k[0] + ': ' + k[1]}
          ></ProgressBar>
        ))}
      </ProgressBar>
      <br />
    </span>
  );
}
