import { Maybe } from '../types/Maybe';
import { IStateMonad, TStateMonad, StateMonadT, runState } from './State';
import { TErrorMonad, ErrorMonad, runError, MError, MSuccess } from './Error';

export const ParserMonad: IMonad = StateMonadT(ErrorMonad());

export type TState = { text: string; index: number };
export type TError = { expected: string; index: number }[];
type IMonad = IStateMonad<TState, TError>;
export type TParserMonad<V> = TStateMonad<TState, TError, V>;

const M = ParserMonad;

const currentText = M.using(M.currentState, ({ text, index }) =>
  M.returning(text)
);
const currentIndex = M.using(M.currentState, ({ text, index }) =>
  M.returning(index)
);
const consumeText = (textToConsume: string) =>
  M.using(M.currentState, ({ text, index }) =>
    M.setState({
      text: text.slice(textToConsume.length),
      index: index + textToConsume.length,
    })
  );
export const ParseErrorExpected = <V>(expected: string): TParserMonad<V> =>
  M.using(currentIndex, (index) => M.lift(MError([{ expected, index }])));

export const maybeParse = <V>(
  parser: TParserMonad<V>,
  text: string
): TErrorMonad<TError, { state: TState; value: V }> =>
  runState(parser, { text, index: 0 });
export const parse = <V>(
  parser: TParserMonad<V>,
  text: string,
  ifError?: Maybe<(e: TError) => Maybe<V>>
): Maybe<V> =>
  runError(maybeParse(parser, text), {
    ifSuccess: ({ state, value: ast }) => ast,
    ifError: ifError ?? (() => undefined),
  });

export const Exact = <Str extends string>(str: Str): TParserMonad<Str> =>
  M.using(currentText, text =>
    text.startsWith(str)
      ? M.using(consumeText(str), () =>
          M.returning(str)
        )
      : ParseErrorExpected(JSON.stringify(str))
  );


// Match a regular expression.
// >> parse(Regex(/[0-1]+/), '123.56') = '123'
export const Regex = (
  r: RegExp,
  options?: Maybe<{
    group?: Maybe<number>;
    expected?: Maybe<string>;
  }>
): TParserMonad<string> =>
  M.using(currentText, (text: string) => {
    const match = text.match(new RegExp(`^(${r.source})`, r.flags));
    return match
      ? M.using(consumeText(match[0]), () =>
          M.returning(
            match[
              options?.group === undefined
                ? 1
                : options?.group === 0
                ? 0
                : options?.group + 1
            ]
          )
        )
      : ParseErrorExpected(options?.expected ?? '/' + r.source + '/');
  });

export const EOF: TParserMonad<undefined> = M.using(currentText, (text) =>
  text === '' ? M.returning(undefined) : ParseErrorExpected('End-Of-File')
);

// Catch errors throw by parser and return undefined instead.
// >> parse(Try(Regex(/0/), M.returning('2')), '1') = '2'
export const Try = <V>(
  parser: TParserMonad<V>,
  default_: TParserMonad<V>
): TParserMonad<V> =>
  M.lower(parser, (maybeState, inject) =>
    runError<
      TError,
      { state: TState; value: V },
      TErrorMonad<TError, { state: TState; value: V }>
    >(maybeState, {
      ifSuccess: MSuccess,
      ifError: (_) => inject(default_),
    })
  );

// Parse multiple independent nodes in sequence.
// >> parse( Seq(Regex(/0/), Regex(/1/)), '012' )
// = ['0', '1']
export const Seq = <A, B>(
  fst: TParserMonad<A>,
  snd: TParserMonad<B>
): TParserMonad<[A, B]> =>
  M.using(fst, (fstResult) =>
    M.using(snd, (sndResult) => M.returning([fstResult, sndResult]))
  );

// Parse one of multiple nodes. First success is returned.
// If all fail, then their errors are merged.
// >> parse( Either( Regex(/0/), Regex(/1/) ), '12' )
// = '1'
export const Either = <V>(
  fst: TParserMonad<V>,
  snd: TParserMonad<V>
): TParserMonad<V> =>
  M.lower(fst, (fstResults, inject) =>
    runError<
      TError,
      { state: TState; value: V },
      TErrorMonad<TError, { state: TState; value: V }>
    >(fstResults, {
      ifSuccess: MSuccess,
      ifError: (fstErrors) =>
        inject(
          M.lower(snd, (sndResult, inject) =>
            runError<
              TError,
              { state: TState; value: V },
              TErrorMonad<TError, { state: TState; value: V }>
            >(sndResult, {
              ifSuccess: MSuccess,
              ifError: (sndErrors) =>
                inject(M.lift(MError(fstErrors.concat(sndErrors)))),
            })
          )
        ),
    })
  );
