import { listGroupBy } from '../functional/groupBy';
import union from '../functional/union';
import { TTSScrapedCard } from '../types/TabletopSimulatorScraped';
import { ExodusCard } from '../types/ExodusCard';

export interface State<T> {
  readonly valuesLeft: T;
  readonly failures: Group;
  readonly matches: Match[];
}
export interface Match {
  readonly searchCard: ExodusCard;
  readonly ttsCard: TTSScrapedCard;
}
export interface Group {
  readonly searchCards: ExodusCard[];
  readonly ttsCards: TTSScrapedCard[];
}

export type Matcher<T> = (_: Group) => State<T>;
export class Finished {
  static Inst = new Finished();
}

export const NoFailures: Group = { searchCards: [], ttsCards: [] };

export const sequence: (
  ...matchers: Array<Matcher<Finished>>
) => Matcher<Finished> =
  (...matchers) =>
  (input) =>
    matchers.reduce(
      ({ valuesLeft, matches, failures }: State<Finished>, nextMatcher) => {
        const state = nextMatcher(failures);
        matches.push(...state.matches);
        return { valuesLeft, matches, failures: state.failures };
      },
      { valuesLeft: Finished.Inst, matches: [], failures: input }
    );

export const within: (
  outer: Matcher<Group[]>,
  inner: Matcher<Finished>
) => Matcher<Finished> = (outer, inner) => (input) => {
  const outerState = outer(input);
  const matches = Array.from(outerState.matches);
  const failures: Group = {
    searchCards: Array.from(outerState.failures.searchCards),
    ttsCards: Array.from(outerState.failures.ttsCards),
  };
  for (const v of outerState.valuesLeft) {
    const innerState = inner(v);
    matches.push(...innerState.matches);
    failures.searchCards.push(...innerState.failures.searchCards);
    failures.ttsCards.push(...innerState.failures.ttsCards);
  }
  return { valuesLeft: Finished.Inst, matches, failures };
};

export function runMatcher(
  matcher: Matcher<Finished>,
  input: Group
): State<Finished> {
  return matcher(input);
}

export const MatchAllToOneTTS: Matcher<Finished> = ({
  searchCards,
  ttsCards,
}) =>
  ttsCards.length === 1 && searchCards.length > 0
    ? {
        matches: searchCards.map((searchCard) => ({
          searchCard,
          ttsCard: ttsCards[0],
        })),
        valuesLeft: Finished.Inst,
        failures: NoFailures,
      }
    : Fail({ searchCards, ttsCards });

export const ExpectEmptyTTS: Matcher<Finished> = ({ searchCards, ttsCards }) =>
  ttsCards.length === 0
    ? { matches: [], valuesLeft: Finished.Inst, failures: NoFailures }
    : Fail({ searchCards, ttsCards });
export const ExpectEmptySearch: Matcher<Finished> = ({
  searchCards,
  ttsCards,
}) =>
  searchCards.length === 0
    ? { matches: [], valuesLeft: Finished.Inst, failures: NoFailures }
    : Fail({ searchCards, ttsCards });

export const Fail: Matcher<Finished> = ({ searchCards, ttsCards }) => ({
  matches: [],
  valuesLeft: Finished.Inst,
  failures: { searchCards, ttsCards },
});

export const IsMatch: Matcher<Finished> = ({ searchCards, ttsCards }) =>
  searchCards.length === 1 && ttsCards.length === 1
    ? {
        matches: [
          {
            searchCard: searchCards[0],
            ttsCard: ttsCards[0],
          },
        ],
        valuesLeft: Finished.Inst,
        failures: NoFailures,
      }
    : Fail({ searchCards, ttsCards });

export interface GroupByParams<K> {
  searchBy: (_: ExodusCard) => K;
  ttsBy: (_: TTSScrapedCard) => K;
}
export const GroupByMatcher =
  <K>({ searchBy, ttsBy }: GroupByParams<K>) =>
  ({ searchCards, ttsCards }: Group): State<Group[]> => {
    const searchByKey = listGroupBy(searchBy, searchCards);
    const ttsByKey = listGroupBy(ttsBy, ttsCards);
    return {
      valuesLeft: Array.from(union(searchByKey.keys(), ttsByKey.keys())).map(
        (key) => ({
          searchCards: searchByKey.get(key) ?? [],
          ttsCards: ttsByKey.get(key) ?? [],
        })
      ),
      matches: [],
      failures: NoFailures,
    };
  };
export const GroupBy = <K>(
  params: GroupByParams<K>,
  inner: Matcher<Finished>
) => within(GroupByMatcher(params), inner);

export interface FilterParams {
  search: (_: ExodusCard) => boolean;
  tts: (_: TTSScrapedCard) => boolean;
}
export const FilterMatcher =
  ({ search, tts }: FilterParams) =>
  ({ searchCards, ttsCards }: Group): State<Group[]> => ({
    valuesLeft: [
      {
        searchCards: searchCards.filter(search),
        ttsCards: ttsCards.filter(tts),
      },
    ],
    failures: {
      searchCards: searchCards.filter((c) => !search(c)),
      ttsCards: ttsCards.filter((c) => !tts(c)),
    },
    matches: [],
  });
export const Filter = (params: FilterParams, inner: Matcher<Finished>) =>
  within(FilterMatcher(params), inner);
