export function listGroupBy<T, K>(
  getKey: (_: T) => K,
  list: T[],
): Map<K,T[]> {
  const map = new Map();
  for (const elem of list) {
    const key = getKey(elem);
    const existingList = map.get(key);
    if (!existingList) {
      map.set(key, [elem]);
    } else {
      existingList.push(elem);
    }
  }
  return map;
}

export function mapGroupBy<T, OldK, NewK>(
  getKey: (_: T) => NewK,
  inputMap: Map<OldK,T>,
): Map<NewK,Map<OldK,T>> {
  const groupedMap = new Map();
  for (const [oldKey, elem] of inputMap.entries()) {
    const newKey = getKey(elem);
    const existingList = groupedMap.get(newKey);
    if (!existingList) {
      groupedMap.set(newKey, new Map().set(oldKey,elem));
    } else {
      existingList.set(oldKey, elem);
    }
  }
  return groupedMap;
}
