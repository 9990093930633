import {
  TTSScrapedCard,
  RawTTSScrapedCard,
  toScrapedFlag,
  toScrapedSet,
} from '../types/TabletopSimulatorScraped';
export const TTSScrapedJson: TTSScrapedCard[] = ((cards: RawTTSScrapedCard[]) =>
  cards.map((c) => ({
    ...c,
    flags: c.flags.map(toScrapedFlag),
    set: toScrapedSet(c.set),
  })))([
  {
    ttsID: 36300,
    name: 'Dimensional Desperado',
    flags: ['Promo'],
    set: 'PROMOS',
    FaceURL:
      'http://cloud-3.steamusercontent.com/ugc/768365276449010379/05771A1DB864C1C79451F08CA2BEC1EDAA9F7844/',
    BackURL:
      'http://cloud-3.steamusercontent.com/ugc/768365068750455723/A2E8BBF482ED5B11D5A19D86A38A6FB03ED2A592/',
  },
  {
    ttsID: 36400,
    name: 'Dimensional Dragon',
    flags: ['Promo'],
    set: 'PROMOS',
    FaceURL:
      'http://cloud-3.steamusercontent.com/ugc/768365276449012313/BCC49AF1B76B4C32AF099E866C371A3F16EC6014/',
    BackURL:
      'http://cloud-3.steamusercontent.com/ugc/768365068750455723/A2E8BBF482ED5B11D5A19D86A38A6FB03ED2A592/',
  },
  {
    ttsID: 36000,
    name: 'Cataclysm',
    flags: ['Promo'],
    set: 'PROMOS',
    FaceURL:
      'http://cloud-3.steamusercontent.com/ugc/768365276449003610/942B0AF64F890F32E35E038AA6626E3C629551F7/',
    BackURL:
      'http://cloud-3.steamusercontent.com/ugc/768365068750455723/A2E8BBF482ED5B11D5A19D86A38A6FB03ED2A592/',
  },
  {
    ttsID: 37000,
    name: 'Angelic Apparition',
    flags: ['Promo'],
    set: 'PROMOS',
    FaceURL:
      'http://cloud-3.steamusercontent.com/ugc/768365276449027910/8C10D91B7D57A127E26F25B50DD5D54B9AD47AE4/',
    BackURL:
      'http://cloud-3.steamusercontent.com/ugc/768365068750455723/A2E8BBF482ED5B11D5A19D86A38A6FB03ED2A592/',
  },
  {
    ttsID: 37100,
    name: 'Mistrai Dragon',
    flags: ['Promo'],
    set: 'PROMOS',
    FaceURL:
      'http://cloud-3.steamusercontent.com/ugc/768365276449029632/9B12B4B5277BBBBBA03838A4E84F7AD2A7C7F784/',
    BackURL:
      'http://cloud-3.steamusercontent.com/ugc/768365068750455723/A2E8BBF482ED5B11D5A19D86A38A6FB03ED2A592/',
  },
  {
    ttsID: 36900,
    name: 'Twisted Vortex',
    flags: ['Promo'],
    set: 'PROMOS',
    FaceURL:
      'http://cloud-3.steamusercontent.com/ugc/768365276449025884/2FAD5BDC0E739CF704C149D84FCD5931144CE4F4/',
    BackURL:
      'http://cloud-3.steamusercontent.com/ugc/768365068750455723/A2E8BBF482ED5B11D5A19D86A38A6FB03ED2A592/',
  },
  {
    ttsID: 35800,
    name: 'Airship Corrodestria',
    flags: ['Promo'],
    set: 'PROMOS',
    FaceURL:
      'http://cloud-3.steamusercontent.com/ugc/768365276449000963/349FD5434ECD28D87BC926D804E87105E192D522/',
    BackURL:
      'http://cloud-3.steamusercontent.com/ugc/768365068750455723/A2E8BBF482ED5B11D5A19D86A38A6FB03ED2A592/',
  },
  {
    ttsID: 35900,
    name: 'Amberblade Dragon',
    flags: ['Promo'],
    set: 'PROMOS',
    FaceURL:
      'http://cloud-3.steamusercontent.com/ugc/768365276449002273/F193484C781350997D8CE267D763DC0D5AEB665A/',
    BackURL:
      'http://cloud-3.steamusercontent.com/ugc/768365068750455723/A2E8BBF482ED5B11D5A19D86A38A6FB03ED2A592/',
  },
  {
    ttsID: 36800,
    name: 'Timeless Angel of Eternity',
    flags: ['Promo'],
    set: 'PROMOS',
    FaceURL:
      'http://cloud-3.steamusercontent.com/ugc/768365276449024315/E4AFA1B8757346D7FD3585795EA9F4B8271FF858/',
    BackURL:
      'http://cloud-3.steamusercontent.com/ugc/768365068750455723/A2E8BBF482ED5B11D5A19D86A38A6FB03ED2A592/',
  },
  {
    ttsID: 36100,
    name: 'Cerulean Stormstream Dragon',
    flags: ['Promo'],
    set: 'PROMOS',
    FaceURL:
      'http://cloud-3.steamusercontent.com/ugc/768365276449004740/52308F758EFDED05D4DA549B5569101C9E83E6C4/',
    BackURL:
      'http://cloud-3.steamusercontent.com/ugc/768365068750455723/A2E8BBF482ED5B11D5A19D86A38A6FB03ED2A592/',
  },
  {
    ttsID: 36600,
    name: 'Thoughts of Progress',
    flags: ['Promo 2'],
    set: 'PROMOS',
    FaceURL:
      'http://cloud-3.steamusercontent.com/ugc/768365276449015161/31DE568F766A4D901C23D62F8E4BD6CEB0B40E2A/',
    BackURL:
      'http://cloud-3.steamusercontent.com/ugc/768365068750455723/A2E8BBF482ED5B11D5A19D86A38A6FB03ED2A592/',
  },
  {
    ttsID: 37200,
    name: 'Thoughts of Progress',
    flags: ['Promo'],
    set: 'PROMOS',
    FaceURL:
      'http://cloud-3.steamusercontent.com/ugc/768365276449031388/2E1A9CBAE876D6F779214BD497427A49B472BE8B/',
    BackURL:
      'http://cloud-3.steamusercontent.com/ugc/768365068750455723/A2E8BBF482ED5B11D5A19D86A38A6FB03ED2A592/',
  },
  {
    ttsID: 36500,
    name: 'Harmonious Angel, the Forthcoming',
    flags: ['Promo'],
    set: 'PROMOS',
    FaceURL:
      'http://cloud-3.steamusercontent.com/ugc/768365276449013447/42075F8A57DF97D3DFF562EE0C576B095C66EC9B/',
    BackURL:
      'http://cloud-3.steamusercontent.com/ugc/768365068750455723/A2E8BBF482ED5B11D5A19D86A38A6FB03ED2A592/',
  },
  {
    ttsID: 36700,
    name: "Thoughtstream's Edge",
    flags: [],
    set: 'PROMOS',
    FaceURL:
      'http://cloud-3.steamusercontent.com/ugc/768365276449016511/5CA3EC2D369EF2E429BA67E50E8C5FF908174122/',
    BackURL:
      'http://cloud-3.steamusercontent.com/ugc/768365068750455723/A2E8BBF482ED5B11D5A19D86A38A6FB03ED2A592/',
  },
  {
    ttsID: 36200,
    name: 'Cloudburst Spiral',
    flags: ['Promo'],
    set: 'PROMOS',
    FaceURL:
      'http://cloud-3.steamusercontent.com/ugc/768365276449009480/D45E377C7078C5847CAAD550D9906E39F9F76B8F/',
    BackURL:
      'http://cloud-3.steamusercontent.com/ugc/768365068750455723/A2E8BBF482ED5B11D5A19D86A38A6FB03ED2A592/',
  },
  {
    ttsID: 37600,
    name: 'Uncharted Territory',
    flags: ['Promo'],
    set: 'PROMOS',
    FaceURL:
      'http://cloud-3.steamusercontent.com/ugc/768365276449169993/1C3F22E16E5266C610D73E6885EEEFE54C6150EF/',
    BackURL:
      'http://cloud-3.steamusercontent.com/ugc/768365068750455723/A2E8BBF482ED5B11D5A19D86A38A6FB03ED2A592/',
  },
  {
    ttsID: 37300,
    name: "Drifter's Beginning",
    flags: ['Promo'],
    set: 'PROMOS',
    FaceURL:
      'http://cloud-3.steamusercontent.com/ugc/768365276449033193/2EFF79F7B5C67970B0810A9797CBC2BA25C98067/',
    BackURL:
      'http://cloud-3.steamusercontent.com/ugc/768365068750455723/A2E8BBF482ED5B11D5A19D86A38A6FB03ED2A592/',
  },
  {
    ttsID: 37500,
    name: 'Teathered to Eeventide',
    flags: ['Promo'],
    set: 'PROMOS',
    FaceURL:
      'http://cloud-3.steamusercontent.com/ugc/768365276449039724/13A156C859DDFED2AB8B58A8E24DCD5B8E68BCD8/',
    BackURL:
      'http://cloud-3.steamusercontent.com/ugc/768365068750455723/A2E8BBF482ED5B11D5A19D86A38A6FB03ED2A592/',
  },
  {
    ttsID: 37400,
    name: 'Glimpse of the Sanctuary',
    flags: ['Promo'],
    set: 'PROMOS',
    FaceURL:
      'http://cloud-3.steamusercontent.com/ugc/768365276449035322/3B6FFDFF6F744F8747DBA2AE0947F3394FB85B41/',
    BackURL:
      'http://cloud-3.steamusercontent.com/ugc/768365068750455723/A2E8BBF482ED5B11D5A19D86A38A6FB03ED2A592/',
  },
  {
    ttsID: 37800,
    name: "Victoria's Ultimatum",
    flags: ['Promo'],
    set: 'PROMOS',
    FaceURL:
      'http://cloud-3.steamusercontent.com/ugc/768365276449173404/58DDB05977F3B1E571E35475018E776BBB9BEA26/',
    BackURL:
      'http://cloud-3.steamusercontent.com/ugc/768365068750455723/A2E8BBF482ED5B11D5A19D86A38A6FB03ED2A592/',
  },
  {
    ttsID: 37700,
    name: 'Uncommon Alliance',
    flags: ['Promo'],
    set: 'PROMOS',
    FaceURL:
      'http://cloud-3.steamusercontent.com/ugc/768365276449171284/E5025BC996223C0B0BABABEFC16DC2CEBE086D3B/',
    BackURL:
      'http://cloud-3.steamusercontent.com/ugc/768365068750455723/A2E8BBF482ED5B11D5A19D86A38A6FB03ED2A592/',
  },
  {
    ttsID: 38400,
    name: 'Starship Galacia',
    flags: ['Promo'],
    set: 'PROMOS',
    FaceURL:
      'http://cloud-3.steamusercontent.com/ugc/768365276449182309/010D7159F7928E53D78A965CDAFA2031AA033870/',
    BackURL:
      'http://cloud-3.steamusercontent.com/ugc/768365068750455723/A2E8BBF482ED5B11D5A19D86A38A6FB03ED2A592/',
  },
  {
    ttsID: 38700,
    name: 'Existence of Unknown Origins',
    flags: ['Promo'],
    set: 'PROMOS',
    FaceURL:
      'http://cloud-3.steamusercontent.com/ugc/768365276449185839/57A8B4C020D479B6135985D850F37199E12680A6/',
    BackURL:
      'http://cloud-3.steamusercontent.com/ugc/768365068750455723/A2E8BBF482ED5B11D5A19D86A38A6FB03ED2A592/',
  },
  {
    ttsID: 38300,
    name: 'Nebulawood Barrier',
    flags: ['Promo'],
    set: 'PROMOS',
    FaceURL:
      'http://cloud-3.steamusercontent.com/ugc/768365276449180634/04E7B40C5CC321E743FD3291ED3E2D683A8C7BD2/',
    BackURL:
      'http://cloud-3.steamusercontent.com/ugc/768365068750455723/A2E8BBF482ED5B11D5A19D86A38A6FB03ED2A592/',
  },
  {
    ttsID: 38200,
    name: 'Mortarbloom Liege',
    flags: ['Promo'],
    set: 'PROMOS',
    FaceURL:
      'http://cloud-3.steamusercontent.com/ugc/768365276449179568/949B14E630AAA3E96705834525D02BB6A22A1EA5/',
    BackURL:
      'http://cloud-3.steamusercontent.com/ugc/768365068750455723/A2E8BBF482ED5B11D5A19D86A38A6FB03ED2A592/',
  },
  {
    ttsID: 38100,
    name: 'Handbell Dancer Gravitas',
    flags: ['Promo'],
    set: 'PROMOS',
    FaceURL:
      'http://cloud-3.steamusercontent.com/ugc/768365276449177354/0BCC79102F09DE30F26EC9FDF08EF706EE4A521F/',
    BackURL:
      'http://cloud-3.steamusercontent.com/ugc/768365068750455723/A2E8BBF482ED5B11D5A19D86A38A6FB03ED2A592/',
  },
  {
    ttsID: 38000,
    name: 'Guild Warfare',
    flags: ['Promo'],
    set: 'PROMOS',
    FaceURL:
      'http://cloud-3.steamusercontent.com/ugc/768365276449176225/251F0DEC8822736A8B32BDB62F6A60A54E812297/',
    BackURL:
      'http://cloud-3.steamusercontent.com/ugc/768365068750455723/A2E8BBF482ED5B11D5A19D86A38A6FB03ED2A592/',
  },
  {
    ttsID: 38600,
    name: 'Dragonis Warden',
    flags: ['Promo'],
    set: 'PROMOS',
    FaceURL:
      'http://cloud-3.steamusercontent.com/ugc/768365276449184929/088AB3FC9F328258B48225B1F5BB0CCE4F5DF1BD/',
    BackURL:
      'http://cloud-3.steamusercontent.com/ugc/768365068750455723/A2E8BBF482ED5B11D5A19D86A38A6FB03ED2A592/',
  },
  {
    ttsID: 38900,
    name: 'Plaguespire Calamity',
    flags: ['Promo'],
    set: 'PROMOS',
    FaceURL:
      'http://cloud-3.steamusercontent.com/ugc/768365276449190982/B95CBE04F8271CDC53C9BDDE80B628E0B7AF86C5/',
    BackURL:
      'http://cloud-3.steamusercontent.com/ugc/768365068750455723/A2E8BBF482ED5B11D5A19D86A38A6FB03ED2A592/',
  },
  {
    ttsID: 38800,
    name: 'Frozen Decree',
    flags: ['Promo'],
    set: 'PROMOS',
    FaceURL:
      'http://cloud-3.steamusercontent.com/ugc/768365276449190174/6A9492B7DB14BFCE854EC6E2853A24B20B19D7D8/',
    BackURL:
      'http://cloud-3.steamusercontent.com/ugc/768365068750455723/A2E8BBF482ED5B11D5A19D86A38A6FB03ED2A592/',
  },
  {
    ttsID: 38500,
    name: 'Conquered Landscape',
    flags: ['Promo'],
    set: 'PROMOS',
    FaceURL:
      'http://cloud-3.steamusercontent.com/ugc/768365276449183360/DE6BF0BF12E99B0C6D7279673ED55AFF607F7A30/',
    BackURL:
      'http://cloud-3.steamusercontent.com/ugc/768365068750455723/A2E8BBF482ED5B11D5A19D86A38A6FB03ED2A592/',
  },
  {
    ttsID: 37900,
    name: 'Carter, Champion of Cardsmithing',
    flags: ['Promo'],
    set: 'PROMOS',
    FaceURL:
      'http://cloud-3.steamusercontent.com/ugc/768365276449174531/02D2DAED25312421FED48B7B812C914F953B47B3/',
    BackURL:
      'http://cloud-3.steamusercontent.com/ugc/768365068750455723/A2E8BBF482ED5B11D5A19D86A38A6FB03ED2A592/',
  },
  {
    ttsID: 39000,
    name: "Shackled Silencer, Skyborn's Regret",
    flags: ['Promo'],
    set: 'PROMOS',
    FaceURL:
      'http://cloud-3.steamusercontent.com/ugc/768365276449192871/F7D72C8CA1AB78DAFD8E54BD3B4117B887200D29/',
    BackURL:
      'http://cloud-3.steamusercontent.com/ugc/768365068750455723/A2E8BBF482ED5B11D5A19D86A38A6FB03ED2A592/',
  },
  {
    ttsID: 34200,
    name: 'Siphon from Thoughts',
    flags: ['Promo'],
    set: 'PROMOS',
    FaceURL:
      'http://cloud-3.steamusercontent.com/ugc/768365276448790839/70945A77A7B19BAAE5A887E73368EA583955E3BC/',
    BackURL:
      'http://cloud-3.steamusercontent.com/ugc/768365068750455723/A2E8BBF482ED5B11D5A19D86A38A6FB03ED2A592/',
  },
  {
    ttsID: 34100,
    name: "Rebecca, Eeventide's First Champion",
    flags: ['Promo'],
    set: 'PROMOS',
    FaceURL:
      'http://cloud-3.steamusercontent.com/ugc/768365276448788935/3D46F8B1C05E2AECF04E4D45CD03BFE8C5E51FBF/',
    BackURL:
      'http://cloud-3.steamusercontent.com/ugc/768365068750455723/A2E8BBF482ED5B11D5A19D86A38A6FB03ED2A592/',
  },
  {
    ttsID: 34900,
    name: 'Forerunner of Eclipses',
    flags: ['Promo'],
    set: 'PROMOS',
    FaceURL:
      'http://cloud-3.steamusercontent.com/ugc/768365276448979802/523EAF907FC519E7621583F90B1499BB735A9B53/',
    BackURL:
      'http://cloud-3.steamusercontent.com/ugc/768365068750455723/A2E8BBF482ED5B11D5A19D86A38A6FB03ED2A592/',
  },
  {
    ttsID: 33800,
    name: 'Sunken Revenant',
    flags: ['Promo'],
    set: 'PROMOS',
    FaceURL:
      'http://cloud-3.steamusercontent.com/ugc/768365276448785143/F18885D6953967DC1BE63938EEEBCDAEFDBE9DEB/',
    BackURL:
      'http://cloud-3.steamusercontent.com/ugc/768365068750455723/A2E8BBF482ED5B11D5A19D86A38A6FB03ED2A592/',
  },
  {
    ttsID: 34500,
    name: 'Mysterious Bosk',
    flags: ['Promo'],
    set: 'PROMOS',
    FaceURL:
      'http://cloud-3.steamusercontent.com/ugc/768365276448974439/BE3BD4E812D0BB6EC16771074E26FE52C21A34FE/',
    BackURL:
      'http://cloud-3.steamusercontent.com/ugc/768365068750455723/A2E8BBF482ED5B11D5A19D86A38A6FB03ED2A592/',
  },
  {
    ttsID: 34400,
    name: 'Glistening Undergrove',
    flags: ['Promo'],
    set: 'PROMOS',
    FaceURL:
      'http://cloud-3.steamusercontent.com/ugc/768365276448973523/7D3C1AA087392FD2E8B11CD79D17C542B919AC62/',
    BackURL:
      'http://cloud-3.steamusercontent.com/ugc/768365068750455723/A2E8BBF482ED5B11D5A19D86A38A6FB03ED2A592/',
  },
  {
    ttsID: 34800,
    name: 'Dunedust Ravager',
    flags: ['Promo'],
    set: 'PROMOS',
    FaceURL:
      'http://cloud-3.steamusercontent.com/ugc/768365276448978966/A6852A8DAEBE661C7290E1CB4783AC960798A590/',
    BackURL:
      'http://cloud-3.steamusercontent.com/ugc/768365068750455723/A2E8BBF482ED5B11D5A19D86A38A6FB03ED2A592/',
  },
  {
    ttsID: 45600,
    name: 'Oterecnoc, End of the World',
    flags: ['Promo'],
    set: 'PROMOS',
    FaceURL:
      'http://cloud-3.steamusercontent.com/ugc/999177766140732579/BD7C86140C36BAB80F53303990698CF1DE7BA286/',
    BackURL:
      'http://cloud-3.steamusercontent.com/ugc/768365068750455723/A2E8BBF482ED5B11D5A19D86A38A6FB03ED2A592/',
  },
  {
    ttsID: 45700,
    name: "Eeventide's Ordinance",
    flags: ['Promo'],
    set: 'PROMOS',
    FaceURL:
      'http://cloud-3.steamusercontent.com/ugc/768365276448801715/75DCAEABE0BD8C3BDC318D12206FAA0FE713622F/',
    BackURL:
      'http://cloud-3.steamusercontent.com/ugc/768365068750455723/A2E8BBF482ED5B11D5A19D86A38A6FB03ED2A592/',
  },
  {
    ttsID: 45800,
    name: 'Defender of the Aerial Provinces',
    flags: ['Promo'],
    set: 'PROMOS',
    FaceURL:
      'http://cloud-3.steamusercontent.com/ugc/768365276448788049/73C9588DE63C726768DB56983463D918CDFAA1CD/',
    BackURL:
      'http://cloud-3.steamusercontent.com/ugc/768365068750455723/A2E8BBF482ED5B11D5A19D86A38A6FB03ED2A592/',
  },
  {
    ttsID: 45900,
    name: 'Duality Sapling',
    flags: ['Promo'],
    set: 'PROMOS',
    FaceURL:
      'http://cloud-3.steamusercontent.com/ugc/768365276448754707/BBCD2B6348335C30E1EEBAEAF713753519B4E7D6/',
    BackURL:
      'http://cloud-3.steamusercontent.com/ugc/768365068750455723/A2E8BBF482ED5B11D5A19D86A38A6FB03ED2A592/',
  },
  {
    ttsID: 46000,
    name: 'Dimensional Seaslithe',
    flags: ['Promo'],
    set: 'PROMOS',
    FaceURL:
      'http://cloud-3.steamusercontent.com/ugc/768365276448786476/49591DB201D8648D5F40ED0C94A0F55A90B8F02F/',
    BackURL:
      'http://cloud-3.steamusercontent.com/ugc/768365068750455723/A2E8BBF482ED5B11D5A19D86A38A6FB03ED2A592/',
  },
  {
    ttsID: 46100,
    name: 'Chronograph Valegion',
    flags: ['Promo'],
    set: 'PROMOS',
    FaceURL:
      'http://cloud-3.steamusercontent.com/ugc/768365276448977146/A17BED04D4C4E1411785AFB3EE55ADED8B99FD77/',
    BackURL:
      'http://cloud-3.steamusercontent.com/ugc/768365068750455723/A2E8BBF482ED5B11D5A19D86A38A6FB03ED2A592/',
  },
  {
    ttsID: 46600,
    name: 'Distorted Lingerer',
    flags: [],
    set: 'PROMOS',
    FaceURL:
      'http://cloud-3.steamusercontent.com/ugc/768365276448975899/81E7A7B10CA8F8AAB39FFE472B5E6A7FC1EFEABE/',
    BackURL:
      'http://cloud-3.steamusercontent.com/ugc/768365068750455723/A2E8BBF482ED5B11D5A19D86A38A6FB03ED2A592/',
  },
  {
    ttsID: 35000,
    name: 'Chains',
    flags: ['Promo'],
    set: 'PROMOS',
    FaceURL:
      'http://cloud-3.steamusercontent.com/ugc/768365276448983627/ABA7B45B96E3DD1E534E32C0EAB661868452A6F9/',
    BackURL:
      'http://cloud-3.steamusercontent.com/ugc/768365068750455723/A2E8BBF482ED5B11D5A19D86A38A6FB03ED2A592/',
  },
  {
    ttsID: 35100,
    name: 'Leech',
    flags: ['Promo'],
    set: 'PROMOS',
    FaceURL:
      'http://cloud-3.steamusercontent.com/ugc/768365276448984838/1016CE87F06168983861A381DEBCD3830B5CA124/',
    BackURL:
      'http://cloud-3.steamusercontent.com/ugc/768365068750455723/A2E8BBF482ED5B11D5A19D86A38A6FB03ED2A592/',
  },
  {
    ttsID: 35200,
    name: 'Unsummon',
    flags: ['Promo'],
    set: 'PROMOS',
    FaceURL:
      'http://cloud-3.steamusercontent.com/ugc/768365276448986573/03D8BC2079638C4BF0A6622066C4F5A4B9A9B04F/',
    BackURL:
      'http://cloud-3.steamusercontent.com/ugc/768365068750455723/A2E8BBF482ED5B11D5A19D86A38A6FB03ED2A592/',
  },
  {
    ttsID: 35300,
    name: 'Vortex',
    flags: ['Promo'],
    set: 'PROMOS',
    FaceURL:
      'http://cloud-3.steamusercontent.com/ugc/768365276448987840/D9A1BA1E612A96B6A469BF37BF3FC0453546CFED/',
    BackURL:
      'http://cloud-3.steamusercontent.com/ugc/768365068750455723/A2E8BBF482ED5B11D5A19D86A38A6FB03ED2A592/',
  },
  {
    ttsID: 35400,
    name: 'Silent Irregularity',
    flags: ['Promo'],
    set: 'PROMOS',
    FaceURL:
      'http://cloud-3.steamusercontent.com/ugc/768365276448989475/802C43EC4D3996A1A9572C19DCD170756F07FDD2/',
    BackURL:
      'http://cloud-3.steamusercontent.com/ugc/768365068750455723/A2E8BBF482ED5B11D5A19D86A38A6FB03ED2A592/',
  },
  {
    ttsID: 35500,
    name: 'Wanderwolf the Inner Fear',
    flags: ['Promo'],
    set: 'PROMOS',
    FaceURL:
      'http://cloud-3.steamusercontent.com/ugc/768365276448990660/48CA9F2B9844B5D19A8C97673C3927FB6B82C2E9/',
    BackURL:
      'http://cloud-3.steamusercontent.com/ugc/768365068750455723/A2E8BBF482ED5B11D5A19D86A38A6FB03ED2A592/',
  },
  {
    ttsID: 35600,
    name: 'Shardscale Pterohedron',
    flags: ['Promo'],
    set: 'PROMOS',
    FaceURL:
      'http://cloud-3.steamusercontent.com/ugc/768365276448997510/C04C693AB92576F39B01F28CC0A194BDDFBE99B9/',
    BackURL:
      'http://cloud-3.steamusercontent.com/ugc/768365068750455723/A2E8BBF482ED5B11D5A19D86A38A6FB03ED2A592/',
  },
  {
    ttsID: 35700,
    name: 'Bristlecone Phantom Pine',
    flags: [],
    set: 'PROMOS',
    FaceURL:
      'http://cloud-3.steamusercontent.com/ugc/768365276448998536/AA8045BABCEA2977A8ABE3D3A3A0E51F86210CF2/',
    BackURL:
      'http://cloud-3.steamusercontent.com/ugc/768365068750455723/A2E8BBF482ED5B11D5A19D86A38A6FB03ED2A592/',
  },
  {
    ttsID: 55700,
    name: 'Legacy Vestige, Corrodestria',
    flags: [],
    set: 'PROMOS',
    FaceURL:
      'http://cloud-3.steamusercontent.com/ugc/1612841555441388017/3AD74364C75EF3B33FDA1E49F43F27E17A079AB1/',
    BackURL:
      'http://cloud-3.steamusercontent.com/ugc/768365068750455723/A2E8BBF482ED5B11D5A19D86A38A6FB03ED2A592/',
  },
  {
    ttsID: 55800,
    name: 'Eeventide’s Enclave',
    flags: [],
    set: 'PROMOS',
    FaceURL:
      'http://cloud-3.steamusercontent.com/ugc/1612841555441390747/D9F966D6894ADB44755BAA49578C8600D3D28864/',
    BackURL:
      'http://cloud-3.steamusercontent.com/ugc/768365068750455723/A2E8BBF482ED5B11D5A19D86A38A6FB03ED2A592/',
  },
  {
    ttsID: 40500,
    name: 'Airship Corrodestria',
    flags: ['Sketch'],
    set: 'SKETCH CARDS',
    FaceURL:
      'http://cloud-3.steamusercontent.com/ugc/768365276449696242/3D6E15005A8E4650257F99391720A227E69801F3/',
    BackURL:
      'http://cloud-3.steamusercontent.com/ugc/768365068750455723/A2E8BBF482ED5B11D5A19D86A38A6FB03ED2A592/',
  },
  {
    ttsID: 40400,
    name: 'Angelic Apparition',
    flags: ['Sketch'],
    set: 'SKETCH CARDS',
    FaceURL:
      'http://cloud-3.steamusercontent.com/ugc/768365276449694686/8D01D108E3CC028B3E98FDF3CCD08119BE1E626E/',
    BackURL:
      'http://cloud-3.steamusercontent.com/ugc/768365068750455723/A2E8BBF482ED5B11D5A19D86A38A6FB03ED2A592/',
  },
  {
    ttsID: 40100,
    name: 'Asteroid Dragon',
    flags: ['Sketch'],
    set: 'SKETCH CARDS',
    FaceURL:
      'http://cloud-3.steamusercontent.com/ugc/768365276449690678/3A4BD137768191BC34F3F39F55C3542C5F37E7A6/',
    BackURL:
      'http://cloud-3.steamusercontent.com/ugc/768365068750455723/A2E8BBF482ED5B11D5A19D86A38A6FB03ED2A592/',
  },
  {
    ttsID: 40600,
    name: 'Atmospheric Striker',
    flags: ['Sketch'],
    set: 'SKETCH CARDS',
    FaceURL:
      'http://cloud-3.steamusercontent.com/ugc/768365276449697554/F19237CD6A771E1BC8FE1BC71099B2B3D7626E43/',
    BackURL:
      'http://cloud-3.steamusercontent.com/ugc/768365068750455723/A2E8BBF482ED5B11D5A19D86A38A6FB03ED2A592/',
  },
  {
    ttsID: 40700,
    name: 'Bedragoth',
    flags: ['Sketch'],
    set: 'SKETCH CARDS',
    FaceURL:
      'http://cloud-3.steamusercontent.com/ugc/768365276449700058/F3CE6144508ECB77E742459BDADE6FFA4442C16F/',
    BackURL:
      'http://cloud-3.steamusercontent.com/ugc/768365068750455723/A2E8BBF482ED5B11D5A19D86A38A6FB03ED2A592/',
  },
  {
    ttsID: 40800,
    name: 'Benevolent Seraphim',
    flags: ['Sketch'],
    set: 'SKETCH CARDS',
    FaceURL:
      'http://cloud-3.steamusercontent.com/ugc/768365276449701866/FCDEACDDCC5C775BB8BF07E51BD1D852B0B14F91/',
    BackURL:
      'http://cloud-3.steamusercontent.com/ugc/768365068750455723/A2E8BBF482ED5B11D5A19D86A38A6FB03ED2A592/',
  },
  {
    ttsID: 40900,
    name: 'Calamity Dragon',
    flags: ['Sketch'],
    set: 'SKETCH CARDS',
    FaceURL:
      'http://cloud-3.steamusercontent.com/ugc/768365276449705278/5CFDC83C7F0B5E6DDA8250518E40AA6EECA99B59/',
    BackURL:
      'http://cloud-3.steamusercontent.com/ugc/768365068750455723/A2E8BBF482ED5B11D5A19D86A38A6FB03ED2A592/',
  },
  {
    ttsID: 41000,
    name: 'Celestial Starcaster',
    flags: ['Sketch'],
    set: 'SKETCH CARDS',
    FaceURL:
      'http://cloud-3.steamusercontent.com/ugc/768365276449706406/ACD5F631D9CC7835E15B0A7DD87E12D2D86C7CE5/',
    BackURL:
      'http://cloud-3.steamusercontent.com/ugc/768365068750455723/A2E8BBF482ED5B11D5A19D86A38A6FB03ED2A592/',
  },
  {
    ttsID: 41100,
    name: 'Clock Dragon',
    flags: ['Sketch'],
    set: 'SKETCH CARDS',
    FaceURL:
      'http://cloud-3.steamusercontent.com/ugc/768365276449707922/A065960C62D0525444F26A0CCE45F0A95BB6EF37/',
    BackURL:
      'http://cloud-3.steamusercontent.com/ugc/768365068750455723/A2E8BBF482ED5B11D5A19D86A38A6FB03ED2A592/',
  },
  {
    ttsID: 41200,
    name: 'Darcrest Dragon',
    flags: ['Sketch'],
    set: 'SKETCH CARDS',
    FaceURL:
      'http://cloud-3.steamusercontent.com/ugc/768365276449708908/A1C2D47D9FB1A5B4DB3428D0AF5311BFE5A106F2/',
    BackURL:
      'http://cloud-3.steamusercontent.com/ugc/768365068750455723/A2E8BBF482ED5B11D5A19D86A38A6FB03ED2A592/',
  },
  {
    ttsID: 41300,
    name: 'Density Dragon',
    flags: ['Sketch'],
    set: 'SKETCH CARDS',
    FaceURL:
      'http://cloud-3.steamusercontent.com/ugc/768365276449710311/8EC00A7438545C529DE8750769C62D09CB62650F/',
    BackURL:
      'http://cloud-3.steamusercontent.com/ugc/768365068750455723/A2E8BBF482ED5B11D5A19D86A38A6FB03ED2A592/',
  },
  {
    ttsID: 41400,
    name: 'Dimensional Desperado',
    flags: ['Sketch'],
    set: 'SKETCH CARDS',
    FaceURL:
      'http://cloud-3.steamusercontent.com/ugc/768365276449711503/554C89752297B20C0AA5461371103451ED5E8057/',
    BackURL:
      'http://cloud-3.steamusercontent.com/ugc/768365068750455723/A2E8BBF482ED5B11D5A19D86A38A6FB03ED2A592/',
  },
  {
    ttsID: 41500,
    name: 'Dimensional Dragon',
    flags: ['Sketch'],
    set: 'SKETCH CARDS',
    FaceURL:
      'http://cloud-3.steamusercontent.com/ugc/768365276449713227/664095F27E698311E3442BD427E01E9A24415765/',
    BackURL:
      'http://cloud-3.steamusercontent.com/ugc/768365068750455723/A2E8BBF482ED5B11D5A19D86A38A6FB03ED2A592/',
  },
  {
    ttsID: 41600,
    name: 'Divine Minister',
    flags: ['Sketch'],
    set: 'SKETCH CARDS',
    FaceURL:
      'http://cloud-3.steamusercontent.com/ugc/768365276449714288/226CAE62D5BAF6B22161AB5C56E61DACC609C53E/',
    BackURL:
      'http://cloud-3.steamusercontent.com/ugc/768365068750455723/A2E8BBF482ED5B11D5A19D86A38A6FB03ED2A592/',
  },
  {
    ttsID: 41700,
    name: 'Doomfest Elderbeast Angel',
    flags: ['Sketch'],
    set: 'SKETCH CARDS',
    FaceURL:
      'http://cloud-3.steamusercontent.com/ugc/768365276449715712/E422ECC9C0EE78738A9FC039B576B82128D7E142/',
    BackURL:
      'http://cloud-3.steamusercontent.com/ugc/768365068750455723/A2E8BBF482ED5B11D5A19D86A38A6FB03ED2A592/',
  },
  {
    ttsID: 41800,
    name: 'Exalted Skyshifter',
    flags: ['Sketch'],
    set: 'SKETCH CARDS',
    FaceURL:
      'http://cloud-3.steamusercontent.com/ugc/768365276449716630/7D25668227069F2D024D4083E44DC599D25A7E49/',
    BackURL:
      'http://cloud-3.steamusercontent.com/ugc/768365068750455723/A2E8BBF482ED5B11D5A19D86A38A6FB03ED2A592/',
  },
  {
    ttsID: 40200,
    name: 'Heavenheart War-Angel',
    flags: ['Sketch'],
    set: 'SKETCH CARDS',
    FaceURL:
      'http://cloud-3.steamusercontent.com/ugc/768365276449691683/F9401F9B37CC7C7CEB74A62C73AD69B3F24DD0E7/',
    BackURL:
      'http://cloud-3.steamusercontent.com/ugc/768365068750455723/A2E8BBF482ED5B11D5A19D86A38A6FB03ED2A592/',
  },
  {
    ttsID: 41900,
    name: 'Heavenly Guardian',
    flags: ['Sketch'],
    set: 'SKETCH CARDS',
    FaceURL:
      'http://cloud-3.steamusercontent.com/ugc/768365276449718781/9EBB2A06CD598FF5182BBCCD9F7AE9389846BA1E/',
    BackURL:
      'http://cloud-3.steamusercontent.com/ugc/768365068750455723/A2E8BBF482ED5B11D5A19D86A38A6FB03ED2A592/',
  },
  {
    ttsID: 40300,
    name: 'Lightpath Paladin',
    flags: ['Sketch'],
    set: 'SKETCH CARDS',
    FaceURL:
      'http://cloud-3.steamusercontent.com/ugc/768365276449693291/758ADC0596E0EEC0A9A4170CA1A596FA7F9B87FD/',
    BackURL:
      'http://cloud-3.steamusercontent.com/ugc/768365068750455723/A2E8BBF482ED5B11D5A19D86A38A6FB03ED2A592/',
  },
  {
    ttsID: 42000,
    name: 'Lustrous Lightbringer',
    flags: ['Sketch'],
    set: 'SKETCH CARDS',
    FaceURL:
      'http://cloud-3.steamusercontent.com/ugc/768365276449719824/1D44958A411E161B9725D2EAFC8309B87C7ABE52/',
    BackURL:
      'http://cloud-3.steamusercontent.com/ugc/768365068750455723/A2E8BBF482ED5B11D5A19D86A38A6FB03ED2A592/',
  },
  {
    ttsID: 42100,
    name: 'Majestic Noziroh',
    flags: ['Sketch'],
    set: 'SKETCH CARDS',
    FaceURL:
      'http://cloud-3.steamusercontent.com/ugc/768365276449721479/078B38CC8030556522CB53B1A32413B9F813E03E/',
    BackURL:
      'http://cloud-3.steamusercontent.com/ugc/768365068750455723/A2E8BBF482ED5B11D5A19D86A38A6FB03ED2A592/',
  },
  {
    ttsID: 42200,
    name: 'Mistrai Dragon',
    flags: ['Sketch'],
    set: 'SKETCH CARDS',
    FaceURL:
      'http://cloud-3.steamusercontent.com/ugc/768365276449722487/56891119C2BC88E47ADD8AAFE573654FFF90D7D5/',
    BackURL:
      'http://cloud-3.steamusercontent.com/ugc/768365068750455723/A2E8BBF482ED5B11D5A19D86A38A6FB03ED2A592/',
  },
  {
    ttsID: 42300,
    name: 'Rose Slithe',
    flags: ['Sketch'],
    set: 'SKETCH CARDS',
    FaceURL:
      'http://cloud-3.steamusercontent.com/ugc/768365276449724237/8225EB1DED73D0D97F5F3359D2C13F043BC9A650/',
    BackURL:
      'http://cloud-3.steamusercontent.com/ugc/768365068750455723/A2E8BBF482ED5B11D5A19D86A38A6FB03ED2A592/',
  },
  {
    ttsID: 42400,
    name: 'Skyward Slithe',
    flags: ['Sketch'],
    set: 'SKETCH CARDS',
    FaceURL:
      'http://cloud-3.steamusercontent.com/ugc/768365276449725495/153CB356C968B2CD613CC43661435D498921989F/',
    BackURL:
      'http://cloud-3.steamusercontent.com/ugc/768365068750455723/A2E8BBF482ED5B11D5A19D86A38A6FB03ED2A592/',
  },
  {
    ttsID: 42500,
    name: 'Slither Drake',
    flags: ['Sketch'],
    set: 'SKETCH CARDS',
    FaceURL:
      'http://cloud-3.steamusercontent.com/ugc/768365276449727531/D34B7BEFBCEEA066B340120855FAF65A2B20D3BC/',
    BackURL:
      'http://cloud-3.steamusercontent.com/ugc/768365068750455723/A2E8BBF482ED5B11D5A19D86A38A6FB03ED2A592/',
  },
  {
    ttsID: 42600,
    name: 'Valforce',
    flags: ['Sketch'],
    set: 'SKETCH CARDS',
    FaceURL:
      'http://cloud-3.steamusercontent.com/ugc/768365276449728688/2769F157202E4ACAA686760C7E5B35DB8E2DEBA5/',
    BackURL:
      'http://cloud-3.steamusercontent.com/ugc/768365068750455723/A2E8BBF482ED5B11D5A19D86A38A6FB03ED2A592/',
  },
  {
    ttsID: 44700,
    name: 'Alana the Star Formation',
    flags: ['Sketch'],
    set: 'SKETCH CARDS',
    FaceURL:
      'http://cloud-3.steamusercontent.com/ugc/768365276450065481/956AAD984C513C0936E3895216BB20A6314EBFA1/',
    BackURL:
      'http://cloud-3.steamusercontent.com/ugc/768365068750455723/A2E8BBF482ED5B11D5A19D86A38A6FB03ED2A592/',
  },
  {
    ttsID: 44800,
    name: 'Battleship Philistia',
    flags: ['Sketch'],
    set: 'SKETCH CARDS',
    FaceURL:
      'http://cloud-3.steamusercontent.com/ugc/768365276450081129/F66919C57A98AE40548E1F028CAFE318F9621C5C/',
    BackURL:
      'http://cloud-3.steamusercontent.com/ugc/768365068750455723/A2E8BBF482ED5B11D5A19D86A38A6FB03ED2A592/',
  },
  {
    ttsID: 42700,
    name: 'Concerto Virtuoso',
    flags: ['Sketch'],
    set: 'SKETCH CARDS',
    FaceURL:
      'http://cloud-3.steamusercontent.com/ugc/768365276449730428/A55237E01287A106F69FDA32EDF12FD176E883D4/',
    BackURL:
      'http://cloud-3.steamusercontent.com/ugc/768365068750455723/A2E8BBF482ED5B11D5A19D86A38A6FB03ED2A592/',
  },
  {
    ttsID: 42800,
    name: 'Dimensional Desperado',
    flags: ['Sketch'],
    set: 'SKETCH CARDS',
    FaceURL:
      'http://cloud-3.steamusercontent.com/ugc/768365276449731349/1E67C8E6688474DC8090A068C2F83990D3EF4DDE/',
    BackURL:
      'http://cloud-3.steamusercontent.com/ugc/768365068750455723/A2E8BBF482ED5B11D5A19D86A38A6FB03ED2A592/',
  },
  {
    ttsID: 42900,
    name: 'Dimensional Dragon',
    flags: ['Sketch'],
    set: 'SKETCH CARDS',
    FaceURL:
      'http://cloud-3.steamusercontent.com/ugc/768365276449733695/49E61E76DE8524C7EE5E1AE82D20E9A6D376C503/',
    BackURL:
      'http://cloud-3.steamusercontent.com/ugc/768365068750455723/A2E8BBF482ED5B11D5A19D86A38A6FB03ED2A592/',
  },
  {
    ttsID: 43100,
    name: 'Dimensional Dust',
    flags: ['Sketch'],
    set: 'SKETCH CARDS',
    FaceURL:
      'http://cloud-3.steamusercontent.com/ugc/768365276449737374/744FB265BC7211364904D458BC1C1D4DCF66A77B/',
    BackURL:
      'http://cloud-3.steamusercontent.com/ugc/768365068750455723/A2E8BBF482ED5B11D5A19D86A38A6FB03ED2A592/',
  },
  {
    ttsID: 44900,
    name: 'Drangel',
    flags: ['Sketch'],
    set: 'SKETCH CARDS',
    FaceURL:
      'http://cloud-3.steamusercontent.com/ugc/768365276450083193/B0C0D60C542CE318AE84749B713BD2ACBFE00913/',
    BackURL:
      'http://cloud-3.steamusercontent.com/ugc/768365068750455723/A2E8BBF482ED5B11D5A19D86A38A6FB03ED2A592/',
  },
  {
    ttsID: 45000,
    name: 'Dream Blossom Dragon',
    flags: ['Sketch'],
    set: 'SKETCH CARDS',
    FaceURL:
      'http://cloud-3.steamusercontent.com/ugc/768365276450085325/572C64844DAFDCC2C29E5D36AE579FF925357126/',
    BackURL:
      'http://cloud-3.steamusercontent.com/ugc/768365068750455723/A2E8BBF482ED5B11D5A19D86A38A6FB03ED2A592/',
  },
  {
    ttsID: 43200,
    name: "Euphoria's Reach",
    flags: ['Sketch'],
    set: 'SKETCH CARDS',
    FaceURL:
      'http://cloud-3.steamusercontent.com/ugc/768365276449739259/2673D87B360E222440C9384ADF9094DC0932B2D1/',
    BackURL:
      'http://cloud-3.steamusercontent.com/ugc/768365068750455723/A2E8BBF482ED5B11D5A19D86A38A6FB03ED2A592/',
  },
  {
    ttsID: 43300,
    name: 'Everdrift Islands',
    flags: ['Sketch'],
    set: 'SKETCH CARDS',
    FaceURL:
      'http://cloud-3.steamusercontent.com/ugc/768365276449740935/6136BC5EB5F8F9CA30DE5B2941BB2343357F1EC7/',
    BackURL:
      'http://cloud-3.steamusercontent.com/ugc/768365068750455723/A2E8BBF482ED5B11D5A19D86A38A6FB03ED2A592/',
  },
  {
    ttsID: 43400,
    name: 'Granciel, Emissary of Stars',
    flags: ['Sketch'],
    set: 'SKETCH CARDS',
    FaceURL:
      'http://cloud-3.steamusercontent.com/ugc/768365276449742179/F43AF7DB682E7034B7B5BCDA5B41887F692C1FCD/',
    BackURL:
      'http://cloud-3.steamusercontent.com/ugc/768365068750455723/A2E8BBF482ED5B11D5A19D86A38A6FB03ED2A592/',
  },
  {
    ttsID: 43500,
    name: 'Isles of the Morning Star',
    flags: ['Sketch'],
    set: 'SKETCH CARDS',
    FaceURL:
      'http://cloud-3.steamusercontent.com/ugc/768365276449743960/C3C0498D0E616832C9DEB8A17C619C51272DF302/',
    BackURL:
      'http://cloud-3.steamusercontent.com/ugc/768365068750455723/A2E8BBF482ED5B11D5A19D86A38A6FB03ED2A592/',
  },
  {
    ttsID: 43600,
    name: 'Princess of Blades',
    flags: ['Sketch'],
    set: 'SKETCH CARDS',
    FaceURL:
      'http://cloud-3.steamusercontent.com/ugc/768365276449744956/AF5F3C1154CEFC821A31F47D9B7E759E92940625/',
    BackURL:
      'http://cloud-3.steamusercontent.com/ugc/768365068750455723/A2E8BBF482ED5B11D5A19D86A38A6FB03ED2A592/',
  },
  {
    ttsID: 43700,
    name: 'Vinebranch Seraph',
    flags: ['Sketch'],
    set: 'SKETCH CARDS',
    FaceURL:
      'http://cloud-3.steamusercontent.com/ugc/768365276449746548/5E88EDE27B9A4D96183941413AC08AF5698EF58F/',
    BackURL:
      'http://cloud-3.steamusercontent.com/ugc/768365068750455723/A2E8BBF482ED5B11D5A19D86A38A6FB03ED2A592/',
  },
  {
    ttsID: 43800,
    name: 'Wishwaker Willow',
    flags: ['Sketch'],
    set: 'SKETCH CARDS',
    FaceURL:
      'http://cloud-3.steamusercontent.com/ugc/768365276449747499/2D788303F52614C9BF5E436EBBDB271DAA1BB4F9/',
    BackURL:
      'http://cloud-3.steamusercontent.com/ugc/768365068750455723/A2E8BBF482ED5B11D5A19D86A38A6FB03ED2A592/',
  },
  {
    ttsID: 43900,
    name: 'Brilliant Brightveil',
    flags: ['Sketch'],
    set: 'SKETCH CARDS',
    FaceURL:
      'http://cloud-3.steamusercontent.com/ugc/768365276449748941/AF3FEDB5359E6FB71004D4A341A165FC250740E0/',
    BackURL:
      'http://cloud-3.steamusercontent.com/ugc/768365068750455723/A2E8BBF482ED5B11D5A19D86A38A6FB03ED2A592/',
  },
  {
    ttsID: 44000,
    name: 'Crystallize',
    flags: ['Sketch'],
    set: 'SKETCH CARDS',
    FaceURL:
      'http://cloud-3.steamusercontent.com/ugc/768365276449749882/0B8A154F344C46C7845B3F45E5A0E2CD9CFA421E/',
    BackURL:
      'http://cloud-3.steamusercontent.com/ugc/768365068750455723/A2E8BBF482ED5B11D5A19D86A38A6FB03ED2A592/',
  },
  {
    ttsID: 44100,
    name: "Crystallus' Command",
    flags: ['Sketch'],
    set: 'SKETCH CARDS',
    FaceURL:
      'http://cloud-3.steamusercontent.com/ugc/768365276449751755/4AF37E11E00967A71D9D1D5CBB7D8C6F23C50D4F/',
    BackURL:
      'http://cloud-3.steamusercontent.com/ugc/768365068750455723/A2E8BBF482ED5B11D5A19D86A38A6FB03ED2A592/',
  },
  {
    ttsID: 44200,
    name: 'Frostfront Crysade',
    flags: ['Sketch'],
    set: 'SKETCH CARDS',
    FaceURL:
      'http://cloud-3.steamusercontent.com/ugc/768365276449752660/09796A0E6668316BD73234C973B75C55DA2AE4B8/',
    BackURL:
      'http://cloud-3.steamusercontent.com/ugc/768365068750455723/A2E8BBF482ED5B11D5A19D86A38A6FB03ED2A592/',
  },
  {
    ttsID: 44300,
    name: 'Garnet Marauder',
    flags: ['Sketch'],
    set: 'SKETCH CARDS',
    FaceURL:
      'http://cloud-3.steamusercontent.com/ugc/768365276449754568/63F16419D04A61CA093188B02BC9055B79DD40D7/',
    BackURL:
      'http://cloud-3.steamusercontent.com/ugc/768365068750455723/A2E8BBF482ED5B11D5A19D86A38A6FB03ED2A592/',
  },
  {
    ttsID: 44400,
    name: 'Helver Formation',
    flags: ['Sketch'],
    set: 'SKETCH CARDS',
    FaceURL:
      'http://cloud-3.steamusercontent.com/ugc/768365276449755622/E86DDA393F143BCFA35E2B4630D4081A15E470BB/',
    BackURL:
      'http://cloud-3.steamusercontent.com/ugc/768365068750455723/A2E8BBF482ED5B11D5A19D86A38A6FB03ED2A592/',
  },
  {
    ttsID: 44500,
    name: 'Nebula Geyser Meadow',
    flags: ['Sketch'],
    set: 'SKETCH CARDS',
    FaceURL:
      'http://cloud-3.steamusercontent.com/ugc/768365276449757288/538EAB8556FC1A009B0FAFA411BA9D1BA1E3E7C0/',
    BackURL:
      'http://cloud-3.steamusercontent.com/ugc/768365068750455723/A2E8BBF482ED5B11D5A19D86A38A6FB03ED2A592/',
  },
  {
    ttsID: 44600,
    name: "Rose's Reach",
    flags: ['Sketch'],
    set: 'SKETCH CARDS',
    FaceURL:
      'http://cloud-3.steamusercontent.com/ugc/768365276449758474/372E8FA27C389F82719FEFD25AEA6E546899BEC5/',
    BackURL:
      'http://cloud-3.steamusercontent.com/ugc/768365068750455723/A2E8BBF482ED5B11D5A19D86A38A6FB03ED2A592/',
  },
  {
    ttsID: 39200,
    name: 'Blossombark Gaper',
    flags: ['Sketch'],
    set: 'SKETCH CARDS',
    FaceURL:
      'http://cloud-3.steamusercontent.com/ugc/768365276449670926/4F5C8423A0D791593BA4A7D0581B969BD07A056B/',
    BackURL:
      'http://cloud-3.steamusercontent.com/ugc/768365068750455723/A2E8BBF482ED5B11D5A19D86A38A6FB03ED2A592/',
  },
  {
    ttsID: 45100,
    name: 'Chaos Bull',
    flags: ['Sketch'],
    set: 'SKETCH CARDS',
    FaceURL:
      'http://cloud-3.steamusercontent.com/ugc/768365276450104010/80DE2D33A86FE2F27561E3648F0806CA34B4773B/',
    BackURL:
      'http://cloud-3.steamusercontent.com/ugc/768365068750455723/A2E8BBF482ED5B11D5A19D86A38A6FB03ED2A592/',
  },
  {
    ttsID: 45200,
    name: 'Drangelic Outcross',
    flags: ['Sketch'],
    set: 'SKETCH CARDS',
    FaceURL:
      'http://cloud-3.steamusercontent.com/ugc/768365276450105999/69325B748BA28A54E6C5D15408B1FD93A3702E83/',
    BackURL:
      'http://cloud-3.steamusercontent.com/ugc/768365068750455723/A2E8BBF482ED5B11D5A19D86A38A6FB03ED2A592/',
  },
  {
    ttsID: 45300,
    name: 'Main Deck Strike',
    flags: ['Sketch'],
    set: 'SKETCH CARDS',
    FaceURL:
      'http://cloud-3.steamusercontent.com/ugc/768365276450107106/F4AA0339BE78E126C14FE90A397C75DEDA400FA4/',
    BackURL:
      'http://cloud-3.steamusercontent.com/ugc/768365068750455723/A2E8BBF482ED5B11D5A19D86A38A6FB03ED2A592/',
  },
  {
    ttsID: 39700,
    name: 'Secluded Shortcut',
    flags: ['Sketch'],
    set: 'SKETCH CARDS',
    FaceURL:
      'http://cloud-3.steamusercontent.com/ugc/768365276449683701/FCAEB67005B2571CAAEDB736F1E2966257FC4343/',
    BackURL:
      'http://cloud-3.steamusercontent.com/ugc/768365068750455723/A2E8BBF482ED5B11D5A19D86A38A6FB03ED2A592/',
  },
  {
    ttsID: 39100,
    name: 'Anathema',
    flags: ['Sketch'],
    set: 'SKETCH CARDS',
    FaceURL:
      'http://cloud-3.steamusercontent.com/ugc/768365276449669464/397411ADDA184771E1952334619CB1BB2C5E9EF9/',
    BackURL:
      'http://cloud-3.steamusercontent.com/ugc/768365276449670057/BD669E889ADE614C0D611165487C4324CEEE39E4/',
  },
  {
    ttsID: 39400,
    name: 'Eroda the Flickering Facade',
    flags: ['Sketch'],
    set: 'SKETCH CARDS',
    FaceURL:
      'http://cloud-3.steamusercontent.com/ugc/768365276449675183/AF209997A924272B8EC565BD5687F24680B61EC1/',
    BackURL:
      'http://cloud-3.steamusercontent.com/ugc/768365068750455723/A2E8BBF482ED5B11D5A19D86A38A6FB03ED2A592/',
  },
  {
    ttsID: 39300,
    name: "Eroda's Ascendance",
    flags: ['Sketch'],
    set: 'SKETCH CARDS',
    FaceURL:
      'http://cloud-3.steamusercontent.com/ugc/768365276449673337/2B789F436CF393B13E19129DFAE5D0BA670139F8/',
    BackURL:
      'http://cloud-3.steamusercontent.com/ugc/768365068750455723/A2E8BBF482ED5B11D5A19D86A38A6FB03ED2A592/',
  },
  {
    ttsID: 39500,
    name: 'Lucia Lievdrahs',
    flags: ['Sketch'],
    set: 'SKETCH CARDS',
    FaceURL:
      'http://cloud-3.steamusercontent.com/ugc/768365276449677128/9D8F0E2913FD9557CE66F31A8D94495152AD8397/',
    BackURL:
      'http://cloud-3.steamusercontent.com/ugc/768365068750455723/A2E8BBF482ED5B11D5A19D86A38A6FB03ED2A592/',
  },
  {
    ttsID: 39600,
    name: 'Madness Settles',
    flags: ['Sketch'],
    set: 'SKETCH CARDS',
    FaceURL:
      'http://cloud-3.steamusercontent.com/ugc/768365276449681628/6EB6E4BA9EE226237B9EE39373686018E28DE62B/',
    BackURL:
      'http://cloud-3.steamusercontent.com/ugc/768365068750455723/A2E8BBF482ED5B11D5A19D86A38A6FB03ED2A592/',
  },
  {
    ttsID: 39800,
    name: "Thoughtstream, Eeventide's Sword",
    flags: ['Sketch'],
    set: 'SKETCH CARDS',
    FaceURL:
      'http://cloud-3.steamusercontent.com/ugc/768365276449684663/F1F1DF39D861B6C0D0D416A557F286F0372DF367/',
    BackURL:
      'http://cloud-3.steamusercontent.com/ugc/768365068750455723/A2E8BBF482ED5B11D5A19D86A38A6FB03ED2A592/',
  },
  {
    ttsID: 39900,
    name: 'Verneejus the Molecular Mockery',
    flags: ['Sketch'],
    set: 'SKETCH CARDS',
    FaceURL:
      'http://cloud-3.steamusercontent.com/ugc/768365276449686447/7C6F22E74DD3DF09C9072945A1A44278BF9485E9/',
    BackURL:
      'http://cloud-3.steamusercontent.com/ugc/768365068750455723/A2E8BBF482ED5B11D5A19D86A38A6FB03ED2A592/',
  },
  {
    ttsID: 40000,
    name: 'Wanderwolf the Inner Fear',
    flags: ['Sketch'],
    set: 'SKETCH CARDS',
    FaceURL:
      'http://cloud-3.steamusercontent.com/ugc/768365276449688099/57F3891520B61CF67E4988DF7EEE8B0B72578E95/',
    BackURL:
      'http://cloud-3.steamusercontent.com/ugc/768365068750455723/A2E8BBF482ED5B11D5A19D86A38A6FB03ED2A592/',
  },
  {
    ttsID: 55900,
    name: 'Carcery the Twisted Tree',
    flags: ['Sketch'],
    set: 'SKETCH CARDS',
    FaceURL:
      'http://cloud-3.steamusercontent.com/ugc/1612841555441393602/EAD20E3714E9AE5C2968BE4F53595D7B28FAF874/',
    BackURL:
      'http://cloud-3.steamusercontent.com/ugc/768365068750455723/A2E8BBF482ED5B11D5A19D86A38A6FB03ED2A592/',
  },
  {
    ttsID: 56000,
    name: 'Magma Mountain Lavassus',
    flags: ['Sketch'],
    set: 'SKETCH CARDS',
    FaceURL:
      'http://cloud-3.steamusercontent.com/ugc/1612841555441395464/3BFD27FFCBAA80AEF3FC79C88D2E0B4AF5E48C68/',
    BackURL:
      'http://cloud-3.steamusercontent.com/ugc/768365068750455723/A2E8BBF482ED5B11D5A19D86A38A6FB03ED2A592/',
  },
  {
    ttsID: 56100,
    name: 'Margana, Chimeric Librarian',
    flags: ['Sketch'],
    set: 'SKETCH CARDS',
    FaceURL:
      'http://cloud-3.steamusercontent.com/ugc/1612841555441396102/38CB86EBDFF659328A5747A7F4549DD5497A8EAB/',
    BackURL:
      'http://cloud-3.steamusercontent.com/ugc/768365068750455723/A2E8BBF482ED5B11D5A19D86A38A6FB03ED2A592/',
  },
  {
    ttsID: 56200,
    name: 'Noellag the Crucible of Time',
    flags: ['Sketch'],
    set: 'SKETCH CARDS',
    FaceURL:
      'http://cloud-3.steamusercontent.com/ugc/1612841555441397859/E7C5C7D0B683246ED2E6DACA08E88651AFD91098/',
    BackURL:
      'http://cloud-3.steamusercontent.com/ugc/768365068750455723/A2E8BBF482ED5B11D5A19D86A38A6FB03ED2A592/',
  },
  {
    ttsID: 56400,
    name: 'Tachyon Tourbillon',
    flags: ['Sketch'],
    set: 'SKETCH CARDS',
    FaceURL:
      'http://cloud-3.steamusercontent.com/ugc/1612841555441401138/7DAAE06349881343C4611FD388A8B3BCB4F2BB75/',
    BackURL:
      'http://cloud-3.steamusercontent.com/ugc/768365068750455723/A2E8BBF482ED5B11D5A19D86A38A6FB03ED2A592/',
  },
  {
    ttsID: 56300,
    name: "Oliver's Psylerran Power",
    flags: ['Sketch'],
    set: 'SKETCH CARDS',
    FaceURL:
      'http://cloud-3.steamusercontent.com/ugc/1612841555441398750/9DC91B66D98D2AF70E388990E75E88A240C1CFE4/',
    BackURL:
      'http://cloud-3.steamusercontent.com/ugc/768365068750455723/A2E8BBF482ED5B11D5A19D86A38A6FB03ED2A592/',
  },
  {
    ttsID: 56600,
    name: 'Archivist Maven',
    flags: ['Sketch'],
    set: 'SKETCH CARDS',
    FaceURL:
      'http://cloud-3.steamusercontent.com/ugc/1612841555441405939/97D07BE464E24BE2E546401B8289A1E7216F8FBE/',
    BackURL:
      'http://cloud-3.steamusercontent.com/ugc/1612841555441020131/F9561C2BDCBB99F31505BA52ED779BABAEB9516D/',
  },
  {
    ttsID: 56500,
    name: 'Alcázar Manor, Aiculoft',
    flags: ['Sketch'],
    set: 'SKETCH CARDS',
    FaceURL:
      'http://cloud-3.steamusercontent.com/ugc/1612841555441404237/5F557F478B60F457197D20E581B40A9D4508FE07/',
    BackURL:
      'http://cloud-3.steamusercontent.com/ugc/768365068750455723/A2E8BBF482ED5B11D5A19D86A38A6FB03ED2A592/',
  },
  {
    ttsID: 29200,
    name: 'A Dimension in Ruin',
    flags: [],
    set: 'The Dimension That Disappeared',
    FaceURL:
      'http://cloud-3.steamusercontent.com/ugc/768365276448586689/E68B22B411D8172574C1C228335D48CAAE529C96/',
    BackURL:
      'http://cloud-3.steamusercontent.com/ugc/768365068750455723/A2E8BBF482ED5B11D5A19D86A38A6FB03ED2A592/',
  },
  {
    ttsID: 30100,
    name: 'Chronological Relapse',
    flags: [],
    set: 'The Dimension That Disappeared',
    FaceURL:
      'http://cloud-3.steamusercontent.com/ugc/768365276448605100/DC9A2EA133537948358096F32C38D7F4E80218C2/',
    BackURL:
      'http://cloud-3.steamusercontent.com/ugc/768365068750455723/A2E8BBF482ED5B11D5A19D86A38A6FB03ED2A592/',
  },
  {
    ttsID: 30200,
    name: "Thoughtstream, Eeventide's Sword",
    flags: [],
    set: 'The Dimension That Disappeared',
    FaceURL:
      'http://cloud-3.steamusercontent.com/ugc/768365276448606168/28AA237491B63CA4052ACA1320E1BD57EA26EE50/',
    BackURL:
      'http://cloud-3.steamusercontent.com/ugc/768365068750455723/A2E8BBF482ED5B11D5A19D86A38A6FB03ED2A592/',
  },
  {
    ttsID: 30300,
    name: "Eroda's Asendance",
    flags: [],
    set: 'The Dimension That Disappeared',
    FaceURL:
      'http://cloud-3.steamusercontent.com/ugc/768365276448613424/89FCCA33B0176CF48DE9D8BA36DDF94E15474D5E/',
    BackURL:
      'http://cloud-3.steamusercontent.com/ugc/768365068750455723/A2E8BBF482ED5B11D5A19D86A38A6FB03ED2A592/',
  },
  {
    ttsID: 30400,
    name: 'Corrupted Keystone',
    flags: [],
    set: 'The Dimension That Disappeared',
    FaceURL:
      'http://cloud-3.steamusercontent.com/ugc/768365276448616444/6AF97599D1ABC59ABF76B3E282CB1916233482A8/',
    BackURL:
      'http://cloud-3.steamusercontent.com/ugc/768365068750455723/A2E8BBF482ED5B11D5A19D86A38A6FB03ED2A592/',
  },
  {
    ttsID: 30500,
    name: 'Madness Settles',
    flags: [],
    set: 'The Dimension That Disappeared',
    FaceURL:
      'http://cloud-3.steamusercontent.com/ugc/768365276448618366/70183835476AA219ADED355F4C569A5E03ECCB6F/',
    BackURL:
      'http://cloud-3.steamusercontent.com/ugc/768365068750455723/A2E8BBF482ED5B11D5A19D86A38A6FB03ED2A592/',
  },
  {
    ttsID: 30600,
    name: 'Lucia Lievdrahs',
    flags: [],
    set: 'The Dimension That Disappeared',
    FaceURL:
      'http://cloud-3.steamusercontent.com/ugc/768365276448619602/FCB7AD044A6E12984106F099DFB12EA02D66ED7B/',
    BackURL:
      'http://cloud-3.steamusercontent.com/ugc/768365068750455723/A2E8BBF482ED5B11D5A19D86A38A6FB03ED2A592/',
  },
  {
    ttsID: 30700,
    name: 'Eroda the Flickering Facade',
    flags: [],
    set: 'The Dimension That Disappeared',
    FaceURL:
      'http://cloud-3.steamusercontent.com/ugc/768365276448621290/C4E8DA4A5FD8E14357C37C23427FA9AE62AC3A38/',
    BackURL:
      'http://cloud-3.steamusercontent.com/ugc/768365068750455723/A2E8BBF482ED5B11D5A19D86A38A6FB03ED2A592/',
  },
  {
    ttsID: 28200,
    name: 'Smokestream the Perfect Mimicry',
    flags: [],
    set: 'The Dimension That Disappeared',
    FaceURL:
      'http://cloud-3.steamusercontent.com/ugc/768365276448570310/13CD9AEFC99E778DBEE7F28B9C4FC305592ADA24/',
    BackURL:
      'http://cloud-3.steamusercontent.com/ugc/768365068750455723/A2E8BBF482ED5B11D5A19D86A38A6FB03ED2A592/',
  },
  {
    ttsID: 28300,
    name: 'Rorroh, Beautiful Nightmare',
    flags: [],
    set: 'The Dimension That Disappeared',
    FaceURL:
      'http://cloud-3.steamusercontent.com/ugc/768365276448572255/E13C2C4C2F04FE1ADEAB95DC7A8DE0F46F5545C3/',
    BackURL:
      'http://cloud-3.steamusercontent.com/ugc/768365068750455723/A2E8BBF482ED5B11D5A19D86A38A6FB03ED2A592/',
  },
  {
    ttsID: 28400,
    name: 'Silent Irregularity',
    flags: [],
    set: 'The Dimension That Disappeared',
    FaceURL:
      'http://cloud-3.steamusercontent.com/ugc/768365276448573335/ED76F2CCB1C64101A2ABCEB7225DA21D4B1D674A/',
    BackURL:
      'http://cloud-3.steamusercontent.com/ugc/768365068750455723/A2E8BBF482ED5B11D5A19D86A38A6FB03ED2A592/',
  },
  {
    ttsID: 28500,
    name: 'Wanderwolf the Inner Fear',
    flags: [],
    set: 'The Dimension That Disappeared',
    FaceURL:
      'http://cloud-3.steamusercontent.com/ugc/768365276448575085/1B8BD27E956ABBE11DE13CD279C1271254AFC5E7/',
    BackURL:
      'http://cloud-3.steamusercontent.com/ugc/768365068750455723/A2E8BBF482ED5B11D5A19D86A38A6FB03ED2A592/',
  },
  {
    ttsID: 28600,
    name: 'Caustic Silhouette',
    flags: [],
    set: 'The Dimension That Disappeared',
    FaceURL:
      'http://cloud-3.steamusercontent.com/ugc/768365276448576182/83006ECF728874857B83E998FD0D25F9C3E8551E/',
    BackURL:
      'http://cloud-3.steamusercontent.com/ugc/768365068750455723/A2E8BBF482ED5B11D5A19D86A38A6FB03ED2A592/',
  },
  {
    ttsID: 28700,
    name: 'Flashfire, Nebulous Haze',
    flags: [],
    set: 'The Dimension That Disappeared',
    FaceURL:
      'http://cloud-3.steamusercontent.com/ugc/768365276448578174/6B43A68A0781A6CDB724443E9C8A8D87C65C012D/',
    BackURL:
      'http://cloud-3.steamusercontent.com/ugc/768365068750455723/A2E8BBF482ED5B11D5A19D86A38A6FB03ED2A592/',
  },
  {
    ttsID: 28800,
    name: 'Convoluted Coalescence',
    flags: [],
    set: 'The Dimension That Disappeared',
    FaceURL:
      'http://cloud-3.steamusercontent.com/ugc/768365276448579520/2F1F9AF202763CBA6B8E48CEB2F53EC8790CB8EB/',
    BackURL:
      'http://cloud-3.steamusercontent.com/ugc/768365068750455723/A2E8BBF482ED5B11D5A19D86A38A6FB03ED2A592/',
  },
  {
    ttsID: 28900,
    name: 'Hailstone Dustmoth',
    flags: [],
    set: 'The Dimension That Disappeared',
    FaceURL:
      'http://cloud-3.steamusercontent.com/ugc/768365276448581604/063885A8A26F39BE1C70ACE878B31CDE3FDE6BFE/',
    BackURL:
      'http://cloud-3.steamusercontent.com/ugc/768365068750455723/A2E8BBF482ED5B11D5A19D86A38A6FB03ED2A592/',
  },
  {
    ttsID: 29000,
    name: 'Shardscale Pterohedron',
    flags: [],
    set: 'The Dimension That Disappeared',
    FaceURL:
      'http://cloud-3.steamusercontent.com/ugc/768365276448582846/617CEB7D83858D50E665EAC5D677BD086E0C45BC/',
    BackURL:
      'http://cloud-3.steamusercontent.com/ugc/768365068750455723/A2E8BBF482ED5B11D5A19D86A38A6FB03ED2A592/',
  },
  {
    ttsID: 29100,
    name: 'Quadrivial Wanderer',
    flags: [],
    set: 'The Dimension That Disappeared',
    FaceURL:
      'http://cloud-3.steamusercontent.com/ugc/768365276448584605/B7FD8E4C47C3126A3FADE7B79B15F93B585A4E32/',
    BackURL:
      'http://cloud-3.steamusercontent.com/ugc/768365068750455723/A2E8BBF482ED5B11D5A19D86A38A6FB03ED2A592/',
  },
  {
    ttsID: 29300,
    name: 'Frigid Lightningwell',
    flags: [],
    set: 'The Dimension That Disappeared',
    FaceURL:
      'http://cloud-3.steamusercontent.com/ugc/768365276448588272/331853A26DF609EF0A44B2FE629ECD0241AA8B3B/',
    BackURL:
      'http://cloud-3.steamusercontent.com/ugc/768365068750455723/A2E8BBF482ED5B11D5A19D86A38A6FB03ED2A592/',
  },
  {
    ttsID: 29400,
    name: 'Shardwood Amalgamation',
    flags: [],
    set: 'The Dimension That Disappeared',
    FaceURL:
      'http://cloud-3.steamusercontent.com/ugc/768365276448589791/B959D761D83D4BFD8D622994BB804D567F5CDB23/',
    BackURL:
      'http://cloud-3.steamusercontent.com/ugc/768365068750455723/A2E8BBF482ED5B11D5A19D86A38A6FB03ED2A592/',
  },
  {
    ttsID: 29500,
    name: 'Roaming Skyscraper, Langis',
    flags: [],
    set: 'The Dimension That Disappeared',
    FaceURL:
      'http://cloud-3.steamusercontent.com/ugc/768365276448594669/9734D24DC81E8DE299C173D1E63DE123F6DC9D06/',
    BackURL:
      'http://cloud-3.steamusercontent.com/ugc/768365068750455723/A2E8BBF482ED5B11D5A19D86A38A6FB03ED2A592/',
  },
  {
    ttsID: 29600,
    name: 'Carcery the Maniacal Madness',
    flags: [],
    set: 'The Dimension That Disappeared',
    FaceURL:
      'http://cloud-3.steamusercontent.com/ugc/768365276448595774/A193FED01A663FE7FC9D52C0EECABDFFFDB4EBB9/',
    BackURL:
      'http://cloud-3.steamusercontent.com/ugc/768365068750455723/A2E8BBF482ED5B11D5A19D86A38A6FB03ED2A592/',
  },
  {
    ttsID: 29700,
    name: 'Bristlecone Phantom Pine',
    flags: [],
    set: 'The Dimension That Disappeared',
    FaceURL:
      'http://cloud-3.steamusercontent.com/ugc/768365276448598216/D39A9444DF3C830CBFBACDE478D18877F83EF880/',
    BackURL:
      'http://cloud-3.steamusercontent.com/ugc/768365068750455723/A2E8BBF482ED5B11D5A19D86A38A6FB03ED2A592/',
  },
  {
    ttsID: 29800,
    name: 'Hissing Spore Valshroom',
    flags: [],
    set: 'The Dimension That Disappeared',
    FaceURL:
      'http://cloud-3.steamusercontent.com/ugc/768365276448599338/556CF0C7233E2DDE024BC0FD3F70EB1CBF69D994/',
    BackURL:
      'http://cloud-3.steamusercontent.com/ugc/768365068750455723/A2E8BBF482ED5B11D5A19D86A38A6FB03ED2A592/',
  },
  {
    ttsID: 29900,
    name: 'Mechanical Hallucination',
    flags: [],
    set: 'The Dimension That Disappeared',
    FaceURL:
      'http://cloud-3.steamusercontent.com/ugc/768365276448601858/3873727D144918DCA42257C592A4678E8A465D76/',
    BackURL:
      'http://cloud-3.steamusercontent.com/ugc/768365068750455723/A2E8BBF482ED5B11D5A19D86A38A6FB03ED2A592/',
  },
  {
    ttsID: 30000,
    name: 'Hazardous Brigantine',
    flags: [],
    set: 'The Dimension That Disappeared',
    FaceURL:
      'http://cloud-3.steamusercontent.com/ugc/768365276448603189/DF1E68633BE0F14C6AE4B2D5180EFDCE389F1832/',
    BackURL:
      'http://cloud-3.steamusercontent.com/ugc/768365068750455723/A2E8BBF482ED5B11D5A19D86A38A6FB03ED2A592/',
  },
  {
    ttsID: 31600,
    name: 'Sign of the Crow',
    flags: [],
    set: 'The Dimension That Disappeared',
    FaceURL:
      'http://cloud-3.steamusercontent.com/ugc/768365276448635678/FCCBD3753A0AD183216C237DED604A3EFE734317/',
    BackURL:
      'http://cloud-3.steamusercontent.com/ugc/768365068750455723/A2E8BBF482ED5B11D5A19D86A38A6FB03ED2A592/',
  },
  {
    ttsID: 31700,
    name: 'Chains of the Ancient One',
    flags: [],
    set: 'The Dimension That Disappeared',
    FaceURL:
      'http://cloud-3.steamusercontent.com/ugc/768365276448636721/2EA6D53959BF3A882B16A3C75AC9BD5AEF118F15/',
    BackURL:
      'http://cloud-3.steamusercontent.com/ugc/768365068750455723/A2E8BBF482ED5B11D5A19D86A38A6FB03ED2A592/',
  },
  {
    ttsID: 31800,
    name: 'Rosebound Deterrence',
    flags: [],
    set: 'The Dimension That Disappeared',
    FaceURL:
      'http://cloud-3.steamusercontent.com/ugc/768365276448638670/007577073C3A809BDA5EE14671F219EBD74D17E4/',
    BackURL:
      'http://cloud-3.steamusercontent.com/ugc/768365068750455723/A2E8BBF482ED5B11D5A19D86A38A6FB03ED2A592/',
  },
  {
    ttsID: 31900,
    name: 'Ashen Aftermath',
    flags: [],
    set: 'The Dimension That Disappeared',
    FaceURL:
      'http://cloud-3.steamusercontent.com/ugc/768365276448639614/52CA682AA45CB3EAE6EAF595698CD102FBC081F3/',
    BackURL:
      'http://cloud-3.steamusercontent.com/ugc/768365068750455723/A2E8BBF482ED5B11D5A19D86A38A6FB03ED2A592/',
  },
  {
    ttsID: 32000,
    name: 'Treeforge Boardroom',
    flags: [],
    set: 'The Dimension That Disappeared',
    FaceURL:
      'http://cloud-3.steamusercontent.com/ugc/768365276448641291/B92B6A75D62323DF1F6AD9E6BED439E2822A2FC2/',
    BackURL:
      'http://cloud-3.steamusercontent.com/ugc/768365068750455723/A2E8BBF482ED5B11D5A19D86A38A6FB03ED2A592/',
  },
  {
    ttsID: 32100,
    name: 'Cryogenic Criterion',
    flags: [],
    set: 'The Dimension That Disappeared',
    FaceURL:
      'http://cloud-3.steamusercontent.com/ugc/768365276448643259/243A02C4E3233FF3E8D358619D63BFF496BB5804/',
    BackURL:
      'http://cloud-3.steamusercontent.com/ugc/768365068750455723/A2E8BBF482ED5B11D5A19D86A38A6FB03ED2A592/',
  },
  {
    ttsID: 32200,
    name: 'Encased Runerock',
    flags: [],
    set: 'The Dimension That Disappeared',
    FaceURL:
      'http://cloud-3.steamusercontent.com/ugc/768365276448644839/92E44BEE7DA2A76F8184891C13D948E52B9B78F2/',
    BackURL:
      'http://cloud-3.steamusercontent.com/ugc/768365068750455723/A2E8BBF482ED5B11D5A19D86A38A6FB03ED2A592/',
  },
  {
    ttsID: 32300,
    name: 'Chivalry of the Wilds',
    flags: [],
    set: 'The Dimension That Disappeared',
    FaceURL:
      'http://cloud-3.steamusercontent.com/ugc/768365276448646338/CAA6850CF857552473963BAED065DD4DC23FD97D/',
    BackURL:
      'http://cloud-3.steamusercontent.com/ugc/768365068750455723/A2E8BBF482ED5B11D5A19D86A38A6FB03ED2A592/',
  },
  {
    ttsID: 32400,
    name: 'Unsound Abstraction',
    flags: [],
    set: 'The Dimension That Disappeared',
    FaceURL:
      'http://cloud-3.steamusercontent.com/ugc/768365276448652321/74C67DD001F7920B407540A38430AC1CEA609715/',
    BackURL:
      'http://cloud-3.steamusercontent.com/ugc/768365068750455723/A2E8BBF482ED5B11D5A19D86A38A6FB03ED2A592/',
  },
  {
    ttsID: 32500,
    name: 'Stormshift Obscurity',
    flags: [],
    set: 'The Dimension That Disappeared',
    FaceURL:
      'http://cloud-3.steamusercontent.com/ugc/768365276448653694/2648F40396033308C29A1616D03EAC22FBDFA71D/',
    BackURL:
      'http://cloud-3.steamusercontent.com/ugc/768365068750455723/A2E8BBF482ED5B11D5A19D86A38A6FB03ED2A592/',
  },
  {
    ttsID: 32600,
    name: 'Royal Resurgence',
    flags: [],
    set: 'The Dimension That Disappeared',
    FaceURL:
      'http://cloud-3.steamusercontent.com/ugc/768365276448655320/9615BDD111BB10BD4358001FBFEEBE5FFCC1A146/',
    BackURL:
      'http://cloud-3.steamusercontent.com/ugc/768365068750455723/A2E8BBF482ED5B11D5A19D86A38A6FB03ED2A592/',
  },
  {
    ttsID: 32700,
    name: 'Crocoite Creation',
    flags: [],
    set: 'The Dimension That Disappeared',
    FaceURL:
      'http://cloud-3.steamusercontent.com/ugc/768365276448656229/B958BAA20FBF71F0CBD80379633A1E788520C37C/',
    BackURL:
      'http://cloud-3.steamusercontent.com/ugc/768365068750455723/A2E8BBF482ED5B11D5A19D86A38A6FB03ED2A592/',
  },
  {
    ttsID: 32800,
    name: 'Violet Vividicta',
    flags: [],
    set: 'The Dimension That Disappeared',
    FaceURL:
      'http://cloud-3.steamusercontent.com/ugc/768365276448657807/6D317E8EBF48654251C2AE9A8BEEE52564CC83AA/',
    BackURL:
      'http://cloud-3.steamusercontent.com/ugc/768365068750455723/A2E8BBF482ED5B11D5A19D86A38A6FB03ED2A592/',
  },
  {
    ttsID: 32900,
    name: 'Reluctant Crown Jewel',
    flags: [],
    set: 'The Dimension That Disappeared',
    FaceURL:
      'http://cloud-3.steamusercontent.com/ugc/768365276448658778/102336070A4C91986E228EE58E00CFEFB147A891/',
    BackURL:
      'http://cloud-3.steamusercontent.com/ugc/768365068750455723/A2E8BBF482ED5B11D5A19D86A38A6FB03ED2A592/',
  },
  {
    ttsID: 33000,
    name: 'Starplume Shroom',
    flags: [],
    set: 'The Dimension That Disappeared',
    FaceURL:
      'http://cloud-3.steamusercontent.com/ugc/768365276448660215/5F130FF88B1862B60FF33C8BA654F3D44A6D1B27/',
    BackURL:
      'http://cloud-3.steamusercontent.com/ugc/768365068750455723/A2E8BBF482ED5B11D5A19D86A38A6FB03ED2A592/',
  },
  {
    ttsID: 33100,
    name: 'Dreamdaze Reverie',
    flags: [],
    set: 'The Dimension That Disappeared',
    FaceURL:
      'http://cloud-3.steamusercontent.com/ugc/768365276448661093/E84D763335786E74C138D14CE75A94135F71C8A8/',
    BackURL:
      'http://cloud-3.steamusercontent.com/ugc/768365068750455723/A2E8BBF482ED5B11D5A19D86A38A6FB03ED2A592/',
  },
  {
    ttsID: 33200,
    name: 'Cavernous Scraphollow',
    flags: [],
    set: 'The Dimension That Disappeared',
    FaceURL:
      'http://cloud-3.steamusercontent.com/ugc/768365276448662774/D6D74C56618429D2EDABCC27C6BA8179D91F58DA/',
    BackURL:
      'http://cloud-3.steamusercontent.com/ugc/768365068750455723/A2E8BBF482ED5B11D5A19D86A38A6FB03ED2A592/',
  },
  {
    ttsID: 33300,
    name: 'Wearweather Specter',
    flags: [],
    set: 'The Dimension That Disappeared',
    FaceURL:
      'http://cloud-3.steamusercontent.com/ugc/768365276448663853/43A395DF0461BC02292F84541735D7F626BCB067/',
    BackURL:
      'http://cloud-3.steamusercontent.com/ugc/768365068750455723/A2E8BBF482ED5B11D5A19D86A38A6FB03ED2A592/',
  },
  {
    ttsID: 26100,
    name: 'Fractured Portals',
    flags: [],
    set: 'The Dimension That Disappeared',
    FaceURL:
      'http://cloud-3.steamusercontent.com/ugc/768365276448537594/D4F4AD83B32788D5D2AA9F1FFB66FCA3E2745814/',
    BackURL:
      'http://cloud-3.steamusercontent.com/ugc/768365068750455723/A2E8BBF482ED5B11D5A19D86A38A6FB03ED2A592/',
  },
  {
    ttsID: 26200,
    name: 'Cease to Exist',
    flags: [],
    set: 'The Dimension That Disappeared',
    FaceURL:
      'http://cloud-3.steamusercontent.com/ugc/768365276448539469/04F131475D80785B20E12070A70A3D8B82B52564/',
    BackURL:
      'http://cloud-3.steamusercontent.com/ugc/768365068750455723/A2E8BBF482ED5B11D5A19D86A38A6FB03ED2A592/',
  },
  {
    ttsID: 26300,
    name: 'Anthem of Humanity',
    flags: [],
    set: 'The Dimension That Disappeared',
    FaceURL:
      'http://cloud-3.steamusercontent.com/ugc/768365276448541765/8D641484D2B32DF2F78F66B3C742E721DC2DCD4B/',
    BackURL:
      'http://cloud-3.steamusercontent.com/ugc/768365068750455723/A2E8BBF482ED5B11D5A19D86A38A6FB03ED2A592/',
  },
  {
    ttsID: 26400,
    name: 'Dimensional Rupture',
    flags: [],
    set: 'The Dimension That Disappeared',
    FaceURL:
      'http://cloud-3.steamusercontent.com/ugc/768365276448542631/0562642C5D2701C81323A88B67C6231C6E8C8472/',
    BackURL:
      'http://cloud-3.steamusercontent.com/ugc/768365068750455723/A2E8BBF482ED5B11D5A19D86A38A6FB03ED2A592/',
  },
  {
    ttsID: 26500,
    name: 'Sandblasted Source',
    flags: [],
    set: 'The Dimension That Disappeared',
    FaceURL:
      'http://cloud-3.steamusercontent.com/ugc/768365276448544515/2E9753BF39DC47F63490EA515BF0DDD8B21474EE/',
    BackURL:
      'http://cloud-3.steamusercontent.com/ugc/768365068750455723/A2E8BBF482ED5B11D5A19D86A38A6FB03ED2A592/',
  },
  {
    ttsID: 26600,
    name: 'Chronic Collision',
    flags: [],
    set: 'The Dimension That Disappeared',
    FaceURL:
      'http://cloud-3.steamusercontent.com/ugc/768365276448545387/2B92151CBD587AC61C8ECEFA3830BB12BFD2634D/',
    BackURL:
      'http://cloud-3.steamusercontent.com/ugc/768365068750455723/A2E8BBF482ED5B11D5A19D86A38A6FB03ED2A592/',
  },
  {
    ttsID: 26700,
    name: 'Dead of Night',
    flags: [],
    set: 'The Dimension That Disappeared',
    FaceURL:
      'http://cloud-3.steamusercontent.com/ugc/768365276448547143/DDD9019522997A9249BBBA60ACD54D595151ADF0/',
    BackURL:
      'http://cloud-3.steamusercontent.com/ugc/768365068750455723/A2E8BBF482ED5B11D5A19D86A38A6FB03ED2A592/',
  },
  {
    ttsID: 26800,
    name: 'Project Oblivion',
    flags: [],
    set: 'The Dimension That Disappeared',
    FaceURL:
      'http://cloud-3.steamusercontent.com/ugc/768365276448549133/77D7E54E32E1D5D3409AB1273613BD130744CA33/',
    BackURL:
      'http://cloud-3.steamusercontent.com/ugc/768365068750455723/A2E8BBF482ED5B11D5A19D86A38A6FB03ED2A592/',
  },
  {
    ttsID: 26900,
    name: 'Red Eye Flight',
    flags: [],
    set: 'The Dimension That Disappeared',
    FaceURL:
      'http://cloud-3.steamusercontent.com/ugc/768365276448550842/D9B6619C3FF8EEC80970BCA3D8E7D1F2758EAE99/',
    BackURL:
      'http://cloud-3.steamusercontent.com/ugc/768365068750455723/A2E8BBF482ED5B11D5A19D86A38A6FB03ED2A592/',
  },
  {
    ttsID: 27000,
    name: 'Frozen Fortitude',
    flags: [],
    set: 'The Dimension That Disappeared',
    FaceURL:
      'http://cloud-3.steamusercontent.com/ugc/768365276448551993/113FDE225E134DE67828BCD8E4E4D9E50E70DCCB/',
    BackURL:
      'http://cloud-3.steamusercontent.com/ugc/768365068750455723/A2E8BBF482ED5B11D5A19D86A38A6FB03ED2A592/',
  },
  {
    ttsID: 27100,
    name: 'Guild Leader Symposium',
    flags: [],
    set: 'The Dimension That Disappeared',
    FaceURL:
      'http://cloud-3.steamusercontent.com/ugc/768365276448554262/FEB4CD330ECFEC6BD438B71A036305559334D656/',
    BackURL:
      'http://cloud-3.steamusercontent.com/ugc/768365068750455723/A2E8BBF482ED5B11D5A19D86A38A6FB03ED2A592/',
  },
  {
    ttsID: 27200,
    name: 'Malevolent Mirage',
    flags: [],
    set: 'The Dimension That Disappeared',
    FaceURL:
      'http://cloud-3.steamusercontent.com/ugc/768365276448555275/CB2E4A1917F18D7F95D9C1C72E9E9EF02050CDDD/',
    BackURL:
      'http://cloud-3.steamusercontent.com/ugc/768365068750455723/A2E8BBF482ED5B11D5A19D86A38A6FB03ED2A592/',
  },
  {
    ttsID: 27300,
    name: 'Valcrow Allegiant',
    flags: [],
    set: 'The Dimension That Disappeared',
    FaceURL:
      'http://cloud-3.steamusercontent.com/ugc/768365276448557031/A7F2B0EEFAB66367C59293BD72568F1CFAF15335/',
    BackURL:
      'http://cloud-3.steamusercontent.com/ugc/768365068750455723/A2E8BBF482ED5B11D5A19D86A38A6FB03ED2A592/',
  },
  {
    ttsID: 27400,
    name: 'Decaying Desert Rose',
    flags: [],
    set: 'The Dimension That Disappeared',
    FaceURL:
      'http://cloud-3.steamusercontent.com/ugc/768365276448557974/9576AA261E78CE543AAAD5C6E5FCF6EF7381FAEE/',
    BackURL:
      'http://cloud-3.steamusercontent.com/ugc/768365068750455723/A2E8BBF482ED5B11D5A19D86A38A6FB03ED2A592/',
  },
  {
    ttsID: 27500,
    name: 'Slithering Altershade',
    flags: [],
    set: 'The Dimension That Disappeared',
    FaceURL:
      'http://cloud-3.steamusercontent.com/ugc/768365276448559700/F18D64832D72516704BFBC86850082E18508F75F/',
    BackURL:
      'http://cloud-3.steamusercontent.com/ugc/768365068750455723/A2E8BBF482ED5B11D5A19D86A38A6FB03ED2A592/',
  },
  {
    ttsID: 27600,
    name: 'Infected Vanadinite Lancer',
    flags: [],
    set: 'The Dimension That Disappeared',
    FaceURL:
      'http://cloud-3.steamusercontent.com/ugc/768365276448560790/15A544AD81B39062F8BC7760706D5D57C9B4FF11/',
    BackURL:
      'http://cloud-3.steamusercontent.com/ugc/768365068750455723/A2E8BBF482ED5B11D5A19D86A38A6FB03ED2A592/',
  },
  {
    ttsID: 27700,
    name: 'Stalactite Hasp',
    flags: [],
    set: 'The Dimension That Disappeared',
    FaceURL:
      'http://cloud-3.steamusercontent.com/ugc/768365276448562231/1C039344295C87870A62C2B7603AA04594E4D21E/',
    BackURL:
      'http://cloud-3.steamusercontent.com/ugc/768365068750455723/A2E8BBF482ED5B11D5A19D86A38A6FB03ED2A592/',
  },
  {
    ttsID: 27800,
    name: 'Waterval Hydra',
    flags: [],
    set: 'The Dimension That Disappeared',
    FaceURL:
      'http://cloud-3.steamusercontent.com/ugc/768365276448563652/A8ECBFB05A18F123EBD7961BC945464B6D89B1D1/',
    BackURL:
      'http://cloud-3.steamusercontent.com/ugc/768365068750455723/A2E8BBF482ED5B11D5A19D86A38A6FB03ED2A592/',
  },
  {
    ttsID: 33500,
    name: 'Tarnishwood Cypress',
    flags: [],
    set: 'The Dimension That Disappeared',
    FaceURL:
      'http://cloud-3.steamusercontent.com/ugc/768365276448721315/7114B8B85AACEB0B624B14C33C9B708C1C5E5084/',
    BackURL:
      'http://cloud-3.steamusercontent.com/ugc/768365068750455723/A2E8BBF482ED5B11D5A19D86A38A6FB03ED2A592/',
  },
  {
    ttsID: 33600,
    name: 'Waterfall Evorgnam',
    flags: [],
    set: 'The Dimension That Disappeared',
    FaceURL:
      'http://cloud-3.steamusercontent.com/ugc/768365276448725217/7D012027FD6CB72AA27BD9C72B51F12F47AE3973/',
    BackURL:
      'http://cloud-3.steamusercontent.com/ugc/768365068750455723/A2E8BBF482ED5B11D5A19D86A38A6FB03ED2A592/',
  },
  {
    ttsID: 27900,
    name: 'Dangerous Cannoneer Palishroomis',
    flags: [],
    set: 'The Dimension That Disappeared',
    FaceURL:
      'http://cloud-3.steamusercontent.com/ugc/768365276448565126/CB1E2603DB5CA1FE2F510A8CF22994DFAA1AEE37/',
    BackURL:
      'http://cloud-3.steamusercontent.com/ugc/768365068750455723/A2E8BBF482ED5B11D5A19D86A38A6FB03ED2A592/',
  },
  {
    ttsID: 28000,
    name: 'Steamshaft Eidolon',
    flags: [],
    set: 'The Dimension That Disappeared',
    FaceURL:
      'http://cloud-3.steamusercontent.com/ugc/768365276448566887/43411E3F8D59F7A03FEE740D9443F88EFC531E7D/',
    BackURL:
      'http://cloud-3.steamusercontent.com/ugc/768365068750455723/A2E8BBF482ED5B11D5A19D86A38A6FB03ED2A592/',
  },
  {
    ttsID: 28100,
    name: 'Valchron',
    flags: [],
    set: 'The Dimension That Disappeared',
    FaceURL:
      'http://cloud-3.steamusercontent.com/ugc/768365276448569139/D5CF23142FA1C7D0167010E4081FB8267C64B6F5/',
    BackURL:
      'http://cloud-3.steamusercontent.com/ugc/768365068750455723/A2E8BBF482ED5B11D5A19D86A38A6FB03ED2A592/',
  },
  {
    ttsID: 26000,
    name: 'End of the Rift',
    flags: [],
    set: 'The Dimension That Disappeared',
    FaceURL:
      'http://cloud-3.steamusercontent.com/ugc/768365276448532481/BA8C0BF822A2756C5396EF7D884C03FC4558ED2F/',
    BackURL:
      'http://cloud-3.steamusercontent.com/ugc/768365068750455723/A2E8BBF482ED5B11D5A19D86A38A6FB03ED2A592/',
  },
  {
    ttsID: 30800,
    name: 'Harrowing Haunt',
    flags: ['SR'],
    set: 'The Dimension That Disappeared',
    FaceURL:
      'http://cloud-3.steamusercontent.com/ugc/768365276448622685/B95173789EC455139406B4CE0DEA3A5520A590C7/',
    BackURL:
      'http://cloud-3.steamusercontent.com/ugc/768365068750455723/A2E8BBF482ED5B11D5A19D86A38A6FB03ED2A592/',
  },
  {
    ttsID: 30900,
    name: 'Desecrated Shipwreck',
    flags: ['SR'],
    set: 'The Dimension That Disappeared',
    FaceURL:
      'http://cloud-3.steamusercontent.com/ugc/768365276448624381/579584247EC8BB63C71060FFC9F2E4D10B7B355D/',
    BackURL:
      'http://cloud-3.steamusercontent.com/ugc/768365068750455723/A2E8BBF482ED5B11D5A19D86A38A6FB03ED2A592/',
  },
  {
    ttsID: 46300,
    name: 'Anathema',
    flags: ['Front, Single Sided', 'SR'],
    set: 'The Dimension That Disappeared',
    FaceURL:
      'http://cloud-3.steamusercontent.com/ugc/768365276448626344/FF634DF3949878BE68531AFC7C3A3B1D9FE5F075/',
    BackURL:
      'http://cloud-3.steamusercontent.com/ugc/768365068750455723/A2E8BBF482ED5B11D5A19D86A38A6FB03ED2A592/',
  },
  {
    ttsID: 46500,
    name: 'Anathema',
    flags: ['Back, Single Sided', 'SR'],
    set: 'The Dimension That Disappeared',
    FaceURL:
      'http://cloud-3.steamusercontent.com/ugc/768365276448626905/127521EFED276E7963944ECD97C4D928F99A8CAD/',
    BackURL:
      'http://cloud-3.steamusercontent.com/ugc/768365068750455723/A2E8BBF482ED5B11D5A19D86A38A6FB03ED2A592/',
  },
  {
    ttsID: 31100,
    name: 'Devourer of Dimensions',
    flags: ['SR'],
    set: 'The Dimension That Disappeared',
    FaceURL:
      'http://cloud-3.steamusercontent.com/ugc/768365276448628329/759A4CE37994A9E496F132CCD7F103D168EEDDD5/',
    BackURL:
      'http://cloud-3.steamusercontent.com/ugc/768365068750455723/A2E8BBF482ED5B11D5A19D86A38A6FB03ED2A592/',
  },
  {
    ttsID: 31200,
    name: 'Verneejus the Molecular Mockery',
    flags: ['SR'],
    set: 'The Dimension That Disappeared',
    FaceURL:
      'http://cloud-3.steamusercontent.com/ugc/768365276448630221/ED74AB3F9604499DD6CF57DB1967C7B41CAE506C/',
    BackURL:
      'http://cloud-3.steamusercontent.com/ugc/768365068750455723/A2E8BBF482ED5B11D5A19D86A38A6FB03ED2A592/',
  },
  {
    ttsID: 31300,
    name: 'Vanciere the Future Phenomenon',
    flags: ['SR'],
    set: 'The Dimension That Disappeared',
    FaceURL:
      'http://cloud-3.steamusercontent.com/ugc/768365276448631191/993DE371D2A04742D47505E6E478CC8053D11CFD/',
    BackURL:
      'http://cloud-3.steamusercontent.com/ugc/768365068750455723/A2E8BBF482ED5B11D5A19D86A38A6FB03ED2A592/',
  },
  {
    ttsID: 31400,
    name: 'Coldcrux, Root of the World',
    flags: ['SR'],
    set: 'The Dimension That Disappeared',
    FaceURL:
      'http://cloud-3.steamusercontent.com/ugc/768365276448633162/FCFFE1FBC80494CE3FE364CD01D35DE7D56DBDE3/',
    BackURL:
      'http://cloud-3.steamusercontent.com/ugc/768365068750455723/A2E8BBF482ED5B11D5A19D86A38A6FB03ED2A592/',
  },
  {
    ttsID: 31500,
    name: "Timeglypha, Eeventide's Second Key",
    flags: ['SR'],
    set: 'The Dimension That Disappeared',
    FaceURL:
      'http://cloud-3.steamusercontent.com/ugc/768365276448634071/1CD541FCC22BD756AD8A85495646B761C400C38C/',
    BackURL:
      'http://cloud-3.steamusercontent.com/ugc/768365068750455723/A2E8BBF482ED5B11D5A19D86A38A6FB03ED2A592/',
  },
  {
    ttsID: 31000,
    name: 'Anathema',
    flags: ['Double Sided', 'SR'],
    set: 'The Dimension That Disappeared',
    FaceURL:
      'http://cloud-3.steamusercontent.com/ugc/768365276448626344/FF634DF3949878BE68531AFC7C3A3B1D9FE5F075/',
    BackURL:
      'http://cloud-3.steamusercontent.com/ugc/768365276448626905/127521EFED276E7963944ECD97C4D928F99A8CAD/',
  },
  {
    ttsID: 18000,
    name: 'Roaring Sanctuary',
    flags: [],
    set: 'Converging Chasms',
    FaceURL:
      'http://cloud-3.steamusercontent.com/ugc/768365068754841972/3A0091EF1D127CA3FD63A70A2C8FC3F146A9EFA1/',
    BackURL:
      'http://cloud-3.steamusercontent.com/ugc/768365068750455723/A2E8BBF482ED5B11D5A19D86A38A6FB03ED2A592/',
  },
  {
    ttsID: 19100,
    name: "Shardveil's Onrush",
    flags: [],
    set: 'Converging Chasms',
    FaceURL:
      'http://cloud-3.steamusercontent.com/ugc/768365068754925367/B7D4FF40150CB67F235B6B8E8202EA8EDEA224F5/',
    BackURL:
      'http://cloud-3.steamusercontent.com/ugc/768365068750455723/A2E8BBF482ED5B11D5A19D86A38A6FB03ED2A592/',
  },
  {
    ttsID: 20200,
    name: 'Treeforge Envoy',
    flags: [],
    set: 'Converging Chasms',
    FaceURL:
      'http://cloud-3.steamusercontent.com/ugc/768365068754951675/9079DA10F1CF02DA75AE5017961A5D47E84BA803/',
    BackURL:
      'http://cloud-3.steamusercontent.com/ugc/768365068750455723/A2E8BBF482ED5B11D5A19D86A38A6FB03ED2A592/',
  },
  {
    ttsID: 21300,
    name: 'Shardveil Courier',
    flags: [],
    set: 'Converging Chasms',
    FaceURL:
      'http://cloud-3.steamusercontent.com/ugc/768365068754977338/6A4077F9904A40E30D18D9FEB8EFE207E2B9D294/',
    BackURL:
      'http://cloud-3.steamusercontent.com/ugc/768365068750455723/A2E8BBF482ED5B11D5A19D86A38A6FB03ED2A592/',
  },
  {
    ttsID: 22400,
    name: 'Portals Collide',
    flags: [],
    set: 'Converging Chasms',
    FaceURL:
      'http://cloud-3.steamusercontent.com/ugc/768365068754998925/7AB607C0015621C7C28415D41B899ACD270E8A04/',
    BackURL:
      'http://cloud-3.steamusercontent.com/ugc/768365068750455723/A2E8BBF482ED5B11D5A19D86A38A6FB03ED2A592/',
  },
  {
    ttsID: 23500,
    name: 'Release',
    flags: [],
    set: 'Converging Chasms',
    FaceURL:
      'http://cloud-3.steamusercontent.com/ugc/768365068755017776/D8F3135C0BC665A4CB35658A31570308AC57E8E7/',
    BackURL:
      'http://cloud-3.steamusercontent.com/ugc/768365068750455723/A2E8BBF482ED5B11D5A19D86A38A6FB03ED2A592/',
  },
  {
    ttsID: 24600,
    name: 'Mysterious Sojourner',
    flags: [],
    set: 'Converging Chasms',
    FaceURL:
      'http://cloud-3.steamusercontent.com/ugc/768365068755047178/0AA2FFD23792DBBD08DF24C8742556D57DF21404/',
    BackURL:
      'http://cloud-3.steamusercontent.com/ugc/768365068750455723/A2E8BBF482ED5B11D5A19D86A38A6FB03ED2A592/',
  },
  {
    ttsID: 25600,
    name: 'Skyborn Guild Castle',
    flags: [],
    set: 'Converging Chasms',
    FaceURL:
      'http://cloud-3.steamusercontent.com/ugc/768365068755077358/6F2535987A4786EB09E1B6CC4B07CF530A0B8D6D/',
    BackURL:
      'http://cloud-3.steamusercontent.com/ugc/768365068750455723/A2E8BBF482ED5B11D5A19D86A38A6FB03ED2A592/',
  },
  {
    ttsID: 17000,
    name: 'Alluvion the Brisk Tide',
    flags: [],
    set: 'Converging Chasms',
    FaceURL:
      'http://cloud-3.steamusercontent.com/ugc/768365068754819965/7E20DB64F79FF83CD5408E89E655C33E7B3CA8CF/',
    BackURL:
      'http://cloud-3.steamusercontent.com/ugc/768365068750455723/A2E8BBF482ED5B11D5A19D86A38A6FB03ED2A592/',
  },
  {
    ttsID: 17100,
    name: 'Shatterglass Owl',
    flags: [],
    set: 'Converging Chasms',
    FaceURL:
      'http://cloud-3.steamusercontent.com/ugc/768365068754821836/F42A3DED287B9F0FD475625B5FF8B10F831F3EE5/',
    BackURL:
      'http://cloud-3.steamusercontent.com/ugc/768365068750455723/A2E8BBF482ED5B11D5A19D86A38A6FB03ED2A592/',
  },
  {
    ttsID: 17200,
    name: 'Crystalline Stag',
    flags: [],
    set: 'Converging Chasms',
    FaceURL:
      'http://cloud-3.steamusercontent.com/ugc/768365068754823168/EFB6E667730EF46DE7395AEA87DD0EE7BC5091AF/',
    BackURL:
      'http://cloud-3.steamusercontent.com/ugc/768365068750455723/A2E8BBF482ED5B11D5A19D86A38A6FB03ED2A592/',
  },
  {
    ttsID: 17300,
    name: 'Snow Bastion Aberration',
    flags: [],
    set: 'Converging Chasms',
    FaceURL:
      'http://cloud-3.steamusercontent.com/ugc/768365068754824490/1B420482297E75FD299DDF15B29E899ED31B2305/',
    BackURL:
      'http://cloud-3.steamusercontent.com/ugc/768365068750455723/A2E8BBF482ED5B11D5A19D86A38A6FB03ED2A592/',
  },
  {
    ttsID: 17400,
    name: 'Planetary Crag of the Cosmos',
    flags: [],
    set: 'Converging Chasms',
    FaceURL:
      'http://cloud-3.steamusercontent.com/ugc/768365068754825412/990B20FEBF39EA1BBE46B4DC34FA7DADD2F475A0/',
    BackURL:
      'http://cloud-3.steamusercontent.com/ugc/768365068750455723/A2E8BBF482ED5B11D5A19D86A38A6FB03ED2A592/',
  },
  {
    ttsID: 17500,
    name: 'Knight of the Frozen Claymore',
    flags: [],
    set: 'Converging Chasms',
    FaceURL:
      'http://cloud-3.steamusercontent.com/ugc/768365068754826935/969033500246136F9BAF8DF7C57BE06037CC0EE8/',
    BackURL:
      'http://cloud-3.steamusercontent.com/ugc/768365068750455723/A2E8BBF482ED5B11D5A19D86A38A6FB03ED2A592/',
  },
  {
    ttsID: 17600,
    name: "Vastion the World's Hollow",
    flags: [],
    set: 'Converging Chasms',
    FaceURL:
      'http://cloud-3.steamusercontent.com/ugc/768365068754828167/B3538A99072660D9B44B83A283BBD0FB30471BF6/',
    BackURL:
      'http://cloud-3.steamusercontent.com/ugc/768365068750455723/A2E8BBF482ED5B11D5A19D86A38A6FB03ED2A592/',
  },
  {
    ttsID: 17700,
    name: 'Overgrown Trench Valchasm',
    flags: [],
    set: 'Converging Chasms',
    FaceURL:
      'http://cloud-3.steamusercontent.com/ugc/768365068754829513/B56E79612B7C7A773C7727198F7666C9AE430D49/',
    BackURL:
      'http://cloud-3.steamusercontent.com/ugc/768365068750455723/A2E8BBF482ED5B11D5A19D86A38A6FB03ED2A592/',
  },
  {
    ttsID: 17800,
    name: 'Mistrai Driftwood',
    flags: [],
    set: 'Converging Chasms',
    FaceURL:
      'http://cloud-3.steamusercontent.com/ugc/768365068754838256/0DCE122B9D70EDCA3F579F8CB0F417E17BBE2822/',
    BackURL:
      'http://cloud-3.steamusercontent.com/ugc/768365068750455723/A2E8BBF482ED5B11D5A19D86A38A6FB03ED2A592/',
  },
  {
    ttsID: 17900,
    name: 'Amethyst Voidcap',
    flags: [],
    set: 'Converging Chasms',
    FaceURL:
      'http://cloud-3.steamusercontent.com/ugc/768365068754840652/152BEED139D2A2BEC1929EB2E732E6BB70CA696A/',
    BackURL:
      'http://cloud-3.steamusercontent.com/ugc/768365068750455723/A2E8BBF482ED5B11D5A19D86A38A6FB03ED2A592/',
  },
  {
    ttsID: 18100,
    name: 'Cankerous Needlespire',
    flags: [],
    set: 'Converging Chasms',
    FaceURL:
      'http://cloud-3.steamusercontent.com/ugc/768365068754843289/D37BD3977FBFB6AB62CCA3282266F71C61CC9BCF/',
    BackURL:
      'http://cloud-3.steamusercontent.com/ugc/768365068750455723/A2E8BBF482ED5B11D5A19D86A38A6FB03ED2A592/',
  },
  {
    ttsID: 18200,
    name: 'Tanglewood Snag',
    flags: [],
    set: 'Converging Chasms',
    FaceURL:
      'http://cloud-3.steamusercontent.com/ugc/768365068754844402/D8E6D77DEAE316F57658B2B7DD63BF5649DDD481/',
    BackURL:
      'http://cloud-3.steamusercontent.com/ugc/768365068750455723/A2E8BBF482ED5B11D5A19D86A38A6FB03ED2A592/',
  },
  {
    ttsID: 18300,
    name: 'Embershard Siren',
    flags: [],
    set: 'Converging Chasms',
    FaceURL:
      'http://cloud-3.steamusercontent.com/ugc/768365068754848536/C81FE12FD666E88D7E9FD648D3470350BE86B2EE/',
    BackURL:
      'http://cloud-3.steamusercontent.com/ugc/768365068750455723/A2E8BBF482ED5B11D5A19D86A38A6FB03ED2A592/',
  },
  {
    ttsID: 18400,
    name: 'Clockwork Automata',
    flags: [],
    set: 'Converging Chasms',
    FaceURL:
      'http://cloud-3.steamusercontent.com/ugc/768365068754849460/66AD1958B4CB4E327738886FB5917D5F036E8D54/',
    BackURL:
      'http://cloud-3.steamusercontent.com/ugc/768365068750455723/A2E8BBF482ED5B11D5A19D86A38A6FB03ED2A592/',
  },
  {
    ttsID: 18500,
    name: 'Horncrest Cloudgorger',
    flags: [],
    set: 'Converging Chasms',
    FaceURL:
      'http://cloud-3.steamusercontent.com/ugc/768365068754851261/CDB3E0020165B786EB8C4F8F411C717B73249FCF/',
    BackURL:
      'http://cloud-3.steamusercontent.com/ugc/768365068750455723/A2E8BBF482ED5B11D5A19D86A38A6FB03ED2A592/',
  },
  {
    ttsID: 18600,
    name: 'Angelica the Vast',
    flags: [],
    set: 'Converging Chasms',
    FaceURL:
      'http://cloud-3.steamusercontent.com/ugc/768365068754852101/3EA5C637E2410A00192B7006C52E307C27322576/',
    BackURL:
      'http://cloud-3.steamusercontent.com/ugc/768365068750455723/A2E8BBF482ED5B11D5A19D86A38A6FB03ED2A592/',
  },
  {
    ttsID: 18700,
    name: 'Cascil the Unvanishing Paladin',
    flags: [],
    set: 'Converging Chasms',
    FaceURL:
      'http://cloud-3.steamusercontent.com/ugc/768365068754854419/6BF7A517BD95723DAF387FBEADB694A0A6CE22DA/',
    BackURL:
      'http://cloud-3.steamusercontent.com/ugc/768365068750455723/A2E8BBF482ED5B11D5A19D86A38A6FB03ED2A592/',
  },
  {
    ttsID: 18800,
    name: 'Ephemra the Star Shield',
    flags: [],
    set: 'Converging Chasms',
    FaceURL:
      'http://cloud-3.steamusercontent.com/ugc/768365068754919821/A186E6BD700642C08ED4224E191CD728E7E9A3BC/',
    BackURL:
      'http://cloud-3.steamusercontent.com/ugc/768365068750455723/A2E8BBF482ED5B11D5A19D86A38A6FB03ED2A592/',
  },
  {
    ttsID: 18900,
    name: 'Starbringer',
    flags: [],
    set: 'Converging Chasms',
    FaceURL:
      'http://cloud-3.steamusercontent.com/ugc/768365068754922032/F4765296EDC31FDA9AC19CD7D3BFFD70FAC52BE9/',
    BackURL:
      'http://cloud-3.steamusercontent.com/ugc/768365068750455723/A2E8BBF482ED5B11D5A19D86A38A6FB03ED2A592/',
  },
  {
    ttsID: 19000,
    name: 'Turbine Apparatus',
    flags: [],
    set: 'Converging Chasms',
    FaceURL:
      'http://cloud-3.steamusercontent.com/ugc/768365068754923500/321C35EBF7BA2859DD9A4B77396E21BA5E73F43F/',
    BackURL:
      'http://cloud-3.steamusercontent.com/ugc/768365068750455723/A2E8BBF482ED5B11D5A19D86A38A6FB03ED2A592/',
  },
  {
    ttsID: 19200,
    name: 'Grandiose Infector',
    flags: [],
    set: 'Converging Chasms',
    FaceURL:
      'http://cloud-3.steamusercontent.com/ugc/768365068754927029/9FDD9067624732FFEAEBCF19A94653EA4F65EF68/',
    BackURL:
      'http://cloud-3.steamusercontent.com/ugc/768365068750455723/A2E8BBF482ED5B11D5A19D86A38A6FB03ED2A592/',
  },
  {
    ttsID: 19300,
    name: 'Dimensional Phoenix',
    flags: [],
    set: 'Converging Chasms',
    FaceURL:
      'http://cloud-3.steamusercontent.com/ugc/768365068754928907/A2823BFBA99C7EE0922D20F7C591EC2667254799/',
    BackURL:
      'http://cloud-3.steamusercontent.com/ugc/768365068750455723/A2E8BBF482ED5B11D5A19D86A38A6FB03ED2A592/',
  },
  {
    ttsID: 19400,
    name: 'Heart of Treeforge',
    flags: [],
    set: 'Converging Chasms',
    FaceURL:
      'http://cloud-3.steamusercontent.com/ugc/768365068754930261/FE6D50FB7601632675BCFAF296A38B30A2363634/',
    BackURL:
      'http://cloud-3.steamusercontent.com/ugc/768365068750455723/A2E8BBF482ED5B11D5A19D86A38A6FB03ED2A592/',
  },
  {
    ttsID: 19500,
    name: 'Scenic Succession',
    flags: [],
    set: 'Converging Chasms',
    FaceURL:
      'http://cloud-3.steamusercontent.com/ugc/768365068754933285/42DFC2B3A18FFBEA286A7D4F0205C89DDB3700A5/',
    BackURL:
      'http://cloud-3.steamusercontent.com/ugc/768365068750455723/A2E8BBF482ED5B11D5A19D86A38A6FB03ED2A592/',
  },
  {
    ttsID: 19600,
    name: 'Main Deck Strike',
    flags: [],
    set: 'Converging Chasms',
    FaceURL:
      'http://cloud-3.steamusercontent.com/ugc/768365068754936208/50962086E8AA9C59C64CD8F6686773C3F656AAC2/',
    BackURL:
      'http://cloud-3.steamusercontent.com/ugc/768365068750455723/A2E8BBF482ED5B11D5A19D86A38A6FB03ED2A592/',
  },
  {
    ttsID: 19700,
    name: 'Return from Stardust',
    flags: [],
    set: 'Converging Chasms',
    FaceURL:
      'http://cloud-3.steamusercontent.com/ugc/768365068754938349/3D77308F1EC0D8F8B476C520D68D341811F2B695/',
    BackURL:
      'http://cloud-3.steamusercontent.com/ugc/768365068750455723/A2E8BBF482ED5B11D5A19D86A38A6FB03ED2A592/',
  },
  {
    ttsID: 19800,
    name: 'Firefly Grotto',
    flags: [],
    set: 'Converging Chasms',
    FaceURL:
      'http://cloud-3.steamusercontent.com/ugc/768365068754939871/DC792779239C9483FA75E1B5503BA2AB48FFD223/',
    BackURL:
      'http://cloud-3.steamusercontent.com/ugc/768365068750455723/A2E8BBF482ED5B11D5A19D86A38A6FB03ED2A592/',
  },
  {
    ttsID: 19900,
    name: 'Twilight Gorge',
    flags: [],
    set: 'Converging Chasms',
    FaceURL:
      'http://cloud-3.steamusercontent.com/ugc/768365068754941646/E624F8751B8A0EC4C223A8141F0821F94773C3E5/',
    BackURL:
      'http://cloud-3.steamusercontent.com/ugc/768365068750455723/A2E8BBF482ED5B11D5A19D86A38A6FB03ED2A592/',
  },
  {
    ttsID: 20000,
    name: 'Starborn Outbreak',
    flags: [],
    set: 'Converging Chasms',
    FaceURL:
      'http://cloud-3.steamusercontent.com/ugc/768365068754942852/597D388C56075A6864EFCCF4264C72799C3C201D/',
    BackURL:
      'http://cloud-3.steamusercontent.com/ugc/768365068750455723/A2E8BBF482ED5B11D5A19D86A38A6FB03ED2A592/',
  },
  {
    ttsID: 20100,
    name: 'Banner of Valor',
    flags: [],
    set: 'Converging Chasms',
    FaceURL:
      'http://cloud-3.steamusercontent.com/ugc/768365068754949252/FBD23306766061E11F602305AF6771318CE012DE/',
    BackURL:
      'http://cloud-3.steamusercontent.com/ugc/768365068750455723/A2E8BBF482ED5B11D5A19D86A38A6FB03ED2A592/',
  },
  {
    ttsID: 20300,
    name: 'Starsleet Downpour',
    flags: [],
    set: 'Converging Chasms',
    FaceURL:
      'http://cloud-3.steamusercontent.com/ugc/768365068754953887/FDA9828B7B7724D08BD3F5027780037C3D6C1D5F/',
    BackURL:
      'http://cloud-3.steamusercontent.com/ugc/768365068750455723/A2E8BBF482ED5B11D5A19D86A38A6FB03ED2A592/',
  },
  {
    ttsID: 20400,
    name: 'Portal Fall Valley',
    flags: [],
    set: 'Converging Chasms',
    FaceURL:
      'http://cloud-3.steamusercontent.com/ugc/768365068754955886/FC7B25CC2607818876B5FD423CE116E1407CC226/',
    BackURL:
      'http://cloud-3.steamusercontent.com/ugc/768365068750455723/A2E8BBF482ED5B11D5A19D86A38A6FB03ED2A592/',
  },
  {
    ttsID: 20500,
    name: 'Lostep the Glacial Phantom',
    flags: [],
    set: 'Converging Chasms',
    FaceURL:
      'http://cloud-3.steamusercontent.com/ugc/768365068754958107/9881B7FD089F61287FC8352920C5D0D22AE3BA8A/',
    BackURL:
      'http://cloud-3.steamusercontent.com/ugc/768365068750455723/A2E8BBF482ED5B11D5A19D86A38A6FB03ED2A592/',
  },
  {
    ttsID: 20600,
    name: 'Crystalveil Sniper',
    flags: [],
    set: 'Converging Chasms',
    FaceURL:
      'http://cloud-3.steamusercontent.com/ugc/768365068754959684/58E58676DE1A530B7EACC2AA4FF5B2EB38110406/',
    BackURL:
      'http://cloud-3.steamusercontent.com/ugc/768365068750455723/A2E8BBF482ED5B11D5A19D86A38A6FB03ED2A592/',
  },
  {
    ttsID: 20700,
    name: 'Chaos Bull',
    flags: [],
    set: 'Converging Chasms',
    FaceURL:
      'http://cloud-3.steamusercontent.com/ugc/768365068754965327/490265A7DE57C1739EF52639DADEF22381A88CB5/',
    BackURL:
      'http://cloud-3.steamusercontent.com/ugc/768365068750455723/A2E8BBF482ED5B11D5A19D86A38A6FB03ED2A592/',
  },
  {
    ttsID: 20800,
    name: 'Searing Scorchgrove',
    flags: [],
    set: 'Converging Chasms',
    FaceURL:
      'http://cloud-3.steamusercontent.com/ugc/768365068754966820/ACB1985BE04699FC8AB7EAAD103A4EB353AFDD3C/',
    BackURL:
      'http://cloud-3.steamusercontent.com/ugc/768365068750455723/A2E8BBF482ED5B11D5A19D86A38A6FB03ED2A592/',
  },
  {
    ttsID: 20900,
    name: 'Blossombark Gaper',
    flags: [],
    set: 'Converging Chasms',
    FaceURL:
      'http://cloud-3.steamusercontent.com/ugc/768365068754970744/1C5F6FD94745B09DD00A1202521AEC4F1BB658A3/',
    BackURL:
      'http://cloud-3.steamusercontent.com/ugc/768365068750455723/A2E8BBF482ED5B11D5A19D86A38A6FB03ED2A592/',
  },
  {
    ttsID: 21000,
    name: 'Crankshaft Oak',
    flags: [],
    set: 'Converging Chasms',
    FaceURL:
      'http://cloud-3.steamusercontent.com/ugc/768365068754971830/D41F29B9E00DBF3846B5396F3561BFDC5BF99635/',
    BackURL:
      'http://cloud-3.steamusercontent.com/ugc/768365068750455723/A2E8BBF482ED5B11D5A19D86A38A6FB03ED2A592/',
  },
  {
    ttsID: 21100,
    name: 'Everbloom Celestial',
    flags: [],
    set: 'Converging Chasms',
    FaceURL:
      'http://cloud-3.steamusercontent.com/ugc/768365068754974017/53694C060FCFEC15A7ABA3F3CFF5E6ED75DE47CA/',
    BackURL:
      'http://cloud-3.steamusercontent.com/ugc/768365068750455723/A2E8BBF482ED5B11D5A19D86A38A6FB03ED2A592/',
  },
  {
    ttsID: 21200,
    name: 'Roiling Storm Thunderhymn',
    flags: [],
    set: 'Converging Chasms',
    FaceURL:
      'http://cloud-3.steamusercontent.com/ugc/768365068754975123/66F66BBF59AC1AF7C668B4D2BD4BA89DF1C98CA9/',
    BackURL:
      'http://cloud-3.steamusercontent.com/ugc/768365068750455723/A2E8BBF482ED5B11D5A19D86A38A6FB03ED2A592/',
  },
  {
    ttsID: 21400,
    name: 'Viridian Peak',
    flags: [],
    set: 'Converging Chasms',
    FaceURL:
      'http://cloud-3.steamusercontent.com/ugc/768365068754981503/EFF78BFB82EDC608201D06BBF70B3178030B61FA/',
    BackURL:
      'http://cloud-3.steamusercontent.com/ugc/768365068750455723/A2E8BBF482ED5B11D5A19D86A38A6FB03ED2A592/',
  },
  {
    ttsID: 21500,
    name: 'Dimensional Octopus',
    flags: [],
    set: 'Converging Chasms',
    FaceURL:
      'http://cloud-3.steamusercontent.com/ugc/768365068754985957/9D71E02D79ACDB31FDD225DC59EAD4687328AC35/',
    BackURL:
      'http://cloud-3.steamusercontent.com/ugc/768365068750455723/A2E8BBF482ED5B11D5A19D86A38A6FB03ED2A592/',
  },
  {
    ttsID: 21600,
    name: 'Timeless Machination',
    flags: [],
    set: 'Converging Chasms',
    FaceURL:
      'http://cloud-3.steamusercontent.com/ugc/768365068754986979/FCE9742E0BA938397691AB76231ABFCF2A281531/',
    BackURL:
      'http://cloud-3.steamusercontent.com/ugc/768365068750455723/A2E8BBF482ED5B11D5A19D86A38A6FB03ED2A592/',
  },
  {
    ttsID: 21700,
    name: 'Treacherous Forethought',
    flags: [],
    set: 'Converging Chasms',
    FaceURL:
      'http://cloud-3.steamusercontent.com/ugc/768365068754988525/F336BB6185097C61315FE74D70BF41932EA1CFE8/',
    BackURL:
      'http://cloud-3.steamusercontent.com/ugc/768365068750455723/A2E8BBF482ED5B11D5A19D86A38A6FB03ED2A592/',
  },
  {
    ttsID: 21800,
    name: 'Traverse the Treetops',
    flags: [],
    set: 'Converging Chasms',
    FaceURL:
      'http://cloud-3.steamusercontent.com/ugc/768365068754989918/D5F9A18B540DF4D746C58496CC969C4EFF69C9F4/',
    BackURL:
      'http://cloud-3.steamusercontent.com/ugc/768365068750455723/A2E8BBF482ED5B11D5A19D86A38A6FB03ED2A592/',
  },
  {
    ttsID: 21900,
    name: "Guild's Advancement",
    flags: [],
    set: 'Converging Chasms',
    FaceURL:
      'http://cloud-3.steamusercontent.com/ugc/768365068754992153/70B4E68C5E111E649C745D5405ABDE5EC672B0D7/',
    BackURL:
      'http://cloud-3.steamusercontent.com/ugc/768365068750455723/A2E8BBF482ED5B11D5A19D86A38A6FB03ED2A592/',
  },
  {
    ttsID: 22000,
    name: 'Dusgrove Stairway',
    flags: [],
    set: 'Converging Chasms',
    FaceURL:
      'http://cloud-3.steamusercontent.com/ugc/768365068754993336/C292AD4B6F1690AB0B3E061EB838368C0BDE54BA/',
    BackURL:
      'http://cloud-3.steamusercontent.com/ugc/768365068750455723/A2E8BBF482ED5B11D5A19D86A38A6FB03ED2A592/',
  },
  {
    ttsID: 22100,
    name: 'Secluded Shortcut',
    flags: [],
    set: 'Converging Chasms',
    FaceURL:
      'http://cloud-3.steamusercontent.com/ugc/768365068754995183/637C967AA35BA67A6B03EED36E3AB0C8FA08EA78/',
    BackURL:
      'http://cloud-3.steamusercontent.com/ugc/768365068750455723/A2E8BBF482ED5B11D5A19D86A38A6FB03ED2A592/',
  },
  {
    ttsID: 22200,
    name: 'Dragonis Guild Citadel',
    flags: [],
    set: 'Converging Chasms',
    FaceURL:
      'http://cloud-3.steamusercontent.com/ugc/768365068754996396/F57B3372E21FEF618B122FB4E30D369127331592/',
    BackURL:
      'http://cloud-3.steamusercontent.com/ugc/768365068750455723/A2E8BBF482ED5B11D5A19D86A38A6FB03ED2A592/',
  },
  {
    ttsID: 22300,
    name: 'Harmonized',
    flags: [],
    set: 'Converging Chasms',
    FaceURL:
      'http://cloud-3.steamusercontent.com/ugc/768365068754998034/FF445A07661289FB8CD406290B926E9EC8C99313/',
    BackURL:
      'http://cloud-3.steamusercontent.com/ugc/768365068750455723/A2E8BBF482ED5B11D5A19D86A38A6FB03ED2A592/',
  },
  {
    ttsID: 22500,
    name: 'Volcanic Vehemence',
    flags: [],
    set: 'Converging Chasms',
    FaceURL:
      'http://cloud-3.steamusercontent.com/ugc/768365068755001093/CEE303FE648900A150B54EB117C4033CBFCC1F74/',
    BackURL:
      'http://cloud-3.steamusercontent.com/ugc/768365068750455723/A2E8BBF482ED5B11D5A19D86A38A6FB03ED2A592/',
  },
  {
    ttsID: 22600,
    name: "Plateau's Edge",
    flags: [],
    set: 'Converging Chasms',
    FaceURL:
      'http://cloud-3.steamusercontent.com/ugc/768365068755002689/29F12B4A3ADC8F3C3533552661BD7F71C2B16C0E/',
    BackURL:
      'http://cloud-3.steamusercontent.com/ugc/768365068750455723/A2E8BBF482ED5B11D5A19D86A38A6FB03ED2A592/',
  },
  {
    ttsID: 22700,
    name: 'Collateral Damage',
    flags: [],
    set: 'Converging Chasms',
    FaceURL:
      'http://cloud-3.steamusercontent.com/ugc/768365068755004967/A1FF9F70ADA3D842CB6C32231C3EAA7880A9041D/',
    BackURL:
      'http://cloud-3.steamusercontent.com/ugc/768365068750455723/A2E8BBF482ED5B11D5A19D86A38A6FB03ED2A592/',
  },
  {
    ttsID: 22800,
    name: 'Buried Plains',
    flags: [],
    set: 'Converging Chasms',
    FaceURL:
      'http://cloud-3.steamusercontent.com/ugc/768365068755006139/75C1B3DA01DF78A7F0B30C14A634DA68749106A5/',
    BackURL:
      'http://cloud-3.steamusercontent.com/ugc/768365068750455723/A2E8BBF482ED5B11D5A19D86A38A6FB03ED2A592/',
  },
  {
    ttsID: 22900,
    name: "Stellarfalls, the Cardsmith's Haven",
    flags: [],
    set: 'Converging Chasms',
    FaceURL:
      'http://cloud-3.steamusercontent.com/ugc/768365068755008514/5AE19248ABF70A754C7827B5592DD17AB991C6A8/',
    BackURL:
      'http://cloud-3.steamusercontent.com/ugc/768365068750455723/A2E8BBF482ED5B11D5A19D86A38A6FB03ED2A592/',
  },
  {
    ttsID: 23000,
    name: 'Departure',
    flags: [],
    set: 'Converging Chasms',
    FaceURL:
      'http://cloud-3.steamusercontent.com/ugc/768365068755009621/33E5109221DEDFF50B1902E3B58434E94A29B9FF/',
    BackURL:
      'http://cloud-3.steamusercontent.com/ugc/768365068750455723/A2E8BBF482ED5B11D5A19D86A38A6FB03ED2A592/',
  },
  {
    ttsID: 23100,
    name: 'Dimensional Destruction',
    flags: [],
    set: 'Converging Chasms',
    FaceURL:
      'http://cloud-3.steamusercontent.com/ugc/768365068755011093/D6F8799E124416E9BD17F2E612226BDD58F1265B/',
    BackURL:
      'http://cloud-3.steamusercontent.com/ugc/768365068750455723/A2E8BBF482ED5B11D5A19D86A38A6FB03ED2A592/',
  },
  {
    ttsID: 23200,
    name: 'Emerald Slithercrest',
    flags: [],
    set: 'Converging Chasms',
    FaceURL:
      'http://cloud-3.steamusercontent.com/ugc/768365068755012811/752065FAAC18B046AEE6663DC38C5A4AD3CABADB/',
    BackURL:
      'http://cloud-3.steamusercontent.com/ugc/768365068750455723/A2E8BBF482ED5B11D5A19D86A38A6FB03ED2A592/',
  },
  {
    ttsID: 23300,
    name: 'Frostgear Behemoth',
    flags: [],
    set: 'Converging Chasms',
    FaceURL:
      'http://cloud-3.steamusercontent.com/ugc/768365068755014623/D0A53D89E618229AEEBEE39ECBFCF182E2125712/',
    BackURL:
      'http://cloud-3.steamusercontent.com/ugc/768365068750455723/A2E8BBF482ED5B11D5A19D86A38A6FB03ED2A592/',
  },
  {
    ttsID: 23400,
    name: 'Valcrater Bandits',
    flags: [],
    set: 'Converging Chasms',
    FaceURL:
      'http://cloud-3.steamusercontent.com/ugc/768365068755015691/63757078F7B83F7F1F172C9F19337B9D3AEEAE3A/',
    BackURL:
      'http://cloud-3.steamusercontent.com/ugc/768365068750455723/A2E8BBF482ED5B11D5A19D86A38A6FB03ED2A592/',
  },
  {
    ttsID: 23600,
    name: 'Dustwood Alder',
    flags: [],
    set: 'Converging Chasms',
    FaceURL:
      'http://cloud-3.steamusercontent.com/ugc/768365068755019091/3B8566939DFCD911427F99CC9764340E5026A53C/',
    BackURL:
      'http://cloud-3.steamusercontent.com/ugc/768365068750455723/A2E8BBF482ED5B11D5A19D86A38A6FB03ED2A592/',
  },
  {
    ttsID: 23700,
    name: 'Wildfire Willow',
    flags: [],
    set: 'Converging Chasms',
    FaceURL:
      'http://cloud-3.steamusercontent.com/ugc/768365068755021255/DFE5A8790562961F5010D958F15EFFD9D5027380/',
    BackURL:
      'http://cloud-3.steamusercontent.com/ugc/768365068750455723/A2E8BBF482ED5B11D5A19D86A38A6FB03ED2A592/',
  },
  {
    ttsID: 23800,
    name: 'Elishroom the Potion Runner',
    flags: [],
    set: 'Converging Chasms',
    FaceURL:
      'http://cloud-3.steamusercontent.com/ugc/768365068755022575/A8D43DA7F3A50DC9D4A669B667839AC36247F375/',
    BackURL:
      'http://cloud-3.steamusercontent.com/ugc/768365068750455723/A2E8BBF482ED5B11D5A19D86A38A6FB03ED2A592/',
  },
  {
    ttsID: 23900,
    name: 'Duneweaver Slithe',
    flags: [],
    set: 'Converging Chasms',
    FaceURL:
      'http://cloud-3.steamusercontent.com/ugc/768365068755024190/4225E1A123155854842705EE4241FE7410562EFB/',
    BackURL:
      'http://cloud-3.steamusercontent.com/ugc/768365068750455723/A2E8BBF482ED5B11D5A19D86A38A6FB03ED2A592/',
  },
  {
    ttsID: 24000,
    name: 'Ordained Dragon',
    flags: [],
    set: 'Converging Chasms',
    FaceURL:
      'http://cloud-3.steamusercontent.com/ugc/768365068755025338/365003CF1E68274B28370F728E9A28098368C7D0/',
    BackURL:
      'http://cloud-3.steamusercontent.com/ugc/768365068750455723/A2E8BBF482ED5B11D5A19D86A38A6FB03ED2A592/',
  },
  {
    ttsID: 24100,
    name: 'Drangelic Outcross',
    flags: [],
    set: 'Converging Chasms',
    FaceURL:
      'http://cloud-3.steamusercontent.com/ugc/768365068755034999/BF728716998ECB16E165E9FF8F8FE7F96A3D3AF8/',
    BackURL:
      'http://cloud-3.steamusercontent.com/ugc/768365068750455723/A2E8BBF482ED5B11D5A19D86A38A6FB03ED2A592/',
  },
  {
    ttsID: 24200,
    name: 'Wielder of the Cross Blade',
    flags: [],
    set: 'Converging Chasms',
    FaceURL:
      'http://cloud-3.steamusercontent.com/ugc/768365068755037384/D0C6D987B12EC4A1577C72B34613081365F79354/',
    BackURL:
      'http://cloud-3.steamusercontent.com/ugc/768365068750455723/A2E8BBF482ED5B11D5A19D86A38A6FB03ED2A592/',
  },
  {
    ttsID: 24300,
    name: 'Portalstep Paragon',
    flags: [],
    set: 'Converging Chasms',
    FaceURL:
      'http://cloud-3.steamusercontent.com/ugc/768365068755041652/64BDE17008B15092C44BE8AC9CC62D8378032942/',
    BackURL:
      'http://cloud-3.steamusercontent.com/ugc/768365068750455723/A2E8BBF482ED5B11D5A19D86A38A6FB03ED2A592/',
  },
  {
    ttsID: 24400,
    name: 'Dimensional Doe',
    flags: [],
    set: 'Converging Chasms',
    FaceURL:
      'http://cloud-3.steamusercontent.com/ugc/768365068755043730/2C20529AD4FE103D83829FE88FE423FEF1856FE2/',
    BackURL:
      'http://cloud-3.steamusercontent.com/ugc/768365068750455723/A2E8BBF482ED5B11D5A19D86A38A6FB03ED2A592/',
  },
  {
    ttsID: 24500,
    name: 'Hyperexhaust Automaton',
    flags: [],
    set: 'Converging Chasms',
    FaceURL:
      'http://cloud-3.steamusercontent.com/ugc/768365068755044926/A89866B14FB644993896367AAB0AA6E35370F491/',
    BackURL:
      'http://cloud-3.steamusercontent.com/ugc/768365068750455723/A2E8BBF482ED5B11D5A19D86A38A6FB03ED2A592/',
  },
  {
    ttsID: 24700,
    name: 'Sentient Anomaly',
    flags: [],
    set: 'Converging Chasms',
    FaceURL:
      'http://cloud-3.steamusercontent.com/ugc/768365068755048293/13F3745E68A171A6E57D6AD1545366E23580AE0C/',
    BackURL:
      'http://cloud-3.steamusercontent.com/ugc/768365068750455723/A2E8BBF482ED5B11D5A19D86A38A6FB03ED2A592/',
  },
  {
    ttsID: 25200,
    name: 'Grandaria, Forger of Songs',
    flags: ['SR'],
    set: 'Converging Chasms',
    FaceURL:
      'http://cloud-3.steamusercontent.com/ugc/768365068755071206/5DAB86DF4BD1177D002821A002F7EEB343BB942F/',
    BackURL:
      'http://cloud-3.steamusercontent.com/ugc/768365068750455723/A2E8BBF482ED5B11D5A19D86A38A6FB03ED2A592/',
  },
  {
    ttsID: 24800,
    name: "Paladin's Parable",
    flags: ['SR'],
    set: 'Converging Chasms',
    FaceURL:
      'http://cloud-3.steamusercontent.com/ugc/768365068755051401/7A068882E17BA3ADF9B126017CB45C5FC5803A38/',
    BackURL:
      'http://cloud-3.steamusercontent.com/ugc/768365068750455723/A2E8BBF482ED5B11D5A19D86A38A6FB03ED2A592/',
  },
  {
    ttsID: 24900,
    name: 'Roxanne the Final Paladin',
    flags: ['SR'],
    set: 'Converging Chasms',
    FaceURL:
      'http://cloud-3.steamusercontent.com/ugc/768365068755052270/7F9EF458FB22380B4DF250FB2D6B2EA043F8AF47/',
    BackURL:
      'http://cloud-3.steamusercontent.com/ugc/768365068750455723/A2E8BBF482ED5B11D5A19D86A38A6FB03ED2A592/',
  },
  {
    ttsID: 25000,
    name: 'Hollowfire Halo Dragon',
    flags: ['SR'],
    set: 'Converging Chasms',
    FaceURL:
      'http://cloud-3.steamusercontent.com/ugc/768365068755053844/EDBCFD610306230BDD738C6D52F9E7C6D9EAD2B9/',
    BackURL:
      'http://cloud-3.steamusercontent.com/ugc/768365068750455723/A2E8BBF482ED5B11D5A19D86A38A6FB03ED2A592/',
  },
  {
    ttsID: 25100,
    name: "Runeglyphis, Eeventide's First Key",
    flags: ['SR'],
    set: 'Converging Chasms',
    FaceURL:
      'http://cloud-3.steamusercontent.com/ugc/768365068755055425/74EC8489AAC74E7D59F105C79600E78C20D9B94D/',
    BackURL:
      'http://cloud-3.steamusercontent.com/ugc/768365068750455723/A2E8BBF482ED5B11D5A19D86A38A6FB03ED2A592/',
  },
  {
    ttsID: 25300,
    name: 'Carcery Vale the Miscreation',
    flags: ['SR'],
    set: 'Converging Chasms',
    FaceURL:
      'http://cloud-3.steamusercontent.com/ugc/768365068755072931/9146E7592A7C161A7B5DF313A1C4C8B0612E47D5/',
    BackURL:
      'http://cloud-3.steamusercontent.com/ugc/768365068750455723/A2E8BBF482ED5B11D5A19D86A38A6FB03ED2A592/',
  },
  {
    ttsID: 25400,
    name: 'Harpoon Halberdier',
    flags: ['SR'],
    set: 'Converging Chasms',
    FaceURL:
      'http://cloud-3.steamusercontent.com/ugc/768365068755074201/9B863707FFA2646B67BE98E9DEAE5C92B8E73F1E/',
    BackURL:
      'http://cloud-3.steamusercontent.com/ugc/768365068750455723/A2E8BBF482ED5B11D5A19D86A38A6FB03ED2A592/',
  },
  {
    ttsID: 25500,
    name: 'Legendary Thoughtglaive',
    flags: ['SR'],
    set: 'Converging Chasms',
    FaceURL:
      'http://cloud-3.steamusercontent.com/ugc/768365068755076307/1FDB278083D79AD18CC7DF69AE59E9D42886E69A/',
    BackURL:
      'http://cloud-3.steamusercontent.com/ugc/768365068750455723/A2E8BBF482ED5B11D5A19D86A38A6FB03ED2A592/',
  },
  {
    ttsID: 15900,
    name: 'Vanquish, Reverser of Time',
    flags: [],
    set: 'Crystal Forge',
    FaceURL:
      'http://cloud-3.steamusercontent.com/ugc/768365068754770607/52DE065F21CE0A6F5B847752D2AB63D110C68006/',
    BackURL:
      'http://cloud-3.steamusercontent.com/ugc/768365068750455723/A2E8BBF482ED5B11D5A19D86A38A6FB03ED2A592/',
  },
  {
    ttsID: 16200,
    name: 'Harbinger Mushroom',
    flags: [],
    set: 'Crystal Forge',
    FaceURL:
      'http://cloud-3.steamusercontent.com/ugc/768365068754774545/5B001298BE1F16C5E001B1AA34AB7331565051B2/',
    BackURL:
      'http://cloud-3.steamusercontent.com/ugc/768365068750455723/A2E8BBF482ED5B11D5A19D86A38A6FB03ED2A592/',
  },
  {
    ttsID: 16300,
    name: 'Carcery the Chemical Oddity',
    flags: [],
    set: 'Crystal Forge',
    FaceURL:
      'http://cloud-3.steamusercontent.com/ugc/768365068754776223/B3E82B3FF15B3F9272276018A917BDDE3EBF2C88/',
    BackURL:
      'http://cloud-3.steamusercontent.com/ugc/768365068750455723/A2E8BBF482ED5B11D5A19D86A38A6FB03ED2A592/',
  },
  {
    ttsID: 16400,
    name: 'Brilliant Brightveil',
    flags: [],
    set: 'Crystal Forge',
    FaceURL:
      'http://cloud-3.steamusercontent.com/ugc/768365068754777942/1ACFEE91F0BEBE66074CE7357E4D1B0D00EF6FF8/',
    BackURL:
      'http://cloud-3.steamusercontent.com/ugc/768365068750455723/A2E8BBF482ED5B11D5A19D86A38A6FB03ED2A592/',
  },
  {
    ttsID: 16500,
    name: 'Creaking Rotwood',
    flags: [],
    set: 'Crystal Forge',
    FaceURL:
      'http://cloud-3.steamusercontent.com/ugc/768365068754779530/9E0F141300BCD2C44F0677A36B49DF25D4F2D11A/',
    BackURL:
      'http://cloud-3.steamusercontent.com/ugc/768365068750455723/A2E8BBF482ED5B11D5A19D86A38A6FB03ED2A592/',
  },
  {
    ttsID: 16600,
    name: 'Sporeheart Shroom',
    flags: [],
    set: 'Crystal Forge',
    FaceURL:
      'http://cloud-3.steamusercontent.com/ugc/768365068754780547/621363C5328A99233CB89C4D52858D4BF39ACA1A/',
    BackURL:
      'http://cloud-3.steamusercontent.com/ugc/768365068750455723/A2E8BBF482ED5B11D5A19D86A38A6FB03ED2A592/',
  },
  {
    ttsID: 16700,
    name: 'Venus Treetrap',
    flags: [],
    set: 'Crystal Forge',
    FaceURL:
      'http://cloud-3.steamusercontent.com/ugc/768365068754782747/2117922B13903F21BB822E0336C1C711F416EE21/',
    BackURL:
      'http://cloud-3.steamusercontent.com/ugc/768365068750455723/A2E8BBF482ED5B11D5A19D86A38A6FB03ED2A592/',
  },
  {
    ttsID: 16800,
    name: 'Strangler Fig',
    flags: [],
    set: 'Crystal Forge',
    FaceURL:
      'http://cloud-3.steamusercontent.com/ugc/768365068754785861/DB28B770D5623167EBB0A8A425D17093167B2FD4/',
    BackURL:
      'http://cloud-3.steamusercontent.com/ugc/768365068750455723/A2E8BBF482ED5B11D5A19D86A38A6FB03ED2A592/',
  },
  {
    ttsID: 16900,
    name: 'Sparkspore Cluster',
    flags: [],
    set: 'Crystal Forge',
    FaceURL:
      'http://cloud-3.steamusercontent.com/ugc/768365068754786801/12CDF2789603E46375F34F70F84C1177CBA85B38/',
    BackURL:
      'http://cloud-3.steamusercontent.com/ugc/768365068750455723/A2E8BBF482ED5B11D5A19D86A38A6FB03ED2A592/',
  },
  {
    ttsID: 14900,
    name: 'Lightstruck Lebanon',
    flags: [],
    set: 'Crystal Forge',
    FaceURL:
      'http://cloud-3.steamusercontent.com/ugc/768365068754752784/BBEE3FA537CE1D2A0B38F3A2E1834D5D18AC8878/',
    BackURL:
      'http://cloud-3.steamusercontent.com/ugc/768365068750455723/A2E8BBF482ED5B11D5A19D86A38A6FB03ED2A592/',
  },
  {
    ttsID: 15000,
    name: 'Roseroot Red Cedar',
    flags: [],
    set: 'Crystal Forge',
    FaceURL:
      'http://cloud-3.steamusercontent.com/ugc/768365068754754075/ED17DDCD008ED1BE083D1BD5D32B808D80199553/',
    BackURL:
      'http://cloud-3.steamusercontent.com/ugc/768365068750455723/A2E8BBF482ED5B11D5A19D86A38A6FB03ED2A592/',
  },
  {
    ttsID: 15100,
    name: 'Treeforge Complex',
    flags: [],
    set: 'Crystal Forge',
    FaceURL:
      'http://cloud-3.steamusercontent.com/ugc/768365068754756003/283DBFEEFCFFDDE1311C2103A477BF7C90EAAF6B/',
    BackURL:
      'http://cloud-3.steamusercontent.com/ugc/768365068750455723/A2E8BBF482ED5B11D5A19D86A38A6FB03ED2A592/',
  },
  {
    ttsID: 15200,
    name: "Rose's Reach",
    flags: [],
    set: 'Crystal Forge',
    FaceURL:
      'http://cloud-3.steamusercontent.com/ugc/768365068754757245/F202B165B9432682B1C492DDE1C8B93BC2F84CA5/',
    BackURL:
      'http://cloud-3.steamusercontent.com/ugc/768365068750455723/A2E8BBF482ED5B11D5A19D86A38A6FB03ED2A592/',
  },
  {
    ttsID: 15300,
    name: 'Hidden Haven',
    flags: [],
    set: 'Crystal Forge',
    FaceURL:
      'http://cloud-3.steamusercontent.com/ugc/768365068754759077/155E36BD30043BFC938035C2A89D0BA6875D3763/',
    BackURL:
      'http://cloud-3.steamusercontent.com/ugc/768365068750455723/A2E8BBF482ED5B11D5A19D86A38A6FB03ED2A592/',
  },
  {
    ttsID: 15400,
    name: 'Helver Formation',
    flags: [],
    set: 'Crystal Forge',
    FaceURL:
      'http://cloud-3.steamusercontent.com/ugc/768365068754760442/AA552D97BDA2A9D40ED188924D11BC38F919016C/',
    BackURL:
      'http://cloud-3.steamusercontent.com/ugc/768365068750455723/A2E8BBF482ED5B11D5A19D86A38A6FB03ED2A592/',
  },
  {
    ttsID: 15500,
    name: 'Starlit Expanse',
    flags: [],
    set: 'Crystal Forge',
    FaceURL:
      'http://cloud-3.steamusercontent.com/ugc/768365068754764308/957C3358A94D3EA0E13E7088673E810B3CB32B27/',
    BackURL:
      'http://cloud-3.steamusercontent.com/ugc/768365068750455723/A2E8BBF482ED5B11D5A19D86A38A6FB03ED2A592/',
  },
  {
    ttsID: 15600,
    name: 'Treelight Convoy',
    flags: [],
    set: 'Crystal Forge',
    FaceURL:
      'http://cloud-3.steamusercontent.com/ugc/768365068754765518/D382A94A958A9F4FEDC1401DBB0754856C632620/',
    BackURL:
      'http://cloud-3.steamusercontent.com/ugc/768365068750455723/A2E8BBF482ED5B11D5A19D86A38A6FB03ED2A592/',
  },
  {
    ttsID: 15700,
    name: 'Nebula Geyser Meadow',
    flags: [],
    set: 'Crystal Forge',
    FaceURL:
      'http://cloud-3.steamusercontent.com/ugc/768365068754767292/EA36243D56E4087B3101E70CF9486376BDBFA8BE/',
    BackURL:
      'http://cloud-3.steamusercontent.com/ugc/768365068750455723/A2E8BBF482ED5B11D5A19D86A38A6FB03ED2A592/',
  },
  {
    ttsID: 15800,
    name: 'Siphoning Energy Roots',
    flags: [],
    set: 'Crystal Forge',
    FaceURL:
      'http://cloud-3.steamusercontent.com/ugc/768365068754768642/CD6E6743125EFBB4997C0FD633F7E5616F687C91/',
    BackURL:
      'http://cloud-3.steamusercontent.com/ugc/768365068750455723/A2E8BBF482ED5B11D5A19D86A38A6FB03ED2A592/',
  },
  {
    ttsID: 16000,
    name: 'Energy Dispersal',
    flags: [],
    set: 'Crystal Forge',
    FaceURL:
      'http://cloud-3.steamusercontent.com/ugc/768365068754771721/8B45C3BD31C8820AF514E69C39FEFAF287233DF2/',
    BackURL:
      'http://cloud-3.steamusercontent.com/ugc/768365068750455723/A2E8BBF482ED5B11D5A19D86A38A6FB03ED2A592/',
  },
  {
    ttsID: 16100,
    name: 'Howling Synthesis',
    flags: [],
    set: 'Crystal Forge',
    FaceURL:
      'http://cloud-3.steamusercontent.com/ugc/768365068754773521/E76260E4C77A09FFDA3F3E0AE63899AEE7BA0CEC/',
    BackURL:
      'http://cloud-3.steamusercontent.com/ugc/768365068750455723/A2E8BBF482ED5B11D5A19D86A38A6FB03ED2A592/',
  },
  {
    ttsID: 12800,
    name: 'Cierice the Liquescent Icicle',
    flags: [],
    set: 'Crystal Forge',
    FaceURL:
      'http://cloud-3.steamusercontent.com/ugc/768365068754710607/33EAD4A025A385F5959E63BA1CD32A4D1BBA3D7F/',
    BackURL:
      'http://cloud-3.steamusercontent.com/ugc/768365068750455723/A2E8BBF482ED5B11D5A19D86A38A6FB03ED2A592/',
  },
  {
    ttsID: 12900,
    name: 'Awakened Star Glacier',
    flags: [],
    set: 'Crystal Forge',
    FaceURL:
      'http://cloud-3.steamusercontent.com/ugc/768365068754712374/E050DDDD2A33D88F75098AEB0D031B9C363DF780/',
    BackURL:
      'http://cloud-3.steamusercontent.com/ugc/768365068750455723/A2E8BBF482ED5B11D5A19D86A38A6FB03ED2A592/',
  },
  {
    ttsID: 13000,
    name: 'Chief Bandit',
    flags: [],
    set: 'Crystal Forge',
    FaceURL:
      'http://cloud-3.steamusercontent.com/ugc/768365068754713380/9FE332FAD45661445BBC9CFBF73AF08303DDF243/',
    BackURL:
      'http://cloud-3.steamusercontent.com/ugc/768365068750455723/A2E8BBF482ED5B11D5A19D86A38A6FB03ED2A592/',
  },
  {
    ttsID: 13100,
    name: 'Calcite Cavalier',
    flags: [],
    set: 'Crystal Forge',
    FaceURL:
      'http://cloud-3.steamusercontent.com/ugc/768365068754715437/A36013C8C656EBC1D3E45EBC0341F96569B99650/',
    BackURL:
      'http://cloud-3.steamusercontent.com/ugc/768365068750455723/A2E8BBF482ED5B11D5A19D86A38A6FB03ED2A592/',
  },
  {
    ttsID: 13200,
    name: 'Tourmaline Dragon',
    flags: [],
    set: 'Crystal Forge',
    FaceURL:
      'http://cloud-3.steamusercontent.com/ugc/768365068754716676/2AEF803C8E05ED22474317A3B75F767A2A0630A5/',
    BackURL:
      'http://cloud-3.steamusercontent.com/ugc/768365068750455723/A2E8BBF482ED5B11D5A19D86A38A6FB03ED2A592/',
  },
  {
    ttsID: 13300,
    name: 'Crystalfire Wolf',
    flags: [],
    set: 'Crystal Forge',
    FaceURL:
      'http://cloud-3.steamusercontent.com/ugc/768365068754718281/475BAE6B5CD5021521A4583405BE17172C11CC20/',
    BackURL:
      'http://cloud-3.steamusercontent.com/ugc/768365068750455723/A2E8BBF482ED5B11D5A19D86A38A6FB03ED2A592/',
  },
  {
    ttsID: 13400,
    name: 'Garnet Marauder',
    flags: [],
    set: 'Crystal Forge',
    FaceURL:
      'http://cloud-3.steamusercontent.com/ugc/768365068754719482/9AEEF470E690BFEA2397B20EBBFAD3EF825AB933/',
    BackURL:
      'http://cloud-3.steamusercontent.com/ugc/768365068750455723/A2E8BBF482ED5B11D5A19D86A38A6FB03ED2A592/',
  },
  {
    ttsID: 13500,
    name: 'Hailstorm Hysteria',
    flags: [],
    set: 'Crystal Forge',
    FaceURL:
      'http://cloud-3.steamusercontent.com/ugc/768365068754722559/E98D387475E4FB9FD72B7CDA3384E26D7A9EA0FA/',
    BackURL:
      'http://cloud-3.steamusercontent.com/ugc/768365068750455723/A2E8BBF482ED5B11D5A19D86A38A6FB03ED2A592/',
  },
  {
    ttsID: 13600,
    name: 'Crystal Leviathan',
    flags: [],
    set: 'Crystal Forge',
    FaceURL:
      'http://cloud-3.steamusercontent.com/ugc/768365068754723905/1FAA730F3C32BAD0F76ABE849E1128A5DF4C32FB/',
    BackURL:
      'http://cloud-3.steamusercontent.com/ugc/768365068750455723/A2E8BBF482ED5B11D5A19D86A38A6FB03ED2A592/',
  },
  {
    ttsID: 13700,
    name: 'Crystal Hammerhead',
    flags: [],
    set: 'Crystal Forge',
    FaceURL:
      'http://cloud-3.steamusercontent.com/ugc/768365068754725399/6C4F0F0EC8B0FEE32BE38D6341848772ADEA4B7A/',
    BackURL:
      'http://cloud-3.steamusercontent.com/ugc/768365068750455723/A2E8BBF482ED5B11D5A19D86A38A6FB03ED2A592/',
  },
  {
    ttsID: 13800,
    name: 'Frostdew Phoenix',
    flags: [],
    set: 'Crystal Forge',
    FaceURL:
      'http://cloud-3.steamusercontent.com/ugc/768365068754729647/AAA5D4E5C79A149C24E4DCF1B841B32158552734/',
    BackURL:
      'http://cloud-3.steamusercontent.com/ugc/768365068750455723/A2E8BBF482ED5B11D5A19D86A38A6FB03ED2A592/',
  },
  {
    ttsID: 13900,
    name: 'Crystal Cove',
    flags: [],
    set: 'Crystal Forge',
    FaceURL:
      'http://cloud-3.steamusercontent.com/ugc/768365068754735067/C7D88E5667671360B170AF3DCC5FC06AA66BFC55/',
    BackURL:
      'http://cloud-3.steamusercontent.com/ugc/768365068750455723/A2E8BBF482ED5B11D5A19D86A38A6FB03ED2A592/',
  },
  {
    ttsID: 14000,
    name: 'Crystallize',
    flags: [],
    set: 'Crystal Forge',
    FaceURL:
      'http://cloud-3.steamusercontent.com/ugc/768365068754736762/F0BCF9AC1E2590E13B590F0FE44F6BB61652BAAD/',
    BackURL:
      'http://cloud-3.steamusercontent.com/ugc/768365068750455723/A2E8BBF482ED5B11D5A19D86A38A6FB03ED2A592/',
  },
  {
    ttsID: 14100,
    name: 'Shard Lighthouse',
    flags: [],
    set: 'Crystal Forge',
    FaceURL:
      'http://cloud-3.steamusercontent.com/ugc/768365068754738722/CD905B437E9EDDFE522B9489DA73FE12F1B79E24/',
    BackURL:
      'http://cloud-3.steamusercontent.com/ugc/768365068750455723/A2E8BBF482ED5B11D5A19D86A38A6FB03ED2A592/',
  },
  {
    ttsID: 14200,
    name: "Crystallus' Command",
    flags: [],
    set: 'Crystal Forge',
    FaceURL:
      'http://cloud-3.steamusercontent.com/ugc/768365068754740448/5CF84C0BCC182683978381687BEF8033227B72FA/',
    BackURL:
      'http://cloud-3.steamusercontent.com/ugc/768365068750455723/A2E8BBF482ED5B11D5A19D86A38A6FB03ED2A592/',
  },
  {
    ttsID: 14300,
    name: 'Glacial Chasm',
    flags: [],
    set: 'Crystal Forge',
    FaceURL:
      'http://cloud-3.steamusercontent.com/ugc/768365068754742715/DAF0CF166B61035EA042649FEE66112E49DF4A7C/',
    BackURL:
      'http://cloud-3.steamusercontent.com/ugc/768365068750455723/A2E8BBF482ED5B11D5A19D86A38A6FB03ED2A592/',
  },
  {
    ttsID: 14400,
    name: 'Frostfront Crysade',
    flags: [],
    set: 'Crystal Forge',
    FaceURL:
      'http://cloud-3.steamusercontent.com/ugc/768365068754743927/C8D89728F2439D72C123AAA49BEFAA237E93B82D/',
    BackURL:
      'http://cloud-3.steamusercontent.com/ugc/768365068750455723/A2E8BBF482ED5B11D5A19D86A38A6FB03ED2A592/',
  },
  {
    ttsID: 14500,
    name: 'Drifters Dueling',
    flags: [],
    set: 'Crystal Forge',
    FaceURL:
      'http://cloud-3.steamusercontent.com/ugc/768365068754745921/4F20E638F9047F8F4986085E4E668A15FE6AD8C1/',
    BackURL:
      'http://cloud-3.steamusercontent.com/ugc/768365068750455723/A2E8BBF482ED5B11D5A19D86A38A6FB03ED2A592/',
  },
  {
    ttsID: 14600,
    name: 'Cryocrest Crags',
    flags: [],
    set: 'Crystal Forge',
    FaceURL:
      'http://cloud-3.steamusercontent.com/ugc/768365068754747737/FCAB7B10966735B5C77318BB8AC430D87D6C3B52/',
    BackURL:
      'http://cloud-3.steamusercontent.com/ugc/768365068750455723/A2E8BBF482ED5B11D5A19D86A38A6FB03ED2A592/',
  },
  {
    ttsID: 14700,
    name: 'Crystal Corridor',
    flags: [],
    set: 'Crystal Forge',
    FaceURL:
      'http://cloud-3.steamusercontent.com/ugc/768365068754749716/5EB70752221783A35F399DF05F6893CABFF4864D/',
    BackURL:
      'http://cloud-3.steamusercontent.com/ugc/768365068750455723/A2E8BBF482ED5B11D5A19D86A38A6FB03ED2A592/',
  },
  {
    ttsID: 14800,
    name: 'Frostbitten Portal Cavern',
    flags: [],
    set: 'Crystal Forge',
    FaceURL:
      'http://cloud-3.steamusercontent.com/ugc/768365068754751144/D29E3A534092EF0D0FFDD9D8321C5596C4E87ADE/',
    BackURL:
      'http://cloud-3.steamusercontent.com/ugc/768365068750455723/A2E8BBF482ED5B11D5A19D86A38A6FB03ED2A592/',
  },
  {
    ttsID: 8200,
    name: 'Blossom Avail',
    flags: [],
    set: 'Galaxy Tides',
    FaceURL:
      'http://cloud-3.steamusercontent.com/ugc/768365068754539728/22D9560611418F6BBFA25CE4A213889BA3E34F5F/',
    BackURL:
      'http://cloud-3.steamusercontent.com/ugc/768365068750455723/A2E8BBF482ED5B11D5A19D86A38A6FB03ED2A592/',
  },
  {
    ttsID: 8900,
    name: 'Forest of Crags',
    flags: [],
    set: 'Galaxy Tides',
    FaceURL:
      'http://cloud-3.steamusercontent.com/ugc/768365068754550643/D4D8C216B70CEC6E22C1CDC29D65434D606EC05F/',
    BackURL:
      'http://cloud-3.steamusercontent.com/ugc/768365068750455723/A2E8BBF482ED5B11D5A19D86A38A6FB03ED2A592/',
  },
  {
    ttsID: 9000,
    name: 'Everdrift Islands',
    flags: [],
    set: 'Galaxy Tides',
    FaceURL:
      'http://cloud-3.steamusercontent.com/ugc/768365068754552602/9ECC7048373057C3321AA4D0766360EB0B046768/',
    BackURL:
      'http://cloud-3.steamusercontent.com/ugc/768365068750455723/A2E8BBF482ED5B11D5A19D86A38A6FB03ED2A592/',
  },
  {
    ttsID: 9100,
    name: 'Appariton Grove',
    flags: [],
    set: 'Galaxy Tides',
    FaceURL:
      'http://cloud-3.steamusercontent.com/ugc/768365068754553806/E3447ECB150C50AB2E88609D8207279F23C2F615/',
    BackURL:
      'http://cloud-3.steamusercontent.com/ugc/768365068750455723/A2E8BBF482ED5B11D5A19D86A38A6FB03ED2A592/',
  },
  {
    ttsID: 9200,
    name: 'Crystal Bell',
    flags: [],
    set: 'Galaxy Tides',
    FaceURL:
      'http://cloud-3.steamusercontent.com/ugc/768365068754555146/7799D18539234CABCE3536F3F8D7506DE8348F21/',
    BackURL:
      'http://cloud-3.steamusercontent.com/ugc/768365068750455723/A2E8BBF482ED5B11D5A19D86A38A6FB03ED2A592/',
  },
  {
    ttsID: 9500,
    name: 'Shores of the Starry Blockade',
    flags: [],
    set: 'Galaxy Tides',
    FaceURL:
      'http://cloud-3.steamusercontent.com/ugc/768365068754558738/58A24E1415A49EA82BA615283B3B389EB7A4DD6E/',
    BackURL:
      'http://cloud-3.steamusercontent.com/ugc/768365068750455723/A2E8BBF482ED5B11D5A19D86A38A6FB03ED2A592/',
  },
  {
    ttsID: 9600,
    name: 'Maelstrom',
    flags: [],
    set: 'Galaxy Tides',
    FaceURL:
      'http://cloud-3.steamusercontent.com/ugc/768365068754561019/F19383C66FCF282AB33A21D85D1215AC6EF4D8BC/',
    BackURL:
      'http://cloud-3.steamusercontent.com/ugc/768365068750455723/A2E8BBF482ED5B11D5A19D86A38A6FB03ED2A592/',
  },
  {
    ttsID: 9700,
    name: 'Alana the Star Formation',
    flags: [],
    set: 'Galaxy Tides',
    FaceURL:
      'http://cloud-3.steamusercontent.com/ugc/768365068754562218/A5AC5F38FBA92D9DB66C48BE0843CFBB8C55761E/',
    BackURL:
      'http://cloud-3.steamusercontent.com/ugc/768365068750455723/A2E8BBF482ED5B11D5A19D86A38A6FB03ED2A592/',
  },
  {
    ttsID: 7200,
    name: 'Emberia, Bringer of Infernos',
    flags: [],
    set: 'Galaxy Tides',
    FaceURL:
      'http://cloud-3.steamusercontent.com/ugc/768365068754511683/D856DA42887FF6514569F9742783001E65A6DBB1/',
    BackURL:
      'http://cloud-3.steamusercontent.com/ugc/768365068750455723/A2E8BBF482ED5B11D5A19D86A38A6FB03ED2A592/',
  },
  {
    ttsID: 7300,
    name: 'Crystal Command Andromeda',
    flags: [],
    set: 'Galaxy Tides',
    FaceURL:
      'http://cloud-3.steamusercontent.com/ugc/768365068754513476/ACEC3A989C20D5D24F29289BDD1820F62DD319CD/',
    BackURL:
      'http://cloud-3.steamusercontent.com/ugc/768365068750455723/A2E8BBF482ED5B11D5A19D86A38A6FB03ED2A592/',
  },
  {
    ttsID: 7400,
    name: 'Dimensional Desperado',
    flags: [],
    set: 'Galaxy Tides',
    FaceURL:
      'http://cloud-3.steamusercontent.com/ugc/768365068754514872/6F99A69FF1BB3207C388117E6E0C231AB4AA9A67/',
    BackURL:
      'http://cloud-3.steamusercontent.com/ugc/768365068750455723/A2E8BBF482ED5B11D5A19D86A38A6FB03ED2A592/',
  },
  {
    ttsID: 7500,
    name: 'Cosmic Shield Archangel',
    flags: [],
    set: 'Galaxy Tides',
    FaceURL:
      'http://cloud-3.steamusercontent.com/ugc/768365068754516771/D1EF3618D90A1C6CD0033404A8A4AA10F9FB7C29/',
    BackURL:
      'http://cloud-3.steamusercontent.com/ugc/768365068750455723/A2E8BBF482ED5B11D5A19D86A38A6FB03ED2A592/',
  },
  {
    ttsID: 7600,
    name: 'Council of Galaxies',
    flags: [],
    set: 'Galaxy Tides',
    FaceURL:
      'http://cloud-3.steamusercontent.com/ugc/768365068754517966/67C2A7B903D5EF0169997AE115744DBD101D430F/',
    BackURL:
      'http://cloud-3.steamusercontent.com/ugc/768365068750455723/A2E8BBF482ED5B11D5A19D86A38A6FB03ED2A592/',
  },
  {
    ttsID: 7700,
    name: 'Endelmore, Bringer of Victory',
    flags: [],
    set: 'Galaxy Tides',
    FaceURL:
      'http://cloud-3.steamusercontent.com/ugc/768365068754520370/1680080230069D65E6EF70E1DA0395AA0BAA8B5F/',
    BackURL:
      'http://cloud-3.steamusercontent.com/ugc/768365068750455723/A2E8BBF482ED5B11D5A19D86A38A6FB03ED2A592/',
  },
  {
    ttsID: 7800,
    name: 'Victory, Keeper of the Order',
    flags: [],
    set: 'Galaxy Tides',
    FaceURL:
      'http://cloud-3.steamusercontent.com/ugc/768365068754527981/F651B219D1D5CD4A53ACAE3E721EB0312B9CB877/',
    BackURL:
      'http://cloud-3.steamusercontent.com/ugc/768365068750455723/A2E8BBF482ED5B11D5A19D86A38A6FB03ED2A592/',
  },
  {
    ttsID: 7900,
    name: 'Legendary Blade Luhzarus',
    flags: [],
    set: 'Galaxy Tides',
    FaceURL:
      'http://cloud-3.steamusercontent.com/ugc/768365068754529765/C59E599C96F02C4CAFD8E7D160996CAD6966A397/',
    BackURL:
      'http://cloud-3.steamusercontent.com/ugc/768365068750455723/A2E8BBF482ED5B11D5A19D86A38A6FB03ED2A592/',
  },
  {
    ttsID: 8000,
    name: 'Dream Blossom Dragon',
    flags: [],
    set: 'Galaxy Tides',
    FaceURL:
      'http://cloud-3.steamusercontent.com/ugc/768365068754536347/779DC72177B2D5FF89ED5647B55508AAE6D9DA4E/',
    BackURL:
      'http://cloud-3.steamusercontent.com/ugc/768365068750455723/A2E8BBF482ED5B11D5A19D86A38A6FB03ED2A592/',
  },
  {
    ttsID: 8100,
    name: 'Drangel',
    flags: [],
    set: 'Galaxy Tides',
    FaceURL:
      'http://cloud-3.steamusercontent.com/ugc/768365068754537570/CD662017959519AA45A30050953771806347130A/',
    BackURL:
      'http://cloud-3.steamusercontent.com/ugc/768365068750455723/A2E8BBF482ED5B11D5A19D86A38A6FB03ED2A592/',
  },
  {
    ttsID: 8300,
    name: 'Ethereal Aurorialis',
    flags: [],
    set: 'Galaxy Tides',
    FaceURL:
      'http://cloud-3.steamusercontent.com/ugc/768365068754540882/E258A77DEC014DAEC586A7CBF11706677F343853/',
    BackURL:
      'http://cloud-3.steamusercontent.com/ugc/768365068750455723/A2E8BBF482ED5B11D5A19D86A38A6FB03ED2A592/',
  },
  {
    ttsID: 8400,
    name: 'Bridge Bastion Dragon',
    flags: [],
    set: 'Galaxy Tides',
    FaceURL:
      'http://cloud-3.steamusercontent.com/ugc/768365068754542551/98EC411761C688A1D22F9636926348B91CA2608A/',
    BackURL:
      'http://cloud-3.steamusercontent.com/ugc/768365068750455723/A2E8BBF482ED5B11D5A19D86A38A6FB03ED2A592/',
  },
  {
    ttsID: 8500,
    name: 'Dimensional Dragon',
    flags: [],
    set: 'Galaxy Tides',
    FaceURL:
      'http://cloud-3.steamusercontent.com/ugc/768365068754543828/41AF67B4F7627C341D0561C3EABE279149AD88EC/',
    BackURL:
      'http://cloud-3.steamusercontent.com/ugc/768365068750455723/A2E8BBF482ED5B11D5A19D86A38A6FB03ED2A592/',
  },
  {
    ttsID: 8600,
    name: 'Starbound Glacier',
    flags: [],
    set: 'Galaxy Tides',
    FaceURL:
      'http://cloud-3.steamusercontent.com/ugc/768365068754545766/F388ACEF47FFD0B95009C21B366335849C83049C/',
    BackURL:
      'http://cloud-3.steamusercontent.com/ugc/768365068750455723/A2E8BBF482ED5B11D5A19D86A38A6FB03ED2A592/',
  },
  {
    ttsID: 8700,
    name: 'Lantern Leaflore',
    flags: [],
    set: 'Galaxy Tides',
    FaceURL:
      'http://cloud-3.steamusercontent.com/ugc/768365068754547521/0AEEA0ABCC6C7F6A69DB57A74CAA7969641D92DA/',
    BackURL:
      'http://cloud-3.steamusercontent.com/ugc/768365068750455723/A2E8BBF482ED5B11D5A19D86A38A6FB03ED2A592/',
  },
  {
    ttsID: 8800,
    name: 'Battleship Philistia',
    flags: [],
    set: 'Galaxy Tides',
    FaceURL:
      'http://cloud-3.steamusercontent.com/ugc/768365068754549109/B65DE003F73FA52D9136569E2932A65C978B334E/',
    BackURL:
      'http://cloud-3.steamusercontent.com/ugc/768365068750455723/A2E8BBF482ED5B11D5A19D86A38A6FB03ED2A592/',
  },
  {
    ttsID: 11300,
    name: 'Beacon of Worlds',
    flags: [],
    set: 'Galaxy Tides',
    FaceURL:
      'http://cloud-3.steamusercontent.com/ugc/768365068754591604/CE5916A2BE5A22AD83C88147AB3CB5127EBDE131/',
    BackURL:
      'http://cloud-3.steamusercontent.com/ugc/768365068750455723/A2E8BBF482ED5B11D5A19D86A38A6FB03ED2A592/',
  },
  {
    ttsID: 11400,
    name: 'Dimensional Dust',
    flags: [],
    set: 'Galaxy Tides',
    FaceURL:
      'http://cloud-3.steamusercontent.com/ugc/768365068754593187/6F4CE6D60D4E6FE9256F59C366BE1215FB7436EF/',
    BackURL:
      'http://cloud-3.steamusercontent.com/ugc/768365068750455723/A2E8BBF482ED5B11D5A19D86A38A6FB03ED2A592/',
  },
  {
    ttsID: 11500,
    name: 'Star Struggle',
    flags: [],
    set: 'Galaxy Tides',
    FaceURL:
      'http://cloud-3.steamusercontent.com/ugc/768365068754594603/9E30A704F176DF78EBBFB8220106B707BB5CA750/',
    BackURL:
      'http://cloud-3.steamusercontent.com/ugc/768365068750455723/A2E8BBF482ED5B11D5A19D86A38A6FB03ED2A592/',
  },
  {
    ttsID: 11600,
    name: 'Starbluff Summit',
    flags: [],
    set: 'Galaxy Tides',
    FaceURL:
      'http://cloud-3.steamusercontent.com/ugc/768365068754596386/575C19CE95583F2F49018EE40622BD144AB1753D/',
    BackURL:
      'http://cloud-3.steamusercontent.com/ugc/768365068750455723/A2E8BBF482ED5B11D5A19D86A38A6FB03ED2A592/',
  },
  {
    ttsID: 11700,
    name: 'Isles of the Morning Star',
    flags: [],
    set: 'Galaxy Tides',
    FaceURL:
      'http://cloud-3.steamusercontent.com/ugc/768365068754610868/ECDD03F5D581F3101892DE3F2876548C393BC85A/',
    BackURL:
      'http://cloud-3.steamusercontent.com/ugc/768365068750455723/A2E8BBF482ED5B11D5A19D86A38A6FB03ED2A592/',
  },
  {
    ttsID: 11800,
    name: 'Interstellar Tides',
    flags: [],
    set: 'Galaxy Tides',
    FaceURL:
      'http://cloud-3.steamusercontent.com/ugc/768365068754613575/12A7CBB5ABAD527E7391A7251D7B2A54D31A9749/',
    BackURL:
      'http://cloud-3.steamusercontent.com/ugc/768365068750455723/A2E8BBF482ED5B11D5A19D86A38A6FB03ED2A592/',
  },
  {
    ttsID: 11900,
    name: 'Vendetta Plateau',
    flags: [],
    set: 'Galaxy Tides',
    FaceURL:
      'http://cloud-3.steamusercontent.com/ugc/768365068754614868/86742B6F889ED0959D1714238A985611EA43DFE5/',
    BackURL:
      'http://cloud-3.steamusercontent.com/ugc/768365068750455723/A2E8BBF482ED5B11D5A19D86A38A6FB03ED2A592/',
  },
  {
    ttsID: 12000,
    name: 'Royal Patriarch',
    flags: [],
    set: 'Galaxy Tides',
    FaceURL:
      'http://cloud-3.steamusercontent.com/ugc/768365068754616524/C3EE480793F871A5B872D240A44F41E3637F1DDD/',
    BackURL:
      'http://cloud-3.steamusercontent.com/ugc/768365068750455723/A2E8BBF482ED5B11D5A19D86A38A6FB03ED2A592/',
  },
  {
    ttsID: 12100,
    name: 'Heavenly Blade Hera',
    flags: [],
    set: 'Galaxy Tides',
    FaceURL:
      'http://cloud-3.steamusercontent.com/ugc/768365068754617972/821F6B9260E5E232C29818D26A3E5A18022C380D/',
    BackURL:
      'http://cloud-3.steamusercontent.com/ugc/768365068750455723/A2E8BBF482ED5B11D5A19D86A38A6FB03ED2A592/',
  },
  {
    ttsID: 12200,
    name: 'Symphony Songsmith',
    flags: [],
    set: 'Galaxy Tides',
    FaceURL:
      'http://cloud-3.steamusercontent.com/ugc/768365068754620391/5E918D7700F4FD4C208D47C4C4BE2E815FAA0A4B/',
    BackURL:
      'http://cloud-3.steamusercontent.com/ugc/768365068750455723/A2E8BBF482ED5B11D5A19D86A38A6FB03ED2A592/',
  },
  {
    ttsID: 12300,
    name: 'Concerto Virtuoso',
    flags: [],
    set: 'Galaxy Tides',
    FaceURL:
      'http://cloud-3.steamusercontent.com/ugc/768365068754621390/0B2D2D46C8EE85206A1C8B33844F730605B2A995/',
    BackURL:
      'http://cloud-3.steamusercontent.com/ugc/768365068750455723/A2E8BBF482ED5B11D5A19D86A38A6FB03ED2A592/',
  },
  {
    ttsID: 12400,
    name: 'Aria, Wielder of Shards',
    flags: [],
    set: 'Galaxy Tides',
    FaceURL:
      'http://cloud-3.steamusercontent.com/ugc/768365068754623618/88F0190065EE27471D961644C8BC44E537E9B6DE/',
    BackURL:
      'http://cloud-3.steamusercontent.com/ugc/768365068750455723/A2E8BBF482ED5B11D5A19D86A38A6FB03ED2A592/',
  },
  {
    ttsID: 12500,
    name: 'Wishwaker Willow',
    flags: [],
    set: 'Galaxy Tides',
    FaceURL:
      'http://cloud-3.steamusercontent.com/ugc/768365068754624591/29ECA5EFF00541E985409573F84666BC1C7C25B1/',
    BackURL:
      'http://cloud-3.steamusercontent.com/ugc/768365068750455723/A2E8BBF482ED5B11D5A19D86A38A6FB03ED2A592/',
  },
  {
    ttsID: 12600,
    name: 'Stellar Elliptical Dragon',
    flags: [],
    set: 'Galaxy Tides',
    FaceURL:
      'http://cloud-3.steamusercontent.com/ugc/768365068754626257/AB4DB7D7B77398F6CD180421B6D616B76367EBDA/',
    BackURL:
      'http://cloud-3.steamusercontent.com/ugc/768365068750455723/A2E8BBF482ED5B11D5A19D86A38A6FB03ED2A592/',
  },
  {
    ttsID: 12700,
    name: 'Heavenly Host',
    flags: [],
    set: 'Galaxy Tides',
    FaceURL:
      'http://cloud-3.steamusercontent.com/ugc/768365068754628684/D88F0AC1F5E60AFA649A20A3DD2A5CB4F97B5ED0/',
    BackURL:
      'http://cloud-3.steamusercontent.com/ugc/768365068750455723/A2E8BBF482ED5B11D5A19D86A38A6FB03ED2A592/',
  },
  {
    ttsID: 5200,
    name: 'Bellflower Dragon',
    flags: [],
    set: 'Galaxy Tides',
    FaceURL:
      'http://cloud-3.steamusercontent.com/ugc/768365068754478644/996901E100A3EB3D9B5171F06114CFB1A8D240E6/',
    BackURL:
      'http://cloud-3.steamusercontent.com/ugc/768365068750455723/A2E8BBF482ED5B11D5A19D86A38A6FB03ED2A592/',
  },
  {
    ttsID: 5300,
    name: 'Calligraphy Inkveil',
    flags: [],
    set: 'Galaxy Tides',
    FaceURL:
      'http://cloud-3.steamusercontent.com/ugc/768365068754480544/A2DF4DEED18ECD3B9CE4F9C2115C4976CB164C0E/',
    BackURL:
      'http://cloud-3.steamusercontent.com/ugc/768365068750455723/A2E8BBF482ED5B11D5A19D86A38A6FB03ED2A592/',
  },
  {
    ttsID: 5400,
    name: 'Moltencrag Slithe',
    flags: [],
    set: 'Galaxy Tides',
    FaceURL:
      'http://cloud-3.steamusercontent.com/ugc/768365068754481364/BC824EB8018914EA935A501C5DB78C22D71D3854/',
    BackURL:
      'http://cloud-3.steamusercontent.com/ugc/768365068750455723/A2E8BBF482ED5B11D5A19D86A38A6FB03ED2A592/',
  },
  {
    ttsID: 5500,
    name: 'Thundercoil Dragon',
    flags: [],
    set: 'Galaxy Tides',
    FaceURL:
      'http://cloud-3.steamusercontent.com/ugc/768365068754483229/2F7A5381CABADE38F6001DFA81CCCD4C235CBA65/',
    BackURL:
      'http://cloud-3.steamusercontent.com/ugc/768365068750455723/A2E8BBF482ED5B11D5A19D86A38A6FB03ED2A592/',
  },
  {
    ttsID: 5600,
    name: 'Portalback Dragon',
    flags: [],
    set: 'Galaxy Tides',
    FaceURL:
      'http://cloud-3.steamusercontent.com/ugc/768365068754484033/003A14B9F917D400D6E2FDBF6C80746C6665A556/',
    BackURL:
      'http://cloud-3.steamusercontent.com/ugc/768365068750455723/A2E8BBF482ED5B11D5A19D86A38A6FB03ED2A592/',
  },
  {
    ttsID: 5700,
    name: 'Dragon Knight Partisan',
    flags: [],
    set: 'Galaxy Tides',
    FaceURL:
      'http://cloud-3.steamusercontent.com/ugc/768365068754486209/EA23AA63D2BA7FF4C2867081DDC6345527B47F84/',
    BackURL:
      'http://cloud-3.steamusercontent.com/ugc/768365068750455723/A2E8BBF482ED5B11D5A19D86A38A6FB03ED2A592/',
  },
  {
    ttsID: 5800,
    name: 'Starsmoke Dragon',
    flags: [],
    set: 'Galaxy Tides',
    FaceURL:
      'http://cloud-3.steamusercontent.com/ugc/768365068754487878/DF1E01F536B11EA0E92C5995F4E6DEB97203F641/',
    BackURL:
      'http://cloud-3.steamusercontent.com/ugc/768365068750455723/A2E8BBF482ED5B11D5A19D86A38A6FB03ED2A592/',
  },
  {
    ttsID: 5900,
    name: 'Nebula Mech Starvoid',
    flags: [],
    set: 'Galaxy Tides',
    FaceURL:
      'http://cloud-3.steamusercontent.com/ugc/768365068754489389/8A1349DCCB4433E66A3F4A18CD1295C31DCF13D0/',
    BackURL:
      'http://cloud-3.steamusercontent.com/ugc/768365068750455723/A2E8BBF482ED5B11D5A19D86A38A6FB03ED2A592/',
  },
  {
    ttsID: 6000,
    name: 'Planetside Remnant',
    flags: [],
    set: 'Galaxy Tides',
    FaceURL:
      'http://cloud-3.steamusercontent.com/ugc/768365068754490343/971AAE444267013598AC76A5CFC765B03669F248/',
    BackURL:
      'http://cloud-3.steamusercontent.com/ugc/768365068750455723/A2E8BBF482ED5B11D5A19D86A38A6FB03ED2A592/',
  },
  {
    ttsID: 6100,
    name: 'Galaxy Gazer',
    flags: [],
    set: 'Galaxy Tides',
    FaceURL:
      'http://cloud-3.steamusercontent.com/ugc/768365068754491670/4DDEC5DC3152EECE11BDADEC6982B4F3D1DE9A9B/',
    BackURL:
      'http://cloud-3.steamusercontent.com/ugc/768365068750455723/A2E8BBF482ED5B11D5A19D86A38A6FB03ED2A592/',
  },
  {
    ttsID: 6200,
    name: 'Granciel, Emissary of Stars',
    flags: [],
    set: 'Galaxy Tides',
    FaceURL:
      'http://cloud-3.steamusercontent.com/ugc/768365068754492465/F89E5D577E19EAA54C50024DF411E498F8FAAE48/',
    BackURL:
      'http://cloud-3.steamusercontent.com/ugc/768365068750455723/A2E8BBF482ED5B11D5A19D86A38A6FB03ED2A592/',
  },
  {
    ttsID: 6300,
    name: 'Guardian Angel Galaxia',
    flags: [],
    set: 'Galaxy Tides',
    FaceURL:
      'http://cloud-3.steamusercontent.com/ugc/768365068754493843/67A09D33173B99EA32BF65B3DAB58AF55B096912/',
    BackURL:
      'http://cloud-3.steamusercontent.com/ugc/768365068750455723/A2E8BBF482ED5B11D5A19D86A38A6FB03ED2A592/',
  },
  {
    ttsID: 6400,
    name: 'Princess of Blades',
    flags: [],
    set: 'Galaxy Tides',
    FaceURL:
      'http://cloud-3.steamusercontent.com/ugc/768365068754494802/55519E840A54FDD7407F7781C5D7BB2475DADC5B/',
    BackURL:
      'http://cloud-3.steamusercontent.com/ugc/768365068750455723/A2E8BBF482ED5B11D5A19D86A38A6FB03ED2A592/',
  },
  {
    ttsID: 6500,
    name: 'Dawndust Paladin',
    flags: [],
    set: 'Galaxy Tides',
    FaceURL:
      'http://cloud-3.steamusercontent.com/ugc/768365068754496264/7DA331E29474AC34BAEF0F4B874FF370F460B300/',
    BackURL:
      'http://cloud-3.steamusercontent.com/ugc/768365068750455723/A2E8BBF482ED5B11D5A19D86A38A6FB03ED2A592/',
  },
  {
    ttsID: 6600,
    name: 'Vinebranch Seraph',
    flags: [],
    set: 'Galaxy Tides',
    FaceURL:
      'http://cloud-3.steamusercontent.com/ugc/768365068754501018/C568DE7127FE3C657C37268F0534F6067C2AB1D2/',
    BackURL:
      'http://cloud-3.steamusercontent.com/ugc/768365068750455723/A2E8BBF482ED5B11D5A19D86A38A6FB03ED2A592/',
  },
  {
    ttsID: 6700,
    name: 'Lystari, Constellation Chaser',
    flags: [],
    set: 'Galaxy Tides',
    FaceURL:
      'http://cloud-3.steamusercontent.com/ugc/768365068754504273/C2942907A83863FD0DD7D18889387A382F2B3E36/',
    BackURL:
      'http://cloud-3.steamusercontent.com/ugc/768365068750455723/A2E8BBF482ED5B11D5A19D86A38A6FB03ED2A592/',
  },
  {
    ttsID: 6800,
    name: 'Starspear Oak',
    flags: [],
    set: 'Galaxy Tides',
    FaceURL:
      'http://cloud-3.steamusercontent.com/ugc/768365068754505789/6C7EEBF3B7E146EE9CF9A56253B9225CA35BC11A/',
    BackURL:
      'http://cloud-3.steamusercontent.com/ugc/768365068750455723/A2E8BBF482ED5B11D5A19D86A38A6FB03ED2A592/',
  },
  {
    ttsID: 6900,
    name: "Euphoria's Reach",
    flags: [],
    set: 'Galaxy Tides',
    FaceURL:
      'http://cloud-3.steamusercontent.com/ugc/768365068754507294/2705BB3CA7CBCD61E29DB246BFA8746820B9DEE4/',
    BackURL:
      'http://cloud-3.steamusercontent.com/ugc/768365068750455723/A2E8BBF482ED5B11D5A19D86A38A6FB03ED2A592/',
  },
  {
    ttsID: 7000,
    name: 'Crystaldrift Slithe',
    flags: [],
    set: 'Galaxy Tides',
    FaceURL:
      'http://cloud-3.steamusercontent.com/ugc/768365068754508455/86F11785951401FB8EB52D2805805FF89B05DCE5/',
    BackURL:
      'http://cloud-3.steamusercontent.com/ugc/768365068750455723/A2E8BBF482ED5B11D5A19D86A38A6FB03ED2A592/',
  },
  {
    ttsID: 7100,
    name: 'Nidalap, Frozen Spear',
    flags: [],
    set: 'Galaxy Tides',
    FaceURL:
      'http://cloud-3.steamusercontent.com/ugc/768365068754510541/E90F1DA6D9C08602CC03BED028D35AB23A1FA143/',
    BackURL:
      'http://cloud-3.steamusercontent.com/ugc/768365068750455723/A2E8BBF482ED5B11D5A19D86A38A6FB03ED2A592/',
  },
  {
    ttsID: 9300,
    name: 'Efil, the Everlasting Empress',
    flags: ['SR'],
    set: 'Galaxy Tides',
    FaceURL:
      'http://cloud-3.steamusercontent.com/ugc/768365068754556264/4879D7072B6B2E9B480B55CA968CEAE5646E909E/',
    BackURL:
      'http://cloud-3.steamusercontent.com/ugc/768365068750455723/A2E8BBF482ED5B11D5A19D86A38A6FB03ED2A592/',
  },
  {
    ttsID: 9400,
    name: 'Alana, the Star Formation',
    flags: ['SR'],
    set: 'Galaxy Tides',
    FaceURL:
      'http://cloud-3.steamusercontent.com/ugc/768365068754557864/208503029D9325F2DE76504CE64D680EBAA15A51/',
    BackURL:
      'http://cloud-3.steamusercontent.com/ugc/768365068750455723/A2E8BBF482ED5B11D5A19D86A38A6FB03ED2A592/',
  },
  {
    ttsID: 2600,
    name: 'Blessing',
    flags: [],
    set: 'Birth of Creation',
    FaceURL:
      'http://cloud-3.steamusercontent.com/ugc/768365068750895193/B942CDAA50BE1921F2627F483B12F3B1BA7E4E71/',
    BackURL:
      'http://cloud-3.steamusercontent.com/ugc/768365068750455723/A2E8BBF482ED5B11D5A19D86A38A6FB03ED2A592/',
  },
  {
    ttsID: 2800,
    name: 'Chains',
    flags: [],
    set: 'Birth of Creation',
    FaceURL:
      'http://cloud-3.steamusercontent.com/ugc/768365068750898534/4B5E936002AA2BC85AA83E673E00065A0B8EAB47/',
    BackURL:
      'http://cloud-3.steamusercontent.com/ugc/768365068750455723/A2E8BBF482ED5B11D5A19D86A38A6FB03ED2A592/',
  },
  {
    ttsID: 3200,
    name: 'Endowment',
    flags: [],
    set: 'Birth of Creation',
    FaceURL:
      'http://cloud-3.steamusercontent.com/ugc/768365068750910094/D724D70431D8AE510C36FB47D3B01B2EFB4567B5/',
    BackURL:
      'http://cloud-3.steamusercontent.com/ugc/768365068750455723/A2E8BBF482ED5B11D5A19D86A38A6FB03ED2A592/',
  },
  {
    ttsID: 3700,
    name: 'Invoke',
    flags: [],
    set: 'Birth of Creation',
    FaceURL:
      'http://cloud-3.steamusercontent.com/ugc/768365068750917741/7CB47451651A53D82F60227C19AB55AAECBBBCAB/',
    BackURL:
      'http://cloud-3.steamusercontent.com/ugc/768365068750455723/A2E8BBF482ED5B11D5A19D86A38A6FB03ED2A592/',
  },
  {
    ttsID: 3800,
    name: 'Leech',
    flags: [],
    set: 'Birth of Creation',
    FaceURL:
      'http://cloud-3.steamusercontent.com/ugc/768365068750919298/C7839F74F87F3C48BD649C9024E5BF02036C1410/',
    BackURL:
      'http://cloud-3.steamusercontent.com/ugc/768365068750455723/A2E8BBF482ED5B11D5A19D86A38A6FB03ED2A592/',
  },
  {
    ttsID: 4100,
    name: 'Reevaluate',
    flags: [],
    set: 'Birth of Creation',
    FaceURL:
      'http://cloud-3.steamusercontent.com/ugc/768365068750923616/4434791ED4AE84D5406B78F1BE3B80B5C1E70FBE/',
    BackURL:
      'http://cloud-3.steamusercontent.com/ugc/768365068750455723/A2E8BBF482ED5B11D5A19D86A38A6FB03ED2A592/',
  },
  {
    ttsID: 4200,
    name: 'Unsummon',
    flags: [],
    set: 'Birth of Creation',
    FaceURL:
      'http://cloud-3.steamusercontent.com/ugc/768365068750925528/4F681553D45342254FFAB1CA15E5AEB7616E0E3A/',
    BackURL:
      'http://cloud-3.steamusercontent.com/ugc/768365068750455723/A2E8BBF482ED5B11D5A19D86A38A6FB03ED2A592/',
  },
  {
    ttsID: 600,
    name: 'Cataclysm',
    flags: [],
    set: 'Birth of Creation',
    FaceURL:
      'http://cloud-3.steamusercontent.com/ugc/768365068750838908/99281A64AA9B33A4E92ACD31D96A8E07B82F7BF1/',
    BackURL:
      'http://cloud-3.steamusercontent.com/ugc/768365068750455723/A2E8BBF482ED5B11D5A19D86A38A6FB03ED2A592/',
  },
  {
    ttsID: 700,
    name: 'Chains',
    flags: [],
    set: 'Birth of Creation',
    FaceURL:
      'http://cloud-3.steamusercontent.com/ugc/768365068750840839/DB84F4D0A7A5721308767A130463141E736809F7/',
    BackURL:
      'http://cloud-3.steamusercontent.com/ugc/768365068750455723/A2E8BBF482ED5B11D5A19D86A38A6FB03ED2A592/',
  },
  {
    ttsID: 1200,
    name: 'Energize',
    flags: [],
    set: 'Birth of Creation',
    FaceURL:
      'http://cloud-3.steamusercontent.com/ugc/768365068750850588/0F81D4DB2BA9ADEC5DCBB1C8DA2C2668EA6C672F/',
    BackURL:
      'http://cloud-3.steamusercontent.com/ugc/768365068750455723/A2E8BBF482ED5B11D5A19D86A38A6FB03ED2A592/',
  },
  {
    ttsID: 1300,
    name: 'Invoke',
    flags: [],
    set: 'Birth of Creation',
    FaceURL:
      'http://cloud-3.steamusercontent.com/ugc/768365068750852742/0CEAE7A8D4E069D27B3655F8AF29D1F0B246CCD0/',
    BackURL:
      'http://cloud-3.steamusercontent.com/ugc/768365068750455723/A2E8BBF482ED5B11D5A19D86A38A6FB03ED2A592/',
  },
  {
    ttsID: 1400,
    name: 'Leech',
    flags: [],
    set: 'Birth of Creation',
    FaceURL:
      'http://cloud-3.steamusercontent.com/ugc/768365068750854420/3D80F021FA3AC77CBC996B7CF90C27654600A080/',
    BackURL:
      'http://cloud-3.steamusercontent.com/ugc/768365068750455723/A2E8BBF482ED5B11D5A19D86A38A6FB03ED2A592/',
  },
  {
    ttsID: 1700,
    name: 'Reversion',
    flags: [],
    set: 'Birth of Creation',
    FaceURL:
      'http://cloud-3.steamusercontent.com/ugc/768365068750860832/2208378FF78451FD6306C9C5F302FB2F18C95B77/',
    BackURL:
      'http://cloud-3.steamusercontent.com/ugc/768365068750455723/A2E8BBF482ED5B11D5A19D86A38A6FB03ED2A592/',
  },
  {
    ttsID: 2100,
    name: 'Vortex',
    flags: [],
    set: 'Birth of Creation',
    FaceURL:
      'http://cloud-3.steamusercontent.com/ugc/768365068750868798/BB5ED626FD3033B3AA9888C5BEEAE9A93E392FBC/',
    BackURL:
      'http://cloud-3.steamusercontent.com/ugc/768365068750455723/A2E8BBF482ED5B11D5A19D86A38A6FB03ED2A592/',
  },
  {
    ttsID: 2300,
    name: 'Angelic Apparition',
    flags: [],
    set: 'Birth of Creation',
    FaceURL:
      'http://cloud-3.steamusercontent.com/ugc/768365068750890104/357883F937E881B36FA55B237B9A8B8D0896C372/',
    BackURL:
      'http://cloud-3.steamusercontent.com/ugc/768365068750455723/A2E8BBF482ED5B11D5A19D86A38A6FB03ED2A592/',
  },
  {
    ttsID: 2400,
    name: 'Atmospheric Striker',
    flags: [],
    set: 'Birth of Creation',
    FaceURL:
      'http://cloud-3.steamusercontent.com/ugc/768365068750891862/CC0C6054B90F7F33AC74923656B3FF4876B138C3/',
    BackURL:
      'http://cloud-3.steamusercontent.com/ugc/768365068750455723/A2E8BBF482ED5B11D5A19D86A38A6FB03ED2A592/',
  },
  {
    ttsID: 2500,
    name: 'Benevolent Seraphim',
    flags: [],
    set: 'Birth of Creation',
    FaceURL:
      'http://cloud-3.steamusercontent.com/ugc/768365068750893342/D14262D9FF089F0F9EB64428FE594FC99B452AC0/',
    BackURL:
      'http://cloud-3.steamusercontent.com/ugc/768365068750455723/A2E8BBF482ED5B11D5A19D86A38A6FB03ED2A592/',
  },
  {
    ttsID: 2700,
    name: 'Celestial Starcaster',
    flags: [],
    set: 'Birth of Creation',
    FaceURL:
      'http://cloud-3.steamusercontent.com/ugc/768365068750896611/4DE0163798900609DFD391E1C629E47B0CEBE445/',
    BackURL:
      'http://cloud-3.steamusercontent.com/ugc/768365068750455723/A2E8BBF482ED5B11D5A19D86A38A6FB03ED2A592/',
  },
  {
    ttsID: 2900,
    name: 'Dimensional Desperado',
    flags: [],
    set: 'Birth of Creation',
    FaceURL:
      'http://cloud-3.steamusercontent.com/ugc/768365068750899823/5ECD3785C905284AADB51BFA92A21DA23480569C/',
    BackURL:
      'http://cloud-3.steamusercontent.com/ugc/768365068750455723/A2E8BBF482ED5B11D5A19D86A38A6FB03ED2A592/',
  },
  {
    ttsID: 3000,
    name: 'Divine Minister',
    flags: [],
    set: 'Birth of Creation',
    FaceURL:
      'http://cloud-3.steamusercontent.com/ugc/768365068750904227/1F488B89072654C881C614787BE110BC9194F481/',
    BackURL:
      'http://cloud-3.steamusercontent.com/ugc/768365068750455723/A2E8BBF482ED5B11D5A19D86A38A6FB03ED2A592/',
  },
  {
    ttsID: 3100,
    name: 'Doomfest Elderbeast Angel',
    flags: [],
    set: 'Birth of Creation',
    FaceURL:
      'http://cloud-3.steamusercontent.com/ugc/768365068750907848/B20A54778E200B893FED70269DEE19F38E1FC9A2/',
    BackURL:
      'http://cloud-3.steamusercontent.com/ugc/768365068750455723/A2E8BBF482ED5B11D5A19D86A38A6FB03ED2A592/',
  },
  {
    ttsID: 3300,
    name: 'Exalted Skyshifter',
    flags: [],
    set: 'Birth of Creation',
    FaceURL:
      'http://cloud-3.steamusercontent.com/ugc/768365068750911049/83DD1C583ADEEE719B86D3B2F91FA0B69616D135/',
    BackURL:
      'http://cloud-3.steamusercontent.com/ugc/768365068750455723/A2E8BBF482ED5B11D5A19D86A38A6FB03ED2A592/',
  },
  {
    ttsID: 3400,
    name: 'Harmonious Angel, the Forthcoming',
    flags: [],
    set: 'Birth of Creation',
    FaceURL:
      'http://cloud-3.steamusercontent.com/ugc/768365068750913016/16549A6BAEE5F7A7B520A1EFEB217D5D01CC8184/',
    BackURL:
      'http://cloud-3.steamusercontent.com/ugc/768365068750455723/A2E8BBF482ED5B11D5A19D86A38A6FB03ED2A592/',
  },
  {
    ttsID: 3500,
    name: 'Heavenheart War-Angel',
    flags: [],
    set: 'Birth of Creation',
    FaceURL:
      'http://cloud-3.steamusercontent.com/ugc/768365068750914438/ACC29A258C39F652D7213993C9856BEF082EBBDC/',
    BackURL:
      'http://cloud-3.steamusercontent.com/ugc/768365068750455723/A2E8BBF482ED5B11D5A19D86A38A6FB03ED2A592/',
  },
  {
    ttsID: 3600,
    name: 'Heavenly Guardian',
    flags: [],
    set: 'Birth of Creation',
    FaceURL:
      'http://cloud-3.steamusercontent.com/ugc/768365068750916506/186FD5EA46DD961B952C7325EEA39883CEDD81DB/',
    BackURL:
      'http://cloud-3.steamusercontent.com/ugc/768365068750455723/A2E8BBF482ED5B11D5A19D86A38A6FB03ED2A592/',
  },
  {
    ttsID: 3900,
    name: 'Lustrous Lightbringer',
    flags: [],
    set: 'Birth of Creation',
    FaceURL:
      'http://cloud-3.steamusercontent.com/ugc/768365068750920277/5F21CD481471D9AE86358EC55B5F13E3057CB25D/',
    BackURL:
      'http://cloud-3.steamusercontent.com/ugc/768365068750455723/A2E8BBF482ED5B11D5A19D86A38A6FB03ED2A592/',
  },
  {
    ttsID: 4000,
    name: 'Majestic Noziroh',
    flags: [],
    set: 'Birth of Creation',
    FaceURL:
      'http://cloud-3.steamusercontent.com/ugc/768365068750922370/92AD9361E8439850E52FEC01E5DF4A605E44BF45/',
    BackURL:
      'http://cloud-3.steamusercontent.com/ugc/768365068750455723/A2E8BBF482ED5B11D5A19D86A38A6FB03ED2A592/',
  },
  {
    ttsID: 300,
    name: 'Asteroid Dragon',
    flags: [],
    set: 'Birth of Creation',
    FaceURL:
      'http://cloud-3.steamusercontent.com/ugc/768365068750834823/EAAC454D2B9DF1BB81A7423C7F13D589F873AD7B/',
    BackURL:
      'http://cloud-3.steamusercontent.com/ugc/768365068750455723/A2E8BBF482ED5B11D5A19D86A38A6FB03ED2A592/',
  },
  {
    ttsID: 400,
    name: 'Bedragoth',
    flags: [],
    set: 'Birth of Creation',
    FaceURL:
      'http://cloud-3.steamusercontent.com/ugc/768365068750835711/79A708C812C194D59AC245CB0AC3F1505986156B/',
    BackURL:
      'http://cloud-3.steamusercontent.com/ugc/768365068750455723/A2E8BBF482ED5B11D5A19D86A38A6FB03ED2A592/',
  },
  {
    ttsID: 500,
    name: 'Calamity Dragon',
    flags: [],
    set: 'Birth of Creation',
    FaceURL:
      'http://cloud-3.steamusercontent.com/ugc/768365068750837793/D2A6BF1BBCAA2C775A0F10D881BBA301017E4DA3/',
    BackURL:
      'http://cloud-3.steamusercontent.com/ugc/768365068750455723/A2E8BBF482ED5B11D5A19D86A38A6FB03ED2A592/',
  },
  {
    ttsID: 800,
    name: 'Clock Dragon',
    flags: [],
    set: 'Birth of Creation',
    FaceURL:
      'http://cloud-3.steamusercontent.com/ugc/768365068750842171/CF70E18A17A6B5D28E27AA83086AA17D1B543BD5/',
    BackURL:
      'http://cloud-3.steamusercontent.com/ugc/768365068750455723/A2E8BBF482ED5B11D5A19D86A38A6FB03ED2A592/',
  },
  {
    ttsID: 900,
    name: 'Darcrest Dragon',
    flags: [],
    set: 'Birth of Creation',
    FaceURL:
      'http://cloud-3.steamusercontent.com/ugc/768365068750844596/B452BBE04F01346959FE1C85F31038601942F5DA/',
    BackURL:
      'http://cloud-3.steamusercontent.com/ugc/768365068750455723/A2E8BBF482ED5B11D5A19D86A38A6FB03ED2A592/',
  },
  {
    ttsID: 1000,
    name: 'Density Dragon',
    flags: [],
    set: 'Birth of Creation',
    FaceURL:
      'http://cloud-3.steamusercontent.com/ugc/768365068750846632/76BF9BC63402C95617FFF07592A9C9A85B39A8FC/',
    BackURL:
      'http://cloud-3.steamusercontent.com/ugc/768365068750455723/A2E8BBF482ED5B11D5A19D86A38A6FB03ED2A592/',
  },
  {
    ttsID: 1100,
    name: 'Dimensional Dragon',
    flags: [],
    set: 'Birth of Creation',
    FaceURL:
      'http://cloud-3.steamusercontent.com/ugc/768365068750848877/B7D8A1909A20DC5308D019A540B8DA027560A67B/',
    BackURL:
      'http://cloud-3.steamusercontent.com/ugc/768365068750455723/A2E8BBF482ED5B11D5A19D86A38A6FB03ED2A592/',
  },
  {
    ttsID: 1500,
    name: 'Mistrai Dragon',
    flags: [],
    set: 'Birth of Creation',
    FaceURL:
      'http://cloud-3.steamusercontent.com/ugc/768365068750856997/F661A286C21EE4A792A1B4C5697FF8437A407571/',
    BackURL:
      'http://cloud-3.steamusercontent.com/ugc/768365068750455723/A2E8BBF482ED5B11D5A19D86A38A6FB03ED2A592/',
  },
  {
    ttsID: 1600,
    name: 'Oceano Dragon',
    flags: [],
    set: 'Birth of Creation',
    FaceURL:
      'http://cloud-3.steamusercontent.com/ugc/768365068750858410/9AB3A0AECCB15646195A9F74E1A6C156F3EFEED7/',
    BackURL:
      'http://cloud-3.steamusercontent.com/ugc/768365068750455723/A2E8BBF482ED5B11D5A19D86A38A6FB03ED2A592/',
  },
  {
    ttsID: 1800,
    name: 'Rose Slithe',
    flags: [],
    set: 'Birth of Creation',
    FaceURL:
      'http://cloud-3.steamusercontent.com/ugc/768365068750861832/1867F6C1E33F9038C1421817461231351A0011D2/',
    BackURL:
      'http://cloud-3.steamusercontent.com/ugc/768365068750455723/A2E8BBF482ED5B11D5A19D86A38A6FB03ED2A592/',
  },
  {
    ttsID: 1900,
    name: 'Skyward Slithe',
    flags: [],
    set: 'Birth of Creation',
    FaceURL:
      'http://cloud-3.steamusercontent.com/ugc/768365068750864088/6E7BC3D8D5556462E0193753B6A0D5B260BB7162/',
    BackURL:
      'http://cloud-3.steamusercontent.com/ugc/768365068750455723/A2E8BBF482ED5B11D5A19D86A38A6FB03ED2A592/',
  },
  {
    ttsID: 2200,
    name: 'Slither Drake',
    flags: [],
    set: 'Birth of Creation',
    FaceURL:
      'http://cloud-3.steamusercontent.com/ugc/768365068750870874/E7B8DE145B14B115ECC6817EB7DF4B489E529474/',
    BackURL:
      'http://cloud-3.steamusercontent.com/ugc/768365068750455723/A2E8BBF482ED5B11D5A19D86A38A6FB03ED2A592/',
  },
  {
    ttsID: 2000,
    name: 'Valforce',
    flags: [],
    set: 'Birth of Creation',
    FaceURL:
      'http://cloud-3.steamusercontent.com/ugc/768365068750865552/E99C89D25E95843178F3454614C11EE4464AF6C9/',
    BackURL:
      'http://cloud-3.steamusercontent.com/ugc/768365068750455723/A2E8BBF482ED5B11D5A19D86A38A6FB03ED2A592/',
  },
  {
    ttsID: 46700,
    name: 'Manuscript of the Archivist',
    flags: [],
    set: 'Threshold of Time',
    FaceURL:
      'http://cloud-3.steamusercontent.com/ugc/1612841555440941924/FC6489BB55BAF969731AD1C28A3D17E3FBB26045/',
    BackURL:
      'http://cloud-3.steamusercontent.com/ugc/768365068750455723/A2E8BBF482ED5B11D5A19D86A38A6FB03ED2A592/',
  },
  {
    ttsID: 46800,
    name: 'Canyon of Secrets',
    flags: [],
    set: 'Threshold of Time',
    FaceURL:
      'http://cloud-3.steamusercontent.com/ugc/1612841555440952406/562233B569168A620D571E0DCC229C8D954B8161/',
    BackURL:
      'http://cloud-3.steamusercontent.com/ugc/768365068750455723/A2E8BBF482ED5B11D5A19D86A38A6FB03ED2A592/',
  },
  {
    ttsID: 46900,
    name: 'Sands of the Broken Timepiece',
    flags: [],
    set: 'Threshold of Time',
    FaceURL:
      'http://cloud-3.steamusercontent.com/ugc/1612841555440954172/0AF9746E293556C62F66FD0470F029FED011DDCA/',
    BackURL:
      'http://cloud-3.steamusercontent.com/ugc/768365068750455723/A2E8BBF482ED5B11D5A19D86A38A6FB03ED2A592/',
  },
  {
    ttsID: 47000,
    name: 'Hailfire and Brimstone',
    flags: [],
    set: 'Threshold of Time',
    FaceURL:
      'http://cloud-3.steamusercontent.com/ugc/1612841555440956778/9C9F84FFEE6285D473676A172A5A8E1994FF6BEF/',
    BackURL:
      'http://cloud-3.steamusercontent.com/ugc/768365068750455723/A2E8BBF482ED5B11D5A19D86A38A6FB03ED2A592/',
  },
  {
    ttsID: 47100,
    name: 'Atlas Ablaze',
    flags: [],
    set: 'Threshold of Time',
    FaceURL:
      'http://cloud-3.steamusercontent.com/ugc/1612841555440957620/94F2C325183BD584146D97BFB94B47BB603D0F3F/',
    BackURL:
      'http://cloud-3.steamusercontent.com/ugc/768365068750455723/A2E8BBF482ED5B11D5A19D86A38A6FB03ED2A592/',
  },
  {
    ttsID: 47200,
    name: 'Isla De Las Cartas',
    flags: [],
    set: 'Threshold of Time',
    FaceURL:
      'http://cloud-3.steamusercontent.com/ugc/1612841555440959558/8A53343073282A9A779E72896FA9B6367E06E8C1/',
    BackURL:
      'http://cloud-3.steamusercontent.com/ugc/768365068750455723/A2E8BBF482ED5B11D5A19D86A38A6FB03ED2A592/',
  },
  {
    ttsID: 47300,
    name: 'Sails of the Fallen',
    flags: [],
    set: 'Threshold of Time',
    FaceURL:
      'http://cloud-3.steamusercontent.com/ugc/1612841555440960533/1549A8D924F53D292F297FFCDB28CEA9A2473979/',
    BackURL:
      'http://cloud-3.steamusercontent.com/ugc/768365068750455723/A2E8BBF482ED5B11D5A19D86A38A6FB03ED2A592/',
  },
  {
    ttsID: 47400,
    name: 'Lost Cove, Unveiled',
    flags: [],
    set: 'Threshold of Time',
    FaceURL:
      'http://cloud-3.steamusercontent.com/ugc/1612841555440962322/8341F3D4464FF8C07EE7B86F1F8A1CF7C08C008F/',
    BackURL:
      'http://cloud-3.steamusercontent.com/ugc/768365068750455723/A2E8BBF482ED5B11D5A19D86A38A6FB03ED2A592/',
  },
  {
    ttsID: 47500,
    name: 'Tlah, Barricade of the Library',
    flags: [],
    set: 'Threshold of Time',
    FaceURL:
      'http://cloud-3.steamusercontent.com/ugc/1612841555440963261/94B9FEBAC5FA13B9C0A5888E42142F0454AFFC3E/',
    BackURL:
      'http://cloud-3.steamusercontent.com/ugc/768365068750455723/A2E8BBF482ED5B11D5A19D86A38A6FB03ED2A592/',
  },
  {
    ttsID: 47600,
    name: 'Evom, Chimerical Resonance',
    flags: [],
    set: 'Threshold of Time',
    FaceURL:
      'http://cloud-3.steamusercontent.com/ugc/1612841555440965334/80C8A2092ADA90DC758296F4ADD9D12844A2D55D/',
    BackURL:
      'http://cloud-3.steamusercontent.com/ugc/768365068750455723/A2E8BBF482ED5B11D5A19D86A38A6FB03ED2A592/',
  },
  {
    ttsID: 47700,
    name: 'Erocne, Conscript Crusader',
    flags: [],
    set: 'Threshold of Time',
    FaceURL:
      'http://cloud-3.steamusercontent.com/ugc/1612841555440966053/86EBA2A48DA896DAF5F8BC6AEB03870849E60E6C/',
    BackURL:
      'http://cloud-3.steamusercontent.com/ugc/768365068750455723/A2E8BBF482ED5B11D5A19D86A38A6FB03ED2A592/',
  },
  {
    ttsID: 47800,
    name: 'Eruc, Archive’s Aegis',
    flags: [],
    set: 'Threshold of Time',
    FaceURL:
      'http://cloud-3.steamusercontent.com/ugc/1612841555440967508/A95D1B47EB0B94A0E88B42E7AC5583010645AFD0/',
    BackURL:
      'http://cloud-3.steamusercontent.com/ugc/768365068750455723/A2E8BBF482ED5B11D5A19D86A38A6FB03ED2A592/',
  },
  {
    ttsID: 47900,
    name: 'Draoh, Untold Liberator',
    flags: [],
    set: 'Threshold of Time',
    FaceURL:
      'http://cloud-3.steamusercontent.com/ugc/1612841555440968242/409A99FCFD0BDD47A4C43163BEDB5D3CC35BCC39/',
    BackURL:
      'http://cloud-3.steamusercontent.com/ugc/768365068750455723/A2E8BBF482ED5B11D5A19D86A38A6FB03ED2A592/',
  },
  {
    ttsID: 48000,
    name: 'Margana, Chimeric Librarian',
    flags: [],
    set: 'Threshold of Time',
    FaceURL:
      'http://cloud-3.steamusercontent.com/ugc/1612841555440969792/9C7BB495AB0B99356A7106344A40FAA8A34C7488/',
    BackURL:
      'http://cloud-3.steamusercontent.com/ugc/768365068750455723/A2E8BBF482ED5B11D5A19D86A38A6FB03ED2A592/',
  },
  {
    ttsID: 48100,
    name: 'Lativ, Restorative Catalyst',
    flags: [],
    set: 'Threshold of Time',
    FaceURL:
      'http://cloud-3.steamusercontent.com/ugc/1612841555440970707/D04BD38D16E43B3B6477908BCA16F793AED94C5C/',
    BackURL:
      'http://cloud-3.steamusercontent.com/ugc/768365068750455723/A2E8BBF482ED5B11D5A19D86A38A6FB03ED2A592/',
  },
  {
    ttsID: 48200,
    name: 'Tachyon Tourbillon',
    flags: [],
    set: 'Threshold of Time',
    FaceURL:
      'http://cloud-3.steamusercontent.com/ugc/1612841555440972483/56CA25124E9AED0D53015C1DF2A60A26C08A168E/',
    BackURL:
      'http://cloud-3.steamusercontent.com/ugc/768365068750455723/A2E8BBF482ED5B11D5A19D86A38A6FB03ED2A592/',
  },
  {
    ttsID: 48300,
    name: 'Insatiable Abysm',
    flags: [],
    set: 'Threshold of Time',
    FaceURL:
      'http://cloud-3.steamusercontent.com/ugc/1612841555440973184/C58D11F8C9B60C8AC1D93BCA3758EEF5DC1E27B2/',
    BackURL:
      'http://cloud-3.steamusercontent.com/ugc/768365068750455723/A2E8BBF482ED5B11D5A19D86A38A6FB03ED2A592/',
  },
  {
    ttsID: 48400,
    name: 'Flaretrap Mantis',
    flags: [],
    set: 'Threshold of Time',
    FaceURL:
      'http://cloud-3.steamusercontent.com/ugc/1612841555440975350/13A6E885DDAC3E485DC3E174D76160C1F03A8828/',
    BackURL:
      'http://cloud-3.steamusercontent.com/ugc/768365068750455723/A2E8BBF482ED5B11D5A19D86A38A6FB03ED2A592/',
  },
  {
    ttsID: 48500,
    name: 'Lirep the Consortium Hierarch',
    flags: [],
    set: 'Threshold of Time',
    FaceURL:
      'http://cloud-3.steamusercontent.com/ugc/1612841555440976180/5A59EFC6CA4AF267F76A2D7ECBE687D4C659EC38/',
    BackURL:
      'http://cloud-3.steamusercontent.com/ugc/768365068750455723/A2E8BBF482ED5B11D5A19D86A38A6FB03ED2A592/',
  },
  {
    ttsID: 48600,
    name: 'Scissorslag Scoria',
    flags: [],
    set: 'Threshold of Time',
    FaceURL:
      'http://cloud-3.steamusercontent.com/ugc/1612841555440978638/2C1A4D80A6297C058F3CCD53560419969F0E4046/',
    BackURL:
      'http://cloud-3.steamusercontent.com/ugc/768365068750455723/A2E8BBF482ED5B11D5A19D86A38A6FB03ED2A592/',
  },
  {
    ttsID: 48700,
    name: 'Palisades the Eternal Ember',
    flags: [],
    set: 'Threshold of Time',
    FaceURL:
      'http://cloud-3.steamusercontent.com/ugc/1612841555440979573/302AE9585A6F49BCD394A97FCCF59CBC8B16F58C/',
    BackURL:
      'http://cloud-3.steamusercontent.com/ugc/768365068750455723/A2E8BBF482ED5B11D5A19D86A38A6FB03ED2A592/',
  },
  {
    ttsID: 48800,
    name: 'Magma Mountain Lavassus',
    flags: [],
    set: 'Threshold of Time',
    FaceURL:
      'http://cloud-3.steamusercontent.com/ugc/1612841555440981287/EBA341B0D2DE1E94A382015122331386A7FDA5D6/',
    BackURL:
      'http://cloud-3.steamusercontent.com/ugc/768365068750455723/A2E8BBF482ED5B11D5A19D86A38A6FB03ED2A592/',
  },
  {
    ttsID: 48900,
    name: 'Vengeance Behemoth',
    flags: [],
    set: 'Threshold of Time',
    FaceURL:
      'http://cloud-3.steamusercontent.com/ugc/1612841555440982158/C86BD709C619B27945DAEB7385475C5BA46E7ED2/',
    BackURL:
      'http://cloud-3.steamusercontent.com/ugc/768365068750455723/A2E8BBF482ED5B11D5A19D86A38A6FB03ED2A592/',
  },
  {
    ttsID: 49000,
    name: 'Forever Fire Snapdragon',
    flags: [],
    set: 'Threshold of Time',
    FaceURL:
      'http://cloud-3.steamusercontent.com/ugc/1612841555440983944/9D93C97CFF963CD7AAC455025B3ADF364E133B5A/',
    BackURL:
      'http://cloud-3.steamusercontent.com/ugc/768365068750455723/A2E8BBF482ED5B11D5A19D86A38A6FB03ED2A592/',
  },
  {
    ttsID: 49100,
    name: 'Burning Fleet Valvessel',
    flags: [],
    set: 'Threshold of Time',
    FaceURL:
      'http://cloud-3.steamusercontent.com/ugc/1612841555440984671/D0FA5EF1EC976EAD559C21A70225EC84093BC41C/',
    BackURL:
      'http://cloud-3.steamusercontent.com/ugc/768365068750455723/A2E8BBF482ED5B11D5A19D86A38A6FB03ED2A592/',
  },
  {
    ttsID: 49200,
    name: 'Ghost Ship Galleon',
    flags: [],
    set: 'Threshold of Time',
    FaceURL:
      'http://cloud-3.steamusercontent.com/ugc/1612841555440986341/3D7A881FD5DE19D1F214286FCE065DB56349F78C/',
    BackURL:
      'http://cloud-3.steamusercontent.com/ugc/768365068750455723/A2E8BBF482ED5B11D5A19D86A38A6FB03ED2A592/',
  },
  {
    ttsID: 49300,
    name: 'Noellag the Crucible of Time',
    flags: [],
    set: 'Threshold of Time',
    FaceURL:
      'http://cloud-3.steamusercontent.com/ugc/1612841555440987402/05F237949D5CA66C37C9D7BC251507C2201347B7/',
    BackURL:
      'http://cloud-3.steamusercontent.com/ugc/768365068750455723/A2E8BBF482ED5B11D5A19D86A38A6FB03ED2A592/',
  },
  {
    ttsID: 49500,
    name: 'Carcery the Twisted Tree',
    flags: [],
    set: 'Threshold of Time',
    FaceURL:
      'http://cloud-3.steamusercontent.com/ugc/1612841555440990272/F1BF336AF53E63B1A7F2B1CB08F09CF9208C7EBA/',
    BackURL:
      'http://cloud-3.steamusercontent.com/ugc/768365068750455723/A2E8BBF482ED5B11D5A19D86A38A6FB03ED2A592/',
  },
  {
    ttsID: 49400,
    name: 'Shivervault Glacier',
    flags: [],
    set: 'Threshold of Time',
    FaceURL:
      'http://cloud-3.steamusercontent.com/ugc/1612841555440989369/B9CAD5E2569073D161160C1C29D9CBF33BA50912/',
    BackURL:
      'http://cloud-3.steamusercontent.com/ugc/768365068750455723/A2E8BBF482ED5B11D5A19D86A38A6FB03ED2A592/',
  },
  {
    ttsID: 49600,
    name: 'Dustia the Auroral Whale',
    flags: [],
    set: 'Threshold of Time',
    FaceURL:
      'http://cloud-3.steamusercontent.com/ugc/1612841555440991987/43E37493C5992CE320FF0C4527181C33B4B84709/',
    BackURL:
      'http://cloud-3.steamusercontent.com/ugc/768365068750455723/A2E8BBF482ED5B11D5A19D86A38A6FB03ED2A592/',
  },
  {
    ttsID: 49700,
    name: "Eeventide's Archive",
    flags: [],
    set: 'Threshold of Time',
    FaceURL:
      'http://cloud-3.steamusercontent.com/ugc/1612841555440992736/21496FD750C208411E8BA60DE609DCD20FAFCD0E/',
    BackURL:
      'http://cloud-3.steamusercontent.com/ugc/768365068750455723/A2E8BBF482ED5B11D5A19D86A38A6FB03ED2A592/',
  },
  {
    ttsID: 49800,
    name: 'The Great Archive',
    flags: [],
    set: 'Threshold of Time',
    FaceURL:
      'http://cloud-3.steamusercontent.com/ugc/1612841555440994782/9366160DF4A7EA817860A352B7EF847FC6716614/',
    BackURL:
      'http://cloud-3.steamusercontent.com/ugc/768365068750455723/A2E8BBF482ED5B11D5A19D86A38A6FB03ED2A592/',
  },
  {
    ttsID: 49900,
    name: 'Archive, the Hub of Knowledge',
    flags: [],
    set: 'Threshold of Time',
    FaceURL:
      'http://cloud-3.steamusercontent.com/ugc/1612841555440995830/FD6169045742A32DF851890B3A585F8BC2DCAE43/',
    BackURL:
      'http://cloud-3.steamusercontent.com/ugc/768365068750455723/A2E8BBF482ED5B11D5A19D86A38A6FB03ED2A592/',
  },
  {
    ttsID: 50000,
    name: 'Leaderless Faction',
    flags: [],
    set: 'Threshold of Time',
    FaceURL:
      'http://cloud-3.steamusercontent.com/ugc/1612841555440997919/246BF771843CC71CA35FD183449CE06F9C33D42E/',
    BackURL:
      'http://cloud-3.steamusercontent.com/ugc/768365068750455723/A2E8BBF482ED5B11D5A19D86A38A6FB03ED2A592/',
  },
  {
    ttsID: 50100,
    name: 'Crossfire Formula',
    flags: [],
    set: 'Threshold of Time',
    FaceURL:
      'http://cloud-3.steamusercontent.com/ugc/1612841555440998872/BD8B244601758244983E2CF96E1A6FE7F40DEC3E/',
    BackURL:
      'http://cloud-3.steamusercontent.com/ugc/768365068750455723/A2E8BBF482ED5B11D5A19D86A38A6FB03ED2A592/',
  },
  {
    ttsID: 50200,
    name: 'Treasury of Treeforge',
    flags: [],
    set: 'Threshold of Time',
    FaceURL:
      'http://cloud-3.steamusercontent.com/ugc/1612841555441001043/CF928131A434D46246E8DE9FE5A247D573CF7469/',
    BackURL:
      'http://cloud-3.steamusercontent.com/ugc/768365068750455723/A2E8BBF482ED5B11D5A19D86A38A6FB03ED2A592/',
  },
  {
    ttsID: 50300,
    name: 'Repel the Thoughtstream',
    flags: [],
    set: 'Threshold of Time',
    FaceURL:
      'http://cloud-3.steamusercontent.com/ugc/1612841555441001997/DD95B483A3A2D842B1FE0F72AF38150F94B7EA1F/',
    BackURL:
      'http://cloud-3.steamusercontent.com/ugc/768365068750455723/A2E8BBF482ED5B11D5A19D86A38A6FB03ED2A592/',
  },
  {
    ttsID: 50400,
    name: 'Trifrost Floret',
    flags: [],
    set: 'Threshold of Time',
    FaceURL:
      'http://cloud-3.steamusercontent.com/ugc/1612841555441003550/A3E0D0E0C0F7864F15AE19D9DDB8399A888C7E30/',
    BackURL:
      'http://cloud-3.steamusercontent.com/ugc/768365068750455723/A2E8BBF482ED5B11D5A19D86A38A6FB03ED2A592/',
  },
  {
    ttsID: 50500,
    name: "Lucia's Throne",
    flags: [],
    set: 'Threshold of Time',
    FaceURL:
      'http://cloud-3.steamusercontent.com/ugc/1612841555441004699/57BC503E5408C5C30682E0571A8E137144965989/',
    BackURL:
      'http://cloud-3.steamusercontent.com/ugc/768365068750455723/A2E8BBF482ED5B11D5A19D86A38A6FB03ED2A592/',
  },
  {
    ttsID: 50600,
    name: 'Looming Autodross',
    flags: [],
    set: 'Threshold of Time',
    FaceURL:
      'http://cloud-3.steamusercontent.com/ugc/1612841555441006433/23018DA3EAA32CC1DCB2873EEF82DF9A747BB038/',
    BackURL:
      'http://cloud-3.steamusercontent.com/ugc/768365068750455723/A2E8BBF482ED5B11D5A19D86A38A6FB03ED2A592/',
  },
  {
    ttsID: 50700,
    name: 'Steamforge Navigator',
    flags: [],
    set: 'Threshold of Time',
    FaceURL:
      'http://cloud-3.steamusercontent.com/ugc/1612841555441007364/03A319F2DC1F91793FD506BE2DA1B7D1DFEB8E28/',
    BackURL:
      'http://cloud-3.steamusercontent.com/ugc/768365068750455723/A2E8BBF482ED5B11D5A19D86A38A6FB03ED2A592/',
  },
  {
    ttsID: 50800,
    name: 'Obstructica, Dredge Engine',
    flags: [],
    set: 'Threshold of Time',
    FaceURL:
      'http://cloud-3.steamusercontent.com/ugc/1612841555441009241/99F13448E90041D3C405F52D67EAB851EE014DCD/',
    BackURL:
      'http://cloud-3.steamusercontent.com/ugc/768365068750455723/A2E8BBF482ED5B11D5A19D86A38A6FB03ED2A592/',
  },
  {
    ttsID: 50900,
    name: 'Reverse Rain Obsidiaval',
    flags: [],
    set: 'Threshold of Time',
    FaceURL:
      'http://cloud-3.steamusercontent.com/ugc/1612841555441010317/4E983712506D9C952CF513E9A838D4DC86A81519/',
    BackURL:
      'http://cloud-3.steamusercontent.com/ugc/768365068750455723/A2E8BBF482ED5B11D5A19D86A38A6FB03ED2A592/',
  },
  {
    ttsID: 51000,
    name: 'Hailcore Meteor',
    flags: [],
    set: 'Threshold of Time',
    FaceURL:
      'http://cloud-3.steamusercontent.com/ugc/1612841555441012535/3DC37279624B99D2BC93B249482AFE6E3A0B1983/',
    BackURL:
      'http://cloud-3.steamusercontent.com/ugc/768365068750455723/A2E8BBF482ED5B11D5A19D86A38A6FB03ED2A592/',
  },
  {
    ttsID: 51100,
    name: 'Kindlefire Hound',
    flags: [],
    set: 'Threshold of Time',
    FaceURL:
      'http://cloud-3.steamusercontent.com/ugc/1612841555441013513/341A2314197FFEF6A5FF10481005C71EF44F5269/',
    BackURL:
      'http://cloud-3.steamusercontent.com/ugc/768365068750455723/A2E8BBF482ED5B11D5A19D86A38A6FB03ED2A592/',
  },
  {
    ttsID: 51200,
    name: "Eeventide's Armada",
    flags: [],
    set: 'Threshold of Time',
    FaceURL:
      'http://cloud-3.steamusercontent.com/ugc/1612841555441015426/CAC8439988CB5F0FA65FC55012B4B0589A13BDFC/',
    BackURL:
      'http://cloud-3.steamusercontent.com/ugc/768365068750455723/A2E8BBF482ED5B11D5A19D86A38A6FB03ED2A592/',
  },
  {
    ttsID: 51300,
    name: 'Snowcapped Sequoia',
    flags: [],
    set: 'Threshold of Time',
    FaceURL:
      'http://cloud-3.steamusercontent.com/ugc/1612841555441016400/73747077A836828E21E276AE4050D44816C64991/',
    BackURL:
      'http://cloud-3.steamusercontent.com/ugc/768365068750455723/A2E8BBF482ED5B11D5A19D86A38A6FB03ED2A592/',
  },
  {
    ttsID: 51400,
    name: 'Venomspire Monarch',
    flags: [],
    set: 'Threshold of Time',
    FaceURL:
      'http://cloud-3.steamusercontent.com/ugc/1612841555441017976/55D9ECA9DB84843DECA3B3ECE8FE5FF3A1C3A229/',
    BackURL:
      'http://cloud-3.steamusercontent.com/ugc/768365068750455723/A2E8BBF482ED5B11D5A19D86A38A6FB03ED2A592/',
  },
  {
    ttsID: 51500,
    name: 'Archivist Chancellor',
    flags: [],
    set: 'Threshold of Time',
    FaceURL:
      'http://cloud-3.steamusercontent.com/ugc/1612841555441019144/B5F07210DF026B992603F024588F95578BCE289A/',
    BackURL:
      'http://cloud-3.steamusercontent.com/ugc/1612841555441020131/F9561C2BDCBB99F31505BA52ED779BABAEB9516D/',
  },
  {
    ttsID: 51600,
    name: 'Archivist Maven',
    flags: [],
    set: 'Threshold of Time',
    FaceURL:
      'http://cloud-3.steamusercontent.com/ugc/1612841555441021678/4E7B3F0DACB3FDB896F1D3C15C146D496E932EEB/',
    BackURL:
      'http://cloud-3.steamusercontent.com/ugc/1612841555441020131/F9561C2BDCBB99F31505BA52ED779BABAEB9516D/',
  },
  {
    ttsID: 51700,
    name: 'Archivist Curator',
    flags: [],
    set: 'Threshold of Time',
    FaceURL:
      'http://cloud-3.steamusercontent.com/ugc/1612841555441022821/97A1A201589DE80F2F43823DCF79F0DBE325FB91/',
    BackURL:
      'http://cloud-3.steamusercontent.com/ugc/1612841555441020131/F9561C2BDCBB99F31505BA52ED779BABAEB9516D/',
  },
  {
    ttsID: 51800,
    name: 'Archivist Cardsmith',
    flags: [],
    set: 'Threshold of Time',
    FaceURL:
      'http://cloud-3.steamusercontent.com/ugc/1612841555441024644/C7881C03F3D4793669798A8CFE9124E3A1DC1C6D/',
    BackURL:
      'http://cloud-3.steamusercontent.com/ugc/1612841555441020131/F9561C2BDCBB99F31505BA52ED779BABAEB9516D/',
  },
  {
    ttsID: 51900,
    name: 'Transmogrification',
    flags: [],
    set: 'Threshold of Time',
    FaceURL:
      'http://cloud-3.steamusercontent.com/ugc/1612841555441025800/9D447A6AD8CAD80A531E4D995E376495716AE622/',
    BackURL:
      'http://cloud-3.steamusercontent.com/ugc/768365068750455723/A2E8BBF482ED5B11D5A19D86A38A6FB03ED2A592/',
  },
  {
    ttsID: 52000,
    name: 'Library Litigation',
    flags: [],
    set: 'Threshold of Time',
    FaceURL:
      'http://cloud-3.steamusercontent.com/ugc/1612841555441027613/7850DF096065E786066EC937DFEABE9CCA72C456/',
    BackURL:
      'http://cloud-3.steamusercontent.com/ugc/768365068750455723/A2E8BBF482ED5B11D5A19D86A38A6FB03ED2A592/',
  },
  {
    ttsID: 52100,
    name: 'Librarian License',
    flags: [],
    set: 'Threshold of Time',
    FaceURL:
      'http://cloud-3.steamusercontent.com/ugc/1612841555441028659/86475C5BB71C12A4FD5C8816838F78DC2505A068/',
    BackURL:
      'http://cloud-3.steamusercontent.com/ugc/768365068750455723/A2E8BBF482ED5B11D5A19D86A38A6FB03ED2A592/',
  },
  {
    ttsID: 52200,
    name: 'New Rage Cycles',
    flags: [],
    set: 'Threshold of Time',
    FaceURL:
      'http://cloud-3.steamusercontent.com/ugc/1612841555441030449/B63DDC479BF2AB38E537990F92CA1449134C761F/',
    BackURL:
      'http://cloud-3.steamusercontent.com/ugc/768365068750455723/A2E8BBF482ED5B11D5A19D86A38A6FB03ED2A592/',
  },
  {
    ttsID: 52300,
    name: 'Complexity Gear',
    flags: [],
    set: 'Threshold of Time',
    FaceURL:
      'http://cloud-3.steamusercontent.com/ugc/1612841555441032089/BEFEF85190F6E609A119D2BF89E459E029667040/',
    BackURL:
      'http://cloud-3.steamusercontent.com/ugc/768365068750455723/A2E8BBF482ED5B11D5A19D86A38A6FB03ED2A592/',
  },
  {
    ttsID: 52400,
    name: 'Combustion Chamber',
    flags: [],
    set: 'Threshold of Time',
    FaceURL:
      'http://cloud-3.steamusercontent.com/ugc/1612841555441034002/4772A55B4E7B44B89BD8D48EE4406901B460BDF0/',
    BackURL:
      'http://cloud-3.steamusercontent.com/ugc/768365068750455723/A2E8BBF482ED5B11D5A19D86A38A6FB03ED2A592/',
  },
  {
    ttsID: 52500,
    name: 'Scarletdrift Heights',
    flags: [],
    set: 'Threshold of Time',
    FaceURL:
      'http://cloud-3.steamusercontent.com/ugc/1612841555441034906/5ED7DA3E26B6098CAF405B4EEB7120F8A0C11793/',
    BackURL:
      'http://cloud-3.steamusercontent.com/ugc/768365068750455723/A2E8BBF482ED5B11D5A19D86A38A6FB03ED2A592/',
  },
  {
    ttsID: 52600,
    name: 'Breaking Helver',
    flags: [],
    set: 'Threshold of Time',
    FaceURL:
      'http://cloud-3.steamusercontent.com/ugc/1612841555441036667/513D664D90B5BC0E6A76217AF301B3174DB2D99F/',
    BackURL:
      'http://cloud-3.steamusercontent.com/ugc/768365068750455723/A2E8BBF482ED5B11D5A19D86A38A6FB03ED2A592/',
  },
  {
    ttsID: 52700,
    name: 'Lost at Sea',
    flags: [],
    set: 'Threshold of Time',
    FaceURL:
      'http://cloud-3.steamusercontent.com/ugc/1612841555441037474/0F4E0373CD44352CF4073067FC8484D928660147/',
    BackURL:
      'http://cloud-3.steamusercontent.com/ugc/768365068750455723/A2E8BBF482ED5B11D5A19D86A38A6FB03ED2A592/',
  },
  {
    ttsID: 52800,
    name: 'Season of Torrents',
    flags: [],
    set: 'Threshold of Time',
    FaceURL:
      'http://cloud-3.steamusercontent.com/ugc/1612841555441039692/8F31E57C355404937C63AA17E4F1AA2B28F2ED95/',
    BackURL:
      'http://cloud-3.steamusercontent.com/ugc/768365068750455723/A2E8BBF482ED5B11D5A19D86A38A6FB03ED2A592/',
  },
  {
    ttsID: 52900,
    name: 'Dimensional Chasm',
    flags: [],
    set: 'Threshold of Time',
    FaceURL:
      'http://cloud-3.steamusercontent.com/ugc/1612841555441040629/E9A25D88254A9C8BC27555AF36A086EA27E7C727/',
    BackURL:
      'http://cloud-3.steamusercontent.com/ugc/768365068750455723/A2E8BBF482ED5B11D5A19D86A38A6FB03ED2A592/',
  },
  {
    ttsID: 53000,
    name: "Tomorrow's Today",
    flags: [],
    set: 'Threshold of Time',
    FaceURL:
      'http://cloud-3.steamusercontent.com/ugc/1612841555441076830/3E36DA49F07A18AD3830B61A2501CD0E28DE9E49/',
    BackURL:
      'http://cloud-3.steamusercontent.com/ugc/768365068750455723/A2E8BBF482ED5B11D5A19D86A38A6FB03ED2A592/',
  },
  {
    ttsID: 53100,
    name: 'Wartime Developments',
    flags: [],
    set: 'Threshold of Time',
    FaceURL:
      'http://cloud-3.steamusercontent.com/ugc/1612841555441077692/242D62AC5F2CFB12C85A4D876CBB3D9FECA941AF/',
    BackURL:
      'http://cloud-3.steamusercontent.com/ugc/768365068750455723/A2E8BBF482ED5B11D5A19D86A38A6FB03ED2A592/',
  },
  {
    ttsID: 53200,
    name: 'Visceral Valvengine',
    flags: [],
    set: 'Threshold of Time',
    FaceURL:
      'http://cloud-3.steamusercontent.com/ugc/1612841555441079344/2104BC57CFC8BF4218D0B1A7C0E3A752C48D1348/',
    BackURL:
      'http://cloud-3.steamusercontent.com/ugc/768365068750455723/A2E8BBF482ED5B11D5A19D86A38A6FB03ED2A592/',
  },
  {
    ttsID: 53300,
    name: 'Hedge, Funder of Dreams',
    flags: [],
    set: 'Threshold of Time',
    FaceURL:
      'http://cloud-3.steamusercontent.com/ugc/1612841555441080266/174802D8288143ECAAC74FD52A5BAC883E5805EF/',
    BackURL:
      'http://cloud-3.steamusercontent.com/ugc/768365068750455723/A2E8BBF482ED5B11D5A19D86A38A6FB03ED2A592/',
  },
  {
    ttsID: 53400,
    name: 'Unstable Chroniker',
    flags: [],
    set: 'Threshold of Time',
    FaceURL:
      'http://cloud-3.steamusercontent.com/ugc/1612841555441082091/C8A5BE83A75FFD3B3585E3CE9E87A5C09951603D/',
    BackURL:
      'http://cloud-3.steamusercontent.com/ugc/768365068750455723/A2E8BBF482ED5B11D5A19D86A38A6FB03ED2A592/',
  },
  {
    ttsID: 53500,
    name: 'Crankwork Conflagration',
    flags: [],
    set: 'Threshold of Time',
    FaceURL:
      'http://cloud-3.steamusercontent.com/ugc/1612841555441083147/498DE616993222B522DBE344CC5E8BB709361B5B/',
    BackURL:
      'http://cloud-3.steamusercontent.com/ugc/768365068750455723/A2E8BBF482ED5B11D5A19D86A38A6FB03ED2A592/',
  },
  {
    ttsID: 53600,
    name: 'Comatose Comet',
    flags: [],
    set: 'Threshold of Time',
    FaceURL:
      'http://cloud-3.steamusercontent.com/ugc/1612841555441085494/7CD5AEE2B623CC36B273DCE6AB69D4AFD71624E1/',
    BackURL:
      'http://cloud-3.steamusercontent.com/ugc/768365068750455723/A2E8BBF482ED5B11D5A19D86A38A6FB03ED2A592/',
  },
  {
    ttsID: 53700,
    name: 'Blueblaze Angel',
    flags: [],
    set: 'Threshold of Time',
    FaceURL:
      'http://cloud-3.steamusercontent.com/ugc/1612841555441086505/2A99B4F1F82D8C0BBB29C624E8E05DF6E6A8FBF1/',
    BackURL:
      'http://cloud-3.steamusercontent.com/ugc/768365068750455723/A2E8BBF482ED5B11D5A19D86A38A6FB03ED2A592/',
  },
  {
    ttsID: 53800,
    name: 'Firenail Infernia',
    flags: [],
    set: 'Threshold of Time',
    FaceURL:
      'http://cloud-3.steamusercontent.com/ugc/1612841555441088221/0C5EDA4CB856EFBE7F150AFD5508A819216A90C1/',
    BackURL:
      'http://cloud-3.steamusercontent.com/ugc/768365068750455723/A2E8BBF482ED5B11D5A19D86A38A6FB03ED2A592/',
  },
  {
    ttsID: 53900,
    name: 'Seabound Inquisition',
    flags: [],
    set: 'Threshold of Time',
    FaceURL:
      'http://cloud-3.steamusercontent.com/ugc/1612841555441090134/8894509552B00FA758CF11D3DF2E3EE70B74FEF3/',
    BackURL:
      'http://cloud-3.steamusercontent.com/ugc/768365068750455723/A2E8BBF482ED5B11D5A19D86A38A6FB03ED2A592/',
  },
  {
    ttsID: 54000,
    name: 'Commandeered Caravel',
    flags: [],
    set: 'Threshold of Time',
    FaceURL:
      'http://cloud-3.steamusercontent.com/ugc/1612841555441091916/FE0F2C2E2BAFA9681A31511C65F7E9B444C9FD79/',
    BackURL:
      'http://cloud-3.steamusercontent.com/ugc/768365068750455723/A2E8BBF482ED5B11D5A19D86A38A6FB03ED2A592/',
  },
  {
    ttsID: 54100,
    name: 'Episodic Executioner',
    flags: [],
    set: 'Threshold of Time',
    FaceURL:
      'http://cloud-3.steamusercontent.com/ugc/1612841555441092943/7D6A060225DC172395E82009EF0E70766885CC56/',
    BackURL:
      'http://cloud-3.steamusercontent.com/ugc/768365068750455723/A2E8BBF482ED5B11D5A19D86A38A6FB03ED2A592/',
  },
  {
    ttsID: 54200,
    name: 'Lucid Liquidity Monolith',
    flags: [],
    set: 'Threshold of Time',
    FaceURL:
      'http://cloud-3.steamusercontent.com/ugc/1612841555441094933/DBB0DCC93EE2061A844E2BFC8E55970DC82B8AFA/',
    BackURL:
      'http://cloud-3.steamusercontent.com/ugc/768365068750455723/A2E8BBF482ED5B11D5A19D86A38A6FB03ED2A592/',
  },
  {
    ttsID: 54300,
    name: 'Rainforest Ribbonroot',
    flags: [],
    set: 'Threshold of Time',
    FaceURL:
      'http://cloud-3.steamusercontent.com/ugc/1612841555441095671/D95152362A76F04C5B8175A4C20439722583542B/',
    BackURL:
      'http://cloud-3.steamusercontent.com/ugc/768365068750455723/A2E8BBF482ED5B11D5A19D86A38A6FB03ED2A592/',
  },
  {
    ttsID: 54400,
    name: 'Revolver Mushroom',
    flags: [],
    set: 'Threshold of Time',
    FaceURL:
      'http://cloud-3.steamusercontent.com/ugc/1612841555441097741/EDD546866D65030C783885DD04901B5CFD23EF64/',
    BackURL:
      'http://cloud-3.steamusercontent.com/ugc/768365068750455723/A2E8BBF482ED5B11D5A19D86A38A6FB03ED2A592/',
  },
  {
    ttsID: 54500,
    name: 'Valdust Phoenixis',
    flags: [],
    set: 'Threshold of Time',
    FaceURL:
      'http://cloud-3.steamusercontent.com/ugc/1612841555441098629/0CFF1B6C65EF8A027FB657A402FC2B372676C4C8/',
    BackURL:
      'http://cloud-3.steamusercontent.com/ugc/768365068750455723/A2E8BBF482ED5B11D5A19D86A38A6FB03ED2A592/',
  },
  {
    ttsID: 54700,
    name: "Oliver's Psylerran Power",
    flags: [],
    set: 'Threshold of Time',
    FaceURL:
      'http://cloud-3.steamusercontent.com/ugc/1612841555441101010/AA7BEF593F65689B8FB61A314CF51DCCBAA69940/',
    BackURL:
      'http://cloud-3.steamusercontent.com/ugc/768365068750455723/A2E8BBF482ED5B11D5A19D86A38A6FB03ED2A592/',
  },
  {
    ttsID: 54800,
    name: 'Ofni, the Archive Alive',
    flags: [],
    set: 'Threshold of Time',
    FaceURL:
      'http://cloud-3.steamusercontent.com/ugc/1612841555441102858/4F8BD27B99CFEBFB8805D6FDC0A8174219CD4452/',
    BackURL:
      'http://cloud-3.steamusercontent.com/ugc/768365068750455723/A2E8BBF482ED5B11D5A19D86A38A6FB03ED2A592/',
  },
  {
    ttsID: 54900,
    name: 'Colossal Contrivance',
    flags: [],
    set: 'Threshold of Time',
    FaceURL:
      'http://cloud-3.steamusercontent.com/ugc/1612841555441105130/243EA074EAAF75734C9111CBEBE6282CE91DC16D/',
    BackURL:
      'http://cloud-3.steamusercontent.com/ugc/768365068750455723/A2E8BBF482ED5B11D5A19D86A38A6FB03ED2A592/',
  },
  {
    ttsID: 55000,
    name: 'Burning Heartbeat Drazah',
    flags: [],
    set: 'Threshold of Time',
    FaceURL:
      'http://cloud-3.steamusercontent.com/ugc/1612841555441106741/F77355837D128C57533AEF6719E1E9F20D30FF32/',
    BackURL:
      'http://cloud-3.steamusercontent.com/ugc/768365068750455723/A2E8BBF482ED5B11D5A19D86A38A6FB03ED2A592/',
  },
  {
    ttsID: 55100,
    name: 'Alcázar Manor, Aiculoft',
    flags: [],
    set: 'Threshold of Time',
    FaceURL:
      'http://cloud-3.steamusercontent.com/ugc/1612841555441109197/CC4DB94985A808C097C70E20EAA3217739A97FBC/',
    BackURL:
      'http://cloud-3.steamusercontent.com/ugc/768365068750455723/A2E8BBF482ED5B11D5A19D86A38A6FB03ED2A592/',
  },
  {
    ttsID: 55200,
    name: 'Cloudscrape Sovereign',
    flags: [],
    set: 'Threshold of Time',
    FaceURL:
      'http://cloud-3.steamusercontent.com/ugc/1612841555441111288/D7E2BBE4E627C14D7B2BAA5FDF6698E7AD0C89E6/',
    BackURL:
      'http://cloud-3.steamusercontent.com/ugc/768365068750455723/A2E8BBF482ED5B11D5A19D86A38A6FB03ED2A592/',
  },
  {
    ttsID: 55300,
    name: "Hourglyphia, Eeventide's Third Key",
    flags: [],
    set: 'Threshold of Time',
    FaceURL:
      'http://cloud-3.steamusercontent.com/ugc/1612841555441112208/246E9B6F73185BB3FE1E611BD88644BA943B1B16/',
    BackURL:
      'http://cloud-3.steamusercontent.com/ugc/768365068750455723/A2E8BBF482ED5B11D5A19D86A38A6FB03ED2A592/',
  },
  {
    ttsID: 55400,
    name: 'Torn Asunder',
    flags: [],
    set: 'Threshold of Time',
    FaceURL:
      'http://cloud-3.steamusercontent.com/ugc/1612841555441113663/13F0AB88B801B1491405C4E3E239DC43DB09F0B0/',
    BackURL:
      'http://cloud-3.steamusercontent.com/ugc/768365068750455723/A2E8BBF482ED5B11D5A19D86A38A6FB03ED2A592/',
  },
]);
