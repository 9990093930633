import React from 'react';
import { ExodusCard } from '../types/ExodusCard';

export interface CardImageViewProps extends React.HTMLAttributes<HTMLImageElement> {
  card: ExodusCard;
  isSmall?: boolean;
  isFlipped?: boolean;
}

export default function CardImageView({ card, isSmall, isFlipped, ...imgProps }: CardImageViewProps) {
  const imgID =
    isFlipped || false
      ? card.s3_backside_large_image_name
      : card.s3_large_image_name || card.large_image_url.split('/').slice(-1)[0] + (isSmall ? '-small' : '');
  return (
    <img
      src={imgID ? `https://d2csyilrom9ygt.cloudfront.net/images/${imgID}` : card.large_image_url}
      alt={card.name}
      {...imgProps}
    />
  );
}
