import { getCardKey, getCardCount } from './DeckState';
import repeat from './functional/repeat';
import isDefined from './functional/isDefined';
import { AppCard, Deck, SavedDecks } from './types/AppState';
import { ExodusCard } from './types/ExodusCard';
import { parseQuery } from './services/SearchQuery';
import { IDedExodusCards } from './services/IDedExodusCards';
import {
  DEFAULT_SEARCH_RESULTS,
  search,
} from './services/FuseSearchCards';

export interface Modal {
  type: 'about' | 'permalink' | 'search-help';
}

export interface CardModal {
  type: 'card';
  card: ExodusCard;
}

export interface AppState {
  readonly cards: Deck;
  readonly deckName: string;
  readonly savedDecks: SavedDecks;
  readonly showingModal?: Modal | CardModal;
  readonly searchResults: ExodusCard[];
  readonly activeSearchPageIndex: number;
  readonly searchResultPages: ExodusCard[][];
}

export type SetStateParams = {
  [P in keyof AppState]?: AppState[P];
};

export type TGetState = () => AppState;
export type TSetState = (arg0: SetStateParams) => void;

export interface UpdatesParams {
  getState: TGetState;
  setState: TSetState;
}

export default class Updates {
  private getState: TGetState;
  private setState: TSetState;

  constructor({ getState, setState }: UpdatesParams) {
    this.getState = getState;
    this.setState = setState;
  }

  setCardCount = (card: ExodusCard, count: number) => {
    const { cards } = this.getState();
    const k = getCardKey(card);
    if (count <= 0) {
      if (cards.has(k)) {
        const newCards = new Map(cards);
        newCards.delete(k);
        this.setState({
          cards: newCards,
        });
      }
    } else {
      this.setState({
        cards: new Map(cards).set(k, {
          ...card,
          quantity: Math.min(2, count),
        }),
      });
    }
  };

  loadDeckList = (cardIDs: string[]) => {
    if (!cardIDs.length) {
      this.setState({ cards: new Map() });
      return;
    }
    this.setState({
      cards: new Map(),
    });
    const cardList: ExodusCard[] = cardIDs
      .map((cid) => IDedExodusCards.get(cid))
      .filter(isDefined);
    const cardMap: Deck = new Map();
    for (const c of cardList) {
      const key = getCardKey(c);
      const existingCard = cardMap.get(key);
      if (existingCard) {
        existingCard.quantity++;
      } else {
        cardMap.set(key, { ...c, quantity: 1 });
      }
    }
    this.setState({
      cards: cardMap,
    });
  };

  addCard = (card: ExodusCard) => {
    this.setCardCount(card, getCardCount(card, this.getState().cards) + 1);
  };

  removeCard = (card: ExodusCard) => {
    this.setCardCount(card, getCardCount(card, this.getState().cards) - 1);
  };

  setDeckName = (deckName: string) => {
    this.setState({ deckName });
  };

  newDeck = () => {
    // save existing with unsaved prefix if not saved?
    this.setState({ cards: new Map(), deckName: '' });
  };

  saveDeck = (deckName: string) => {
    deckName = deckName.trim();
    if (deckName) {
      const { savedDecks, cards } = this.getState();
      const newSavedDecks = new Map(savedDecks);
      if (cards?.size === 0) {
        newSavedDecks.delete(deckName);
      } else {
        newSavedDecks.set(deckName, Array.from(cards?.entries() || []));
      }
      this.setState({ savedDecks: newSavedDecks });
    }
  };

  loadDeck = (deckName: string) => {
    // save existing with unsaved prefix if not saved?
    const { savedDecks } = this.getState();
    this.setState({ deckName, cards: new Map() });
    this.loadDeckList(
      Array.from(new Map(savedDecks?.get(deckName)).values())
        .map((c: AppCard) => repeat(c.card_id, c.quantity))
        .flat()
    );
  };

  showCard = (card: ExodusCard) => {
    this.setState({
      showingModal: { type: 'card', card },
    });
  };

  createPermalink = () => {
    this.setState({
      showingModal: {
        type: 'permalink',
      },
    });
  };

  showSearchHelpModal = () => {
    this.setState({
      showingModal: {
        type: 'search-help',
      },
    });
  };

  showAboutModal = () => {
    this.setState({
      showingModal: {
        type: 'about',
      },
    });
  };

  hideModal = () => {
    this.setState({
      showingModal: undefined,
    });
  };

  static partialStateForSearchResults(searchResults: ExodusCard[]): {
    searchResults: ExodusCard[];
    searchResultPages: ExodusCard[][];
    activeSearchPageIndex: number;
  } {
    const searchResultPages = [];
    const PAGE_SIZE = 12;
    for (let pageI = 0; pageI < searchResults.length; pageI += PAGE_SIZE) {
      searchResultPages.push(searchResults.slice(pageI, pageI + PAGE_SIZE));
    }
    if (searchResultPages.length === 0) {
      searchResultPages.push([]);
    }
    return {
      searchResults,
      activeSearchPageIndex: 0,
      searchResultPages,
    };
  }

  search = (input: string) => {
    if (input === '') {
      this.setState(
        Updates.partialStateForSearchResults(DEFAULT_SEARCH_RESULTS)
      );
      return;
    }
    const query = parseQuery(input);
    if (!query) {
      return;
    }
    const r = search(
      query,
      {
        savedDecks: this.getState().savedDecks,
        cards: this.getState().cards,
      }
    );
    this.setState(
      Updates.partialStateForSearchResults(
        r.type === 'WasInvalid' ? [] : r.results
      )
    );
    return;
  };

  selectSearchPage = (pageI: number) => {
    this.setState({
      activeSearchPageIndex: Math.max(
        0,
        Math.min(pageI, this.getState().searchResultPages.length - 1)
      ),
    });
  };

  addCardBySearchQuery = (input: string) => {
    const query = parseQuery(input);
    if (!query) {
      return;
    }
    const r = search(
      query,
      {
        savedDecks: this.getState().savedDecks,
        cards: this.getState().cards,
      }
    );
    if (r.type === 'DidFilter' && r) {
      const resultCard = r.results[0];
      if (resultCard !== undefined) {
        this.addCard(resultCard);
      }
    }
  };
}
