import { Maybe } from '../types/Maybe';
import { SafeMaybe, Nothing, Just } from '../functional/SafeMaybe';
import {
  TParserMonad,
  TError,
  ParserMonad,
  Regex,
  Exact,
  Either,
  EOF,
  Try,
  Seq,
  parse,
  ParseErrorExpected,
} from '../parser/Parser';
import {
  BooleanField,
  EnumField,
  NumericField,
  FuseField,
  FUSE_FIELDS,
  FuseQuery,
  FuseOr,
  FuseAnd,
  FuseTerm,
  SearchCurrentDeck,
  SearchHasBooleanField,
  SearchNumericField,
  SearchFieldExists,
  SearchSavedDeck,
  SearchEnumField,
  SearchNot,
  negate,
} from './FuseSearchCards';

const M = ParserMonad;

export const parseQuery = (
  text: string,
  onError?: (e: TError) => Maybe<FuseQuery>
): Maybe<FuseQuery> => parse(QueryParser, text, onError);

const ALIASES: Map<string, string[]> = new Map()
  .set('t', ['card_type', 'creature_type'])
  .set('type', ['card_type', 'creature_type'])
  .set('e', ['effect'])
  .set('ed', ['edition'])
  .set('r', ['rarity'])
  .set('a', ['artist'])
  .set('s', ['set_name'])
  .set('set', ['set_name'])
  .set('d', ['deck'])
  .set('cd', ['current_deck']);

enum DeckField {
  deck = 'deck',
  current_deck = 'current_deck',
}

function rawFieldToAliasedFields(rawFieldName: string): string[] {
  return (
    ALIASES.get(rawFieldName.toLowerCase()) ?? [rawFieldName.toLowerCase()]
  );
}

const u = M.using;
const Whitespace = Regex(/\s*/);
const Token = (pattern: RegExp): TParserMonad<string> =>
  u(Whitespace, () => Regex(pattern));
const Identifier: TParserMonad<string> = Token(/[_\p{L}0-9.*,/'-]+/u);
const String_: TParserMonad<string> = u(Token(/"/), () =>
  u(Regex(/(\\"|[^"])+/), (value) => u(Exact('"'), () => M.returning(value)))
);

const Comparable: TParserMonad<string> = Either(Identifier, String_);

const Negation: TParserMonad<'-'> = u(Whitespace, () => Exact('-'));

type ColonOp = '>=' | '>' | ':' | '=' | '<' | '<=';
const ColonOpMap: Map<string, ColonOp> = new Map()
  .set(':>=', '>=')
  .set('>=', '>=')
  .set(':<=', '<=')
  .set('<=', '<=')
  .set(':>', '>')
  .set('>', '>')
  .set(':<', '<')
  .set('<', '<')
  .set(':', ':')
  .set('=', '=');
const ColonOperator: TParserMonad<ColonOp> = u(Whitespace, () =>
  Array.from(ColonOpMap.entries())
    .map(([opStr, op]) => M.fmap(Exact(opStr), (_) => op))
    .reduce(Either)
);

type FieldType =
  | { type: 'IsFuseField'; fieldName: FuseField }
  | { type: 'IsDeckField'; fieldName: DeckField }
  | { type: 'IsNumericField'; fieldName: NumericField }
  | { type: 'IsBooleanField'; fieldName: BooleanField }
  | { type: 'IsEnumField'; fieldName: EnumField };

const TermFields: TParserMonad<Array<FieldType>> = u(
  Identifier,
  (rawFieldName) => {
    const fieldNames = rawFieldToAliasedFields(rawFieldName);
    const fields: Array<FieldType> = [];
    for (const fieldName of fieldNames) {
      const fuseField = FUSE_FIELDS.get(fieldName);
      const deckField = new Map(Object.entries(DeckField)).get(fieldName);
      const booleanField = new Map(Object.entries(BooleanField)).get(fieldName);
      const numericField = new Map(Object.entries(NumericField)).get(fieldName);
      const enumField = new Map(Object.entries(EnumField)).get(fieldName);
      if (fuseField !== undefined) {
        fields.push({ type: 'IsFuseField', fieldName: fuseField });
      } else if (deckField !== undefined) {
        fields.push({ type: 'IsDeckField', fieldName: deckField });
      } else if (booleanField !== undefined) {
        fields.push({ type: 'IsBooleanField', fieldName: booleanField });
      } else if (numericField !== undefined) {
        fields.push({ type: 'IsNumericField', fieldName: numericField });
      } else if (enumField !== undefined) {
        fields.push({ type: 'IsEnumField', fieldName: enumField });
      } else {
        return ParseErrorExpected(
          `Don't know how to search for ${JSON.stringify(fieldName)}`
        );
      }
    }
    return M.returning(fields);
  }
);
const LabelledFilter: TParserMonad<FuseQuery> = u(TermFields, (fields) =>
  u(ColonOperator, (colonOperator) =>
    u(Comparable, (comparedTo) => {
      const query = fields.reduce(
        (prevFieldQ: SafeMaybe<TParserMonad<FuseQuery>>, nextField) => {
          const parseNextQ = parseFieldQuery({
            field: nextField,
            colonOperator,
            comparedTo,
          });
          return new Just(
            prevFieldQ.type !== 'Nothing'
              ? u(prevFieldQ.value, (prevQ) =>
                  M.fmap(parseNextQ, (nextQ) => new FuseOr(prevQ, nextQ))
                )
              : parseNextQ
          );
        },
        Nothing.Inst
      );
      return query.type !== 'Nothing'
        ? query.value
        : ParseErrorExpected(`Couldn't find any fields to search in query`);
    })
  )
);

const UnlabelledFilter: TParserMonad<FuseQuery> = M.fmap(
  Comparable,
  (comparedTo) =>
    rawFieldToAliasedFields(comparedTo).some((f) => f === 'current_deck')
      ? new SearchCurrentDeck()
      : new FuseTerm({
          field: FuseField.name,
          // TODO: reuse, everywhere there's a new FuseTerm
          contains: comparedTo.replace(/(^\*|\*$)/g, ''),
        })
);

const toFuseQuery: (v: FuseQuery) => FuseQuery = (x) => x;
const GroupedFilter: TParserMonad<FuseQuery> = u(
  Try(Negation, M.returning(undefined)),
  (prefixOperator) =>
    M.fmap(
      Either(
        u(Token(/\(/), (_openParen) =>
          u(Query, (filters) =>
            u(Token(/\)/), (_closeParen) => M.returning(filters))
          )
        ),
        Either(
          M.fmap(LabelledFilter, toFuseQuery),
          M.fmap(UnlabelledFilter, toFuseQuery)
        )
      ),
      (inner): FuseQuery => {
        switch (prefixOperator) {
          case '-':
            return negate(inner);
          case undefined:
            return inner;
        }
      }
    )
);

const FilterOperator: TParserMonad<'or' | 'and'> = Either(
  M.fmap(Token(/(or|OR)/), () => 'or'),
  M.fmap(Try(Token(/(and|AND)/), M.returning(undefined)), () => 'and')
);
const Filters: TParserMonad<FuseQuery> = u(GroupedFilter, (filter) =>
  u(FilterOperator, (filterOperator) =>
    M.fmap(Query, (nextFilters): FuseQuery => {
      switch (filterOperator) {
        case 'and':
          return new FuseAnd(filter, nextFilters);
        case 'or':
          return new FuseOr(filter, nextFilters);
      }
    })
  )
);
const Query: TParserMonad<FuseQuery> = Either(Filters, GroupedFilter);

const QueryParser: TParserMonad<FuseQuery> = u(Query, (result) =>
  M.fmap(Seq(Whitespace, EOF), () => insertDefaultEditionFilter(result))
);

function insertDefaultEditionFilter(query: FuseQuery): FuseQuery {
  return hasEdition(query)
    ? query
    : new FuseAnd(
        new SearchHasBooleanField(BooleanField.is_default_edition),
        query
      );
}
function hasEdition(q: FuseQuery): boolean {
  switch (q.type) {
    case 'Or':
    case 'And':
      return hasEdition(q.left) || hasEdition(q.right);
    case 'Not':
      return hasEdition(q.inner);
    case 'NumericField':
    case 'FieldExists':
    case 'Invalid':
    case 'Term':
      return false;
    case 'CurrentDeck':
    case 'SavedDeck':
      return true;
    case 'HasBooleanField':
      return q.field === BooleanField.is_default_edition;
    case 'EnumField':
      return q.props.field === EnumField.edition;
  }
}

function parseFieldQuery({
  field,
  colonOperator,
  comparedTo,
}: {
  field: FieldType;
  colonOperator: ColonOp;
  comparedTo: string;
}): TParserMonad<FuseQuery> {
  const onlyExactMatch = colonOperator === '=';
  switch (field.type) {
    case 'IsFuseField':
      return M.returning(
        comparedTo === '*'
          ? new SearchFieldExists(field.fieldName)
          : new FuseTerm({
              field: field.fieldName,
              contains: comparedTo.replace(/(^\*|\*$)/g, ''),
              onlyExactMatch,
            })
      );
    case 'IsBooleanField': {
      switch (comparedTo) {
        case 'true':
          return M.returning(new SearchHasBooleanField(field.fieldName));
        case 'false':
          return M.returning(
            new SearchNot(new SearchHasBooleanField(field.fieldName))
          );
      }
      return ParseErrorExpected('Boolean field can only be true or false');
    }
    case 'IsDeckField': {
      switch (field.fieldName) {
        case DeckField.current_deck:
          return M.returning(new SearchCurrentDeck());
        case DeckField.deck:
          return M.returning(new SearchSavedDeck(comparedTo));
      }
      break;
    }
    case 'IsNumericField': {
      const count = parseInt(comparedTo);
      return '' + count === comparedTo
        ? M.returning(
            new SearchNumericField({
              field: field.fieldName,
              comparisonOperator: colonOperator === ':' ? '=' : colonOperator,
              comparedTo: count,
            })
          )
        : ParseErrorExpected('Numeric fields can only be compared to numbers');
    }
    case 'IsEnumField': {
      const comparisonOperator =
        colonOperator === '=' ? '=' : colonOperator === ':' ? ':' : undefined;
      if (comparisonOperator === undefined) {
        return ParseErrorExpected('Invalid comparison operator');
      }
      if (comparisonOperator === ':' && comparedTo.startsWith('*')) {
        return M.returning(
          new SearchEnumField({
            field: field.fieldName,
            comparisonOperator: ':*',
            comparedTo: comparedTo.replace(/^\*/, ''),
          })
        );
      }
      return M.returning(
        new SearchEnumField({
          field: field.fieldName,
          comparisonOperator,
          comparedTo,
        })
      );
    }
  }
}
