export type SafeMaybe<T> = Nothing | Just<T>;
export class Nothing {
  readonly type = 'Nothing';
  static Inst = new Nothing();
}
export class Just<T> {
  readonly type = 'Just';
  constructor(public readonly value: T) {}
}
export const asMaybe = <T>(x: SafeMaybe<T>): SafeMaybe<T> => x;
