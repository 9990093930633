import { InputGroup, Button, Form, Pagination } from 'react-bootstrap';
import debounce from 'lodash.debounce';
import '../styles/CardSearchView.css';
import { ExodusCard } from '../types/ExodusCard';
import Updates from '../Updates';
import { Deck } from '../types/AppState';
import { DefinePaginationForNPages } from './DefinePaginationForNPages';
import { getCardCount } from '../DeckState';
import CardImageView from './CardImageView';

interface CardSearchViewProps {
  cards: Deck;
  updates: Updates;
  searchResults: ExodusCard[];
  searchResultPages: ExodusCard[][];
  activeSearchPageIndex: number;
}
export default function CardSearchView({
  cards,
  updates,
  searchResults,
  searchResultPages,
  activeSearchPageIndex,
}: CardSearchViewProps) {
  return (
    <span style={{ display: 'flex', minHeight: '100vh' }}>
      <div style={{ width: '100%' }}>
        <InputGroup>
          <Form.Control
            type="text"
            placeholder="Search cards"
            onChange={debounce((e) => {
              updates.search(e.target.value);
            }, 300)}
          />
          <InputGroup.Append>
            <Button
              variant="outline-primary"
              onClick={() => updates.showSearchHelpModal()}
            >
              ?
            </Button>
          </InputGroup.Append>
        </InputGroup>
        <div className="col">
          <div className="searchResultCount">
            {searchResults.length} results
          </div>
          <div className="searchPaginationRow">
            <SearchPagination
              activeSearchPageIndex={activeSearchPageIndex}
              searchResultPages={searchResultPages}
              updates={updates}
            />
          </div>
          {searchResultPages[activeSearchPageIndex].map((card) => (
            <span
              key={card.card_id}
              style={{ display: 'inline-block' }}
              className={'searchImage'}
            >
              <ResultCard card={card} updates={updates} cards={cards} />
            </span>
          ))}
          <div className="searchPaginationRow" style={{ margin: '16px 0' }}>
            <SearchPagination
              activeSearchPageIndex={activeSearchPageIndex}
              searchResultPages={searchResultPages}
              updates={updates}
            />
          </div>
        </div>
      </div>
    </span>
  );
}

interface ResultCardProps {
  card: ExodusCard;
  cards: Deck;
  updates: Updates;
}
const ResultCard = ({card,cards,updates}: ResultCardProps) =>
  <span style={{ padding: 4, display: 'inline-block', width: '100%' }}>
    <CardImageView
      card={card}
      style={{ width: '100%' }}
      onClick={(e) => { updates.showCard(card); }}
    />
    <span className="addCardButtonRow">
      <SetCardCountButton card={card} count={1} cards={cards} updates={updates} />
      <SetCardCountButton card={card} count={2} cards={cards} updates={updates} />
    </span>
  </span>;

interface SearchPaginationProps {
  searchResultPages: ExodusCard[][];
  activeSearchPageIndex: number;
  updates: Updates;
}
function SearchPagination({
  searchResultPages,
  activeSearchPageIndex,
  updates,
}: SearchPaginationProps) {
  const select = (i: number) => () => updates.selectSearchPage(i);
  return (
    <Pagination>
      <Pagination.Prev
        disabled={activeSearchPageIndex === 0}
        onClick={select(activeSearchPageIndex - 1)}
      />
      {DefinePaginationForNPages({
        activePageIndex: activeSearchPageIndex,
        numPages: searchResultPages.length,
      }).map((p) =>
        p.type === 'Item' ? (
          <Pagination.Item
            key={'' + p.index}
            onClick={select(p.index)}
            active={p.index === activeSearchPageIndex}
          >
            {p.index + 1}
          </Pagination.Item>
        ) : (
          <Pagination.Ellipsis key={p.key} disabled />
        )
      )}
      <Pagination.Next
        disabled={activeSearchPageIndex === searchResultPages.length - 1}
        onClick={select(activeSearchPageIndex + 1)}
      />
    </Pagination>
  );
}

interface SetCardCountButtonProps {
  card: ExodusCard;
  count: number;
  updates: Updates;
  cards: Deck;
}

function SetCardCountButton({ card, count, cards, updates }: SetCardCountButtonProps) {
  const isSet = getCardCount(card, cards) === count;
  return (
    <span className="addCardButtonContainer">
      <Button
        className="addCardButton"
        disabled={!cards}
        variant={isSet ? 'success' : 'outline-primary'}
        onClick={() => updates.setCardCount(card, isSet ? 0 : count)}
      >
        {count}
      </Button>
    </span>
  );
}
