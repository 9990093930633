import { Component } from 'react';
import CardSearchView from './CardSearchView';
import CardDetailView from './CardDetailView';
import { Button, Container, Row, Col, Modal } from 'react-bootstrap';
import DeckListView from './DeckListView';
import DeckMenu from './DeckMenu';
import exportDeck from '../services/TTSExportService';
import DeckStatsView from './DeckStatsView';
import debounce from 'lodash.debounce';
import Updates, { AppState } from '../Updates';
import DeckInsertCardView from './DeckInsertCardView';
import ShareDeckPermalink from './ShareDeckPermalink';
import SearchHelp from './SearchHelp';
import AboutSection from './AboutSection';
import repeat from '../functional/repeat';
import { Navbar, Nav } from 'react-bootstrap';
import { AppCard, DeckSerialized, SavedDecksSerialized } from '../types/AppState';
import { DEFAULT_SEARCH_RESULTS } from '../services/FuseSearchCards';

export default class DeckBuilderView extends Component {
  private url: URL;
  private isLoadingDeckFromURL: boolean;
  private updates: Updates;
  public state: AppState;

  constructor(props: {}) {
    super(props);
    this.url = new URL(window.location.href);
    this.isLoadingDeckFromURL = this.url.searchParams.has('cards');
    this.state = {
      deckName: this.isLoadingDeckFromURL
        ? this.url.searchParams.get('dname') || ''
        : localStorage.getItem('deckName') || '',
      savedDecks: this.loadSavedDecks(),
      cards: new Map<string, AppCard>(),
      ...Updates.partialStateForSearchResults(
        DEFAULT_SEARCH_RESULTS,
      ),
    };
    this.updates = new Updates({
      getState: () => this.state,
      setState: (newState) => {
        this.setState(newState);
        if (newState.deckName !== undefined) {
          this.storeDeckName(newState.deckName);
        }
        if (newState.cards) {
          localStorage.setItem(
            'myDeck',
            JSON.stringify({
              version: this.CURRENT_VERSION,
              cards: Array.from(newState.cards.entries()),
            })
          );
        }
        if (newState.savedDecks) {
          localStorage.setItem(
            'savedDecks',
            JSON.stringify({
              version: this.CURRENT_VERSION,
              mapEntries: Array.from(newState.savedDecks.entries()),
            })
          );
        }
      },
    });
  }

  componentDidMount() {
    if (this.isLoadingDeckFromURL) {
      switch (this.url.searchParams.get('v')) {
        case '1': {
          const cardIDs = JSON.parse(`[${this.url.searchParams.get('cards')}]`).map((cid: string) => `card_${cid}`);
          this.updates.loadDeckList(cardIDs);
          break;
        }
        default:
          break;
      }
    } else {
      const myDeckStr = localStorage.getItem('myDeck');
      this.updates.loadDeckList(
        // TODO: have new deck version that is just card_id list
        Array.from(this.upgradeDeck(myDeckStr && JSON.parse(myDeckStr)).values())
          .map((c) => repeat(c.card_id, c.quantity))
          .flat()
      );
    }
  }

  CURRENT_VERSION = 1;
  upgradeDeck(deck: DeckSerialized) {
    if (!deck) {
      return new Map();
    }
    switch (deck.version) {
      case this.CURRENT_VERSION:
        return new Map(deck.cards);
      default:
        return new Map();
    }
  }
  loadSavedDecks() {
    const rawSavedDecks = localStorage.getItem('savedDecks');
    if (!rawSavedDecks) {
      return new Map();
    }
    const savedDecks: SavedDecksSerialized = JSON.parse(rawSavedDecks);
    switch (savedDecks.version) {
      case this.CURRENT_VERSION:
        return new Map(
          savedDecks.mapEntries.filter(([, deck]) => deck instanceof Array)
            .map(([name,deck]) => [name.trim(), deck])
        );
      default:
        return new Map();
    }
  }

  exportCurrentDeck = (name: string) => {
    if (this.state.cards) {
      exportDeck(this.state.cards, name);
    }
  };

  storeDeckName = debounce((deckName) => {
    localStorage.setItem('deckName', deckName);
  }, 300);

  render() {
    return (
      <span>
        <Navbar bg="dark" variant="dark">
          <Navbar.Brand>exodecks</Navbar.Brand>
          <Nav className="ml-auto">
            <Nav.Link onClick={this.updates.showAboutModal}>About</Nav.Link>
          </Nav>
        </Navbar>
        <Container fluid>
          <Row className="pt-2">
            <Col>
              <DeckMenu
                cards={this.state.cards}
                deckName={this.state.deckName}
                savedDecks={this.state.savedDecks}
                updates={this.updates}
              />
            </Col>
          </Row>
          <Row className="flex-wrap-reverse pt-3">
            <Col md={7}>
              <CardSearchView
                cards={this.state.cards}
                searchResults={this.state.searchResults}
                activeSearchPageIndex={this.state.activeSearchPageIndex}
                searchResultPages={this.state.searchResultPages}
                updates={this.updates}
              />
            </Col>
            <Col md={5}>
              <DeckStatsView cards={this.state.cards} />
              <DeckInsertCardView updates={this.updates} />
              <DeckListView cards={this.state.cards} updates={this.updates} />
            </Col>
          </Row>
        </Container>
        {this.getModal()}
      </span>
    );
  }

  getModal() {
    const modal = this.state.showingModal;
    switch (modal?.type) {
      case 'card':
        return (
          <Modal size="lg" show={!!this.state.showingModal} onHide={this.updates.hideModal}>
            <Modal.Header closeButton>
              <h4>{modal.card.name}</h4>
            </Modal.Header>
            <Modal.Body>
              <CardDetailView card={modal.card} />
            </Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={this.updates.hideModal}>
                Close
              </Button>
            </Modal.Footer>
          </Modal>
        );
      case 'permalink':
        return (
          <Modal show={!!this.state.showingModal} onHide={this.updates.hideModal} centered>
            <Modal.Header closeButton>
              <h4>Share Link</h4>
            </Modal.Header>
            <Modal.Body>
              <ShareDeckPermalink
                deckName={this.state.deckName}
                cards={this.state.cards}
                version={this.CURRENT_VERSION}
                updates={this.updates}
              />
            </Modal.Body>
          </Modal>
        );
      case 'search-help':
        return (
          <Modal size="lg" show={!!this.state.showingModal} onHide={this.updates.hideModal}>
            <Modal.Header closeButton>
              <h4>How to Search</h4>
            </Modal.Header>
            <Modal.Body>
              <SearchHelp />
            </Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={this.updates.hideModal}>
                Close
              </Button>
            </Modal.Footer>
          </Modal>
        );
      case 'about':
        return (
          <Modal show={!!this.state.showingModal} onHide={this.updates.hideModal}>
            <Modal.Header closeButton>
              <h4>About</h4>
            </Modal.Header>
            <Modal.Body>
              <AboutSection />
            </Modal.Body>
          </Modal>
        );
      default:
        return undefined;
    }
  }
}
