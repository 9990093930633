interface TTSScrapedCardWithoutEnums {
  readonly ttsID: number;
  readonly name: string;
  readonly FaceURL: string;
  readonly BackURL: string;
}
export type TTSScrapedCard = TTSScrapedCardWithoutEnums & {
  flags: Array<TTSScrapedFlag>;
  set: TTSScrapedSet;
}
export type RawTTSScrapedCard = TTSScrapedCardWithoutEnums & {
  flags: Array<keyof typeof TTSScrapedFlag>;
  set: keyof typeof TTSScrapedSet;
}
export enum TTSScrapedFlag {
  'Promo' = 'Promo',
  'Promo 2' = 'Promo 2',
  'SR' = 'SR',
  'Double Sided' = 'Double Sided',
  'Back, Single Sided' = 'Back, Single Sided',
  'Front, Single Sided' = 'Front, Single Sided',
  'Sketch' = 'Sketch',
}
export function toScrapedFlag(s: keyof typeof TTSScrapedFlag): TTSScrapedFlag {
  return TTSScrapedFlag[s];
}

export enum TTSScrapedSet {
  'PROMOS' = 'PROMOS',
  'SKETCH CARDS' = 'SKETCH CARDS',
  'Birth of Creation' = 'Birth of Creation',
  'Converging Chasms' = 'Converging Chasms',
  'Crystal Forge' = 'Crystal Forge',
  'Galaxy Tides' = 'Galaxy Tides',
  'The Dimension That Disappeared' = 'The Dimension That Disappeared',
  'Threshold of Time' = 'Threshold of Time',
}
export function toScrapedSet(s: keyof typeof TTSScrapedSet): TTSScrapedSet {
  return TTSScrapedSet[s];
}
