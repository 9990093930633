import {TErrorMonad, IErrorMonad} from './Error';

type TMonad<E,V> = TErrorMonad<E,V>;
type IMonad<E> = IErrorMonad<E>;

export type TInnerMonad<State,E,V> = TMonad<E,{state:State,value:V}>;
export type TStateMonad<State,E,V> = (s:State) => TInnerMonad<State,E,V>;

export const runState
  = <S,E,V>(monad: TStateMonad<S,E,V>, state: S): TInnerMonad<S,E,V> =>
      monad(state);

export type IStateMonad<S,E> = {
  returning: <V>(value:V) => TStateMonad<S,E,V>,

  using:
    <In,Out>(
      monad: TStateMonad<S,E,In>,
      f: (v:In) => TStateMonad<S,E,Out>
    ) => TStateMonad<S,E,Out>,

  fmap:
    <In,Out>(
      monad: TStateMonad<S,E,In>,
      f: (v:In) => Out
    ) => TStateMonad<S,E,Out>,

  lower:
    <In,Out>(
      monad: TStateMonad<S,E,In>,
      f: (
        m:TInnerMonad<S,E,In>,
        inject:<X>(m:TStateMonad<S,E,X>) => TInnerMonad<S,E,X>
      ) => TInnerMonad<S,E,Out>
    ) => TStateMonad<S,E,Out>,

  lift: <V>(m: TMonad<E,V>) => TStateMonad<S,E,V>,

  currentState: (s:S) => TInnerMonad<S,E,S>,

  setState: (s:S) => TStateMonad<S,E,undefined>,
};
export const StateMonadT = <S,E>(M: IMonad<E>): IStateMonad<S,E> => ({
  returning: value => state => M.returning({state, value}),
  using: (monad, f) => state =>
    M.using(runState(monad, state), ({state:newState, value}) =>
      runState(f(value), newState)
    ),
  fmap: (monad, f) => state =>
    M.using(runState(monad, state), ({state:newState, value}) =>
      M.returning({state:newState, value:f(value)}),
    ),
  lower: (monad, f) => state =>
    f(runState(monad, state), m => runState(m, state)),
  lift: m => state => M.using(m, value => M.returning({state, value})),
  currentState: state => M.returning({state, value:state}),
  setState: newState => _ => M.returning({state:newState, value:undefined}),
});
