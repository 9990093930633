import { Form } from 'react-bootstrap';
import Updates from '../Updates';

export interface DeckInsertCardViewProps {
  updates: Updates;
}

export default function DeckInsertCardView({
  updates,
}: DeckInsertCardViewProps) {
  return (
    <Form.Control
      type="text"
      placeholder="Enter cards directly into deck"
      onKeyPress={(e: React.KeyboardEvent<HTMLInputElement>) => {
        if (e.key === 'Enter') {
          updates.addCardBySearchQuery((e.target as HTMLInputElement).value);
        }
      }}
    />
  );
}
