import { AppCard, Deck } from '../types/AppState';
import { TTSDeckState, TTSMod, TTSSingleCardObjectState } from '../types/TabletopSimulator';
import { ExodusCardToTTS } from './ExodusCardToTTS';

const EXODUS_CARDBACK_IMG =
  'http://cloud-3.steamusercontent.com/ugc/768365068750455723/A2E8BBF482ED5B11D5A19D86A38A6FB03ED2A592/';

const downloadFile = async (
  myData: unknown,
  filename: String
): Promise<void> => {
  const fileName = filename;
  const json = JSON.stringify(myData);
  const blob = new Blob([json], { type: 'application/json' });
  const href = URL.createObjectURL(blob);
  const link = document.createElement('a');
  link.href = href;
  link.download = fileName + '.json';
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
};

function exportDeck(cardMap: Deck, name: String) {
  // Base custom deck postion.
  const mainDeck: TTSDeckState = {
    Name: 'DeckCustom',
    ContainedObjects: [],
    DeckIDs: [],
    CustomDeck: {},
    Transform: {
      posX: 0,
      posY: 1,
      posZ: 0,
      rotX: 0,
      rotY: 180,
      rotZ: 180,
      scaleX: 1,
      scaleY: 1,
      scaleZ: 1,
    },
  };
  const backsideDeck: TTSDeckState = {
    Name: 'DeckCustom',
    ContainedObjects: [],
    DeckIDs: [],
    CustomDeck: {},
    Transform: {
      posX: 3,
      posY: 0,
      posZ: 0,
      rotX: 0,
      rotY: 180,
      rotZ: 0,
      scaleX: 1,
      scaleY: 1,
      scaleZ: 1,
    },
  };

  let nextCid = 1;

  cardMap.forEach((card: AppCard) => {
    const cid = nextCid++;
    let backsideCid;
    mainDeck.CustomDeck[String(cid)] = {
      FaceURL:
        ExodusCardToTTS.get(card.card_id)?.FaceURL ?? card.large_image_url,
      BackURL: EXODUS_CARDBACK_IMG,
      NumHeight: 1,
      NumWidth: 1,
      BackIsHidden: true,
    };
    if (card.backside_large_image_url) {
      backsideCid = nextCid++;
      backsideDeck.CustomDeck[String(backsideCid)] = {
        FaceURL:
          ExodusCardToTTS.get(card.card_id)?.BackURL ??
          card.backside_large_image_url,
        BackURL: EXODUS_CARDBACK_IMG,
        NumHeight: 1,
        NumWidth: 1,
        BackIsHidden: true,
      };
    }
    for (let i = 0; i < card.quantity; i++) {
      mainDeck.DeckIDs.push(cid * 100);
      mainDeck.ContainedObjects.push({
        CardID: cid * 100,
        Name: 'CardCustom',
        Nickname: card.name,
        Transform: {
          posX: 0,
          posY: 0,
          posZ: 0,
          rotX: 0,
          rotY: 180,
          rotZ: 180,
          scaleX: 1,
          scaleY: 1,
          scaleZ: 1,
        },
      });
      if (backsideCid !== undefined) {
        backsideDeck.DeckIDs.push(backsideCid * 100);
        backsideDeck.ContainedObjects.push({
          CardID: backsideCid * 100,
          Name: 'CardCustom',
          Nickname: `${card.name} (Backside)`,
          Transform: {
            posX: 0,
            posY: 0,
            posZ: 0,
            rotX: 0,
            rotY: 180,
            rotZ: 180,
            scaleX: 1,
            scaleY: 1,
            scaleZ: 1,
          },
        });
      }
    }
  });
  function convertDeckToObjects(
    state: TTSDeckState
  ): Array<TTSDeckState | TTSSingleCardObjectState> {
    if (state.DeckIDs.length === 0) {
      return [];
    }
    if (state.DeckIDs.length === 1) {
      return [{
        ...state.ContainedObjects[0],
        CustomDeck: state.CustomDeck,
        Transform: state.Transform,
      }];
    }
    return [state];
  }
  const ttsBase: TTSMod = {
    ObjectStates: [
      ...convertDeckToObjects(mainDeck),
      ...convertDeckToObjects(backsideDeck),
    ],
  };
  if (ttsBase.ObjectStates.length > 0) {
    downloadFile(ttsBase, name || 'untitledDeck');
  }
}

export default exportDeck;
