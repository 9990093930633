import { ListGroup, Container, Row, Col } from 'react-bootstrap';

export default function SearchHelp() {
  return (
    <ListGroup variant="flush">
      {FORMATS.map((format) => (
        <ListGroup.Item key={format.title}>
          <FormatRow format={format} />
        </ListGroup.Item>
      ))}
    </ListGroup>
  );
}

function FormatRow({ format }: { format: Format }) {
  return (
    <Container fluid>
      <Row>
        <h5>{format.title}</h5>
      </Row>
      <Row>
        <Col md={6}>{format.description}</Col>
        <Col md={6}>
          {format.examples.map((ex) => (
            <ListGroup className="pt-2">
              <ListGroup.Item>
                <Container>
                  <Row>
                    <code>{ex.search}</code>
                  </Row>
                  <Row>{ex.result}</Row>
                </Container>
              </ListGroup.Item>
            </ListGroup>
          ))}
        </Col>
      </Row>
    </Container>
  );
}

interface Format {
  title: string;
  description: JSX.Element;
  examples: {
    search: string;
    result: JSX.Element;
  }[];
}

export const FORMATS: Format[] = [
  {
    title: 'Name',
    description: (
      <p>
        Search for card by name by typing words in that cards name. Use <code>name=</code> to match the
        exact name you entered.
      </p>
    ),
    examples: [
      {
        search: 'alana',
        result: (
          <p>
            The card named <b>Alana the Star Formation</b>
          </p>
        ),
      },
      {
        search: 'val',
        result: (
          <p>
            Cards like <b>Valchron</b> and <b>Overgrown Trench Valchasm</b>
          </p>
        ),
      },
      {
        search: 'name=chains',
        result: (
          <p>
            Just the card <b>Chains</b>, and not <b>Chains of the Ancient One</b>
          </p>
        ),
      },
    ],
  },
  {
    title: 'Effect/Ability',
    description: (
      <p>
        Use <code>e:</code> or <code>effect:</code> to search for cards that have specific words in their
        effect/ability. Use <code>e:*</code> to search for cards that have an effect or ability.
      </p>
    ),
    examples: [
      {
        search: 'e:draw',
        result: (
          <p>
            Cards like <b>Vortex</b> and <b>Forerunner of Eclipses</b>.
          </p>
        ),
      },
      {
        search: 'effect:draw',
        result: (
          <p>
            Cards like <b>Vortex</b> and <b>Forerunner of Eclipses</b>.
          </p>
        ),
      },
      {
        search: 'e:*',
        result: (
          <p>
            Cards with effects/abilities. Will not include creatures without abilities like <b>Dimensional Desperado</b>
            .
          </p>
        ),
      },
    ],
  },
  {
    title: 'Card/Creature Type',
    description: (
      <p>
        Use <code>t:</code> or <code>type:</code> to search for cards of a specific card or creature type. Use{' '}
        <code>creature_type_count:</code> to search for cards with specific number of creature types.
      </p>
    ),
    examples: [
      {
        search: 't:symmetry',
        result: <p>Symmetries (including double sided cards like Anathema)</p>,
      },
      {
        search: 't:chrono',
        result: (
          <p>
            Chrono creatures (including creatures with multiple types like <b>Wanderwolf the Inner Fear</b>)
          </p>
        ),
      },
      {
        search: 'creature_type_count:1',
        result: <p>Creatures with exactly one creature type</p>,
      },
      {
        search: 'creature_type_count:>1', // TODO: support creature_type_count>1 & t>1
        result: <p>Creatures with multiple types</p>,
      },
    ],
  },
  {
    title: 'Rarity',
    description: (
      <p>
        Search for cards of a specific rarity with <code>r:</code> or <code>rarity:</code>. Use{' '}
        <code>rarity=</code> to match the full word (i.e. to filter out Secret/Harmony Rares).
      </p>
    ),
    examples: [
      {
        search: 'r:common',
        result: <p>Common cards</p>,
      },
      {
        search: 'r:uncommon',
        result: <p>Uncommon cards</p>,
      },
      {
        search: 'r:*common',
        result: <p>Common and Uncommon cards</p>,
      },
      {
        search: 'r:rare',
        result: <p>All kinds of rares: Rare, Secret Rare, Harmony Rare</p>,
      },
      {
        search: 'r:secret',
        result: <p>Secret Rares only</p>,
      },
      {
        search: 'r=Rare',
        result: <p>Just rare cards</p>,
      },
    ],
  },
  {
    title: 'Edition',
    description: (
      <p>
        Use <code>ed:</code> or <code>edition:</code> to search for specific editions. By default the search results
        will only show one of the editions of a card. Use <code>ed:*</code> to override this default and show all
        editions.
      </p>
    ),
    examples: [
      {
        search: 'ed:First',
        result: <p>First editions</p>,
      },
      {
        search: 'ed:promo',
        result: <p>Promo cards</p>,
      },
      {
        search: 'ed:limited',
        result: <p>Sketch cards</p>,
      },
      {
        search: 'ed:*',
        result: <p>To show all editions</p>,
      },
    ],
  },
  {
    title: 'Artist',
    description: (
      <p>
        Use <code>a:</code> or <code>artist:</code> to search for cards illustrated by a specific artist.
      </p>
    ),
    examples: [
      {
        search: 'a:Roy',
        result: (
          <p>
            Cards illustrated by <b>Roy Ugang</b>
          </p>
        ),
      },
    ],
  },
  {
    title: 'Set Name',
    description: (
      <p>
        Use <code>s:</code> or <code>set:</code> to search for cards printed in a specific set.
      </p>
    ),
    examples: [
      {
        search: 's:"Galaxy Tides"',
        result: (
          <p>
            Cards in <b>Galaxy Tides</b> boosters.
          </p>
        ),
      },
    ],
  },
  {
    title: 'Multiple Filters',
    description: (
      <p>
        You can add multiple search filters separated by spaces or <code>and</code> to find cards that match all of
        them. Use <code>or</code> to combine multilpe search results. Use parentheses when there are both{' '}
        <code>and</code>s and <code>or</code>s.
      </p>
    ),
    examples: [
      {
        search: 'Vortex ed:First',
        result: <p>Show the first edition printing of Vortex</p>,
      },
      {
        search: 't:angel or t:dragon',
        result: (
          <p>
            Creatures that are <b>either</b> a Angel or Dragon or <b>both</b>.
          </p>
        ),
      },
      {
        search: 'r:rare (t:angel or t:dragon)',
        result: (
          <p>
            Only Rare creatures that are <b>either</b> a Angel or Dragon or <b>both</b>.
          </p>
        ),
      },
    ],
  },
  {
    title: 'Exclude',
    description: (
      <p>
        Add <code>-</code> before a filter to remove cards that match from the search results.
      </p>
    ),
    examples: [
      {
        search: '-r:rare',
        result: <p>Cards that are not Rare. i.e. Commons and Uncommons.</p>,
      },
      {
        search: 't:creature -(t:angel or t:dragon)',
        result: (
          <p>
            Only creatures that are <b>not</b> a Angel nor a Dragon.
          </p>
        ),
      },
    ],
  },
  {
    title: 'Your Saved Decks',
    description: (
      <p>
        Use <code>d:</code> or <code>deck:</code> to filter for only cards in a specific deck you have saved.
        And use <code>cd:</code> or <code>current_deck:</code> to filter for cards that are in your current (unsaved) deck. For
        example, you can have a deck that represents cards that you own, and then use this filter to look for specific
        kinds of cards that you own.
      </p>
    ),
    examples: [
      {
        search: 'd:MyDeck',
        result: (
          <p>
            Only cards that exist in a deck you have <b>saved</b> named "MyDeck".
          </p>
        ),
      },
      {
        search: 'deck:mydec',
        result: (
          <p>
            The deck filter is case-insensitive and will try to match against the decks you have.
          </p>
        ),
      },
      {
        search: 'current_deck',
        result: (
          <p>
            Show cards that are in your current (unsaved) deck.
          </p>
        ),
      },
      {
        search: 'cd',
        result: (
          <p>
            Shorthand for "current_deck"
          </p>
        ),
      },
    ],
  },
];
