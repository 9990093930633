export type TErrorMonad<E,V>
  = {
      type: 'Success',
      value: V,
    }
  | {
      type: 'Error',
      error: E,
    };

export const MSuccess =
  <E,V>(value:V): TErrorMonad<E,V> => ({type:'Success', value});
export const MError =
  <E,V>(error:E): TErrorMonad<E,V> => ({type:'Error', error});

export function runError<E,V,Result>(
  monad: TErrorMonad<E,V>,
  {ifSuccess, ifError}: {
    ifSuccess:(v:V)=>Result,
    ifError:(error:E)=>Result,
  }
): Result {
  switch (monad.type) {
    case 'Success':
      return ifSuccess(monad.value);
    case 'Error':
      return ifError(monad.error);
  }
}

export type IErrorMonad<E> = {
  returning: <V>(value:V) => TErrorMonad<E,V>,
  using:
    <In,Out>(
      monad: TErrorMonad<E,In>,
      f: (v:In) => TErrorMonad<E,Out>
    ) => TErrorMonad<E,Out>,
};
export const ErrorMonad: <E>()=>IErrorMonad<E> = <E>() => ({
  returning: MSuccess,
  using: <In,Out>(monad: TErrorMonad<E,In>, f: (v:In) => TErrorMonad<E,Out>) =>
    runError<E,In,TErrorMonad<E,Out>>(monad, {
      ifSuccess: f,
      ifError: MError,
    }),
});
