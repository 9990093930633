import { Maybe } from '../types/Maybe';
import Fuse from 'fuse.js';
import { ExodusCard } from '../types/ExodusCard';
import { ExodusCardsJson } from './ExodusCards';
import { Deck, SavedDecks } from '../types/AppState';

export enum FuseField {
  name = 'name',
  set_name = 'set_name',
  set_number = 'set_number',
  creature_type = 'creature_type',
  card_type = 'card_type',
  artist = 'artist',
  effect = 'effect',
  ruling = 'ruling',
  foil_only = 'foil_only',
}
export const FUSE_FIELDS: Map<string, FuseField> = new Map(
  Object.entries(FuseField)
);
const WEIGHT_OVERRIDES: Map<FuseField, number> = new Map().set('name', 10);

export const Index = new Fuse(ExodusCardsJson, {
  threshold: 0.2,
  ignoreLocation: true,
  includeScore: true,
  useExtendedSearch: true,
  keys: Array.from(FUSE_FIELDS.values()).map((key) => ({
    name: key,
    weight: WEIGHT_OVERRIDES.get(key) ?? 1,
  })),
});

export type FuseQuery =
  | FuseOr
  | FuseAnd
  | FuseTerm
  | FuseInvalid
  | SearchCardDataQuery
  | SearchNot;
// NOTE: don't include FuseTerm, since that
// doesn't guarentee that all results are returned.
type SearchCardDataQuery =
  | SearchHasBooleanField
  | SearchNumericField
  | SearchCurrentDeck
  | SearchSavedDeck
  | SearchFieldExists
  | SearchEnumField;
export class FuseOr {
  readonly type = 'Or';
  constructor(
    public readonly left: FuseQuery,
    public readonly right: FuseQuery
  ) {}
}
export class FuseAnd {
  readonly type = 'And';
  constructor(
    public readonly left: FuseQuery,
    public readonly right: FuseQuery
  ) {}
}
export class FuseTerm {
  readonly type = 'Term';
  constructor(
    public readonly props: {
      field?: Maybe<FuseField>;
      contains: string;
      negate?: boolean;
      onlyExactMatch?: boolean;
    }
  ) {}
}
export enum BooleanField {
  'is_default_edition' = 'is_default_edition',
}
export enum NumericField {
  'creature_type_count' = 'creature_type_count',
}
export enum EnumField {
  'rarity' = 'rarity',
  'edition' = 'edition',
}
export class SearchHasBooleanField {
  readonly type = 'HasBooleanField';
  constructor(public readonly field: BooleanField) {}
}
export class SearchEnumField {
  readonly type = 'EnumField';
  constructor(
    public readonly props: {
      field: EnumField;
      comparisonOperator: '=' | ':' | ':*';
      comparedTo: string;
    }
  ) {}
}
export class SearchNumericField {
  readonly type = 'NumericField';
  constructor(
    public readonly props: {
      field: NumericField;
      comparisonOperator: '<' | '>' | '<=' | '>=' | '=';
      comparedTo: number;
    }
  ) {}
}
export class SearchCurrentDeck {
  readonly type = 'CurrentDeck';
}
export class SearchSavedDeck {
  readonly type = 'SavedDeck';
  constructor(public readonly deckName: string) {}
}
export class SearchFieldExists {
  readonly type = 'FieldExists';
  constructor(public readonly field: FuseField) {}
}
export class SearchNot {
  readonly type = 'Not';
  constructor(public readonly inner: SearchCardDataQuery) {}
}
export class FuseInvalid {
  readonly type = 'Invalid';
  constructor(public readonly errorMsgs: Array<string>) {}
}

export type FuseMaybe<T> = FuseWasInvalid<T> | FuseDidFilter<T>;
export class FuseWasInvalid<T> {
  readonly type = 'WasInvalid';
  constructor(public readonly error: FuseInvalid) {}
  withResults<O>(_: (_: T) => O): FuseWasInvalid<O> {
    return new FuseWasInvalid(this.error);
  }
}
export class FuseDidFilter<T> {
  readonly type = 'DidFilter';
  constructor(public readonly results: T) {}
  withResults<O>(f: (_: T) => O): FuseDidFilter<O> {
    return new FuseDidFilter(f(this.results));
  }
}

export function search(
  q: FuseQuery,
  params: FuseSearchParams
): FuseMaybe<Array<ExodusCard>> {
  return runFuseQuery(q, params).withResults((r) =>
    Array.from(r.entries())
      .sort(([_cardL, scoreL], [_cardR, scoreR]) => scoreL - scoreR)
      .map(([card, _score]) => card)
  );
}
// TODO: extract into corresponding functions
function runFuseQuery(
  q: FuseQuery,
  params: FuseSearchParams
): FuseMaybe<Map<ExodusCard, number>> {
  const toMap = <T>(list: T[]): Map<T, number> =>
    new Map(list.map((c) => [c, 1]));
  switch (q.type) {
    case 'Invalid':
      return new FuseWasInvalid(q);
    case 'Not':
      return runFuseQuery(q.inner, params).withResults((r) =>
        toMap(ExodusCardsJson.filter((c) => !r.has(c)))
      );
    case 'CurrentDeck':
      return new FuseDidFilter(
        toMap(ExodusCardsJson.filter((c) => params.cards.has(c.card_id)))
      );
    case 'SavedDeck': {
      let deck = params.savedDecks.get(q.deckName);
      if (!deck) {
        const matchedDecks = Array.from(params.savedDecks.entries())
          .filter(
            ([name, _]) =>
              name.toLowerCase().indexOf(q.deckName.toLowerCase()) >= 0
          )
          .map(([_, deck]) => deck);
        deck = matchedDecks[0];
        if (!deck || matchedDecks.length > 1) {
          return new FuseWasInvalid(new FuseInvalid(['Could not find deck']));
        }
      }
      const cardIdSet = new Set(deck.map(([cardId, _]) => cardId));
      return new FuseDidFilter(
        toMap(ExodusCardsJson.filter((c) => cardIdSet.has(c.card_id)))
      );
    }
    case 'HasBooleanField':
      return new FuseDidFilter(
        toMap(ExodusCardsJson.filter((c) => c[q.field] === true))
      );
    case 'NumericField': {
      const filter = (c: ExodusCard): boolean => {
        const number = c[q.props.field];
        if (number === undefined) {
          return false;
        }
        const comparedTo = q.props.comparedTo;
        switch (q.props.comparisonOperator) {
          case '=':
            return number === comparedTo;
          case '<':
            return number < comparedTo;
          case '<=':
            return number <= comparedTo;
          case '>':
            return number > comparedTo;
          case '>=':
            return number >= comparedTo;
        }
      };
      return new FuseDidFilter(toMap(ExodusCardsJson.filter(filter)));
    }
    case 'EnumField': {
      const escapedContains = q.props.comparedTo.replace(
        /[.*+?^${}()|[\]\\]/g,
        '\\$&'
      );
      let regexStr;
      switch (q.props.comparisonOperator) {
        case '=':
          regexStr = String.raw`^${escapedContains}$`;
          break;
        case ':':
          regexStr = String.raw`\b${escapedContains}`;
          break;
        case ':*':
          regexStr = String.raw`${escapedContains}`;
          break;
      }
      const regex = new RegExp(regexStr, 'i');
      const notUndefinedOrNull = <T>(x: T | undefined | null) =>
        x !== undefined && x !== null;
      return new FuseDidFilter(
        toMap(
          ExodusCardsJson.filter((c) =>
            notUndefinedOrNull(c[q.props.field]?.match(regex))
          )
        )
      );
    }
    case 'FieldExists': {
      const filter = (c: ExodusCard): boolean => {
        const data = c[q.field];
        if (data === undefined) {
          return false;
        }
        switch (typeof data) {
          case 'string':
            return data !== '';
          case 'object':
            return data.length > 0;
          case 'number':
            return data > 0;
        }
      };
      return new FuseDidFilter(toMap(ExodusCardsJson.filter(filter)));
    }
    case 'Or': {
      const left = runFuseQuery(q.left, params);
      const right = runFuseQuery(q.right, params);
      if (left.type === 'WasInvalid' && right.type === 'WasInvalid') {
        return new FuseWasInvalid(
          new FuseInvalid(
            left.error.errorMsgs.concat(right.error.errorMsgs)
          )
        );
      }
      if (left.type === 'WasInvalid') {
        return left;
      }
      if (right.type === 'WasInvalid') {
        return right;
      }
      const union: Map<ExodusCard, number> = new Map(left.results);
      for (const [c, score] of right.results.entries()) {
        const existingScore = union.get(c);
        if (existingScore !== undefined) {
          union.set(c, existingScore * score);
        } else {
          union.set(c, score);
        }
      }
      return new FuseDidFilter(union);
    }
    case 'And': {
      const left = runFuseQuery(q.left, params);
      const right = runFuseQuery(q.right, params);
      if (left.type === 'WasInvalid' && right.type === 'WasInvalid') {
        return new FuseWasInvalid(
          new FuseInvalid(
            left.error.errorMsgs.concat(right.error.errorMsgs)
          )
        );
      }
      if (left.type === 'WasInvalid') {
        return left;
      }
      if (right.type === 'WasInvalid') {
        return right;
      }
      const intersection: Map<ExodusCard, number> = new Map();
      for (const [c, leftScore] of left.results.entries()) {
        const rightScore = right.results.get(c);
        if (rightScore !== undefined) {
          intersection.set(c, leftScore * rightScore);
        }
      }
      return new FuseDidFilter(intersection);
    }
    case 'Term':
      const { field, negate: negateBool, onlyExactMatch, contains } = q.props;
      if (negateBool) {
        const escapedContains = contains.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');
        const fieldList: FuseField[] =
          field !== undefined ? [field] : Object.values(FuseField);
        return new FuseDidFilter(
          toMap(
            ExodusCardsJson.filter((card) =>
              fieldList.every((f) => {
                const cardData = card[f];
                const stringsToSearch =
                  cardData === undefined
                    ? []
                    : typeof cardData === 'string'
                    ? [cardData]
                    : cardData;
                return stringsToSearch.every(
                  (s) =>
                    !s.match(
                      new RegExp(
                        onlyExactMatch
                          ? String.raw`^${escapedContains}$`
                          : String.raw`\b${escapedContains}\b`,
                        'i'
                      )
                    )
                );
              })
            )
          )
        );
      }
      const prefix = onlyExactMatch ? '=' : '';
      const termString = `${prefix}${JSON.stringify(contains)}`;
      const rawFuseTerm =
        field !== undefined
          ? { [field]: termString }
          : {
              $or: Array.from(FUSE_FIELDS.values()).map((field) => ({
                [field]: termString,
              })),
            };
      return new FuseDidFilter(
        new Map(Index.search(rawFuseTerm).map((e) => [e.item, e.score ?? 1]))
      );
  }
}
export function negate(q: FuseQuery): FuseQuery {
  switch (q.type) {
    case 'Or':
      return new FuseAnd(negate(q.left), negate(q.right));
    case 'And':
      return new FuseOr(negate(q.left), negate(q.right));
    case 'Invalid':
      return q;
    case 'Term':
      return new FuseTerm({ ...q.props, negate: !q.props.negate });
    case 'Not':
      return q.inner;
    case 'HasBooleanField':
    case 'NumericField':
    case 'CurrentDeck':
    case 'SavedDeck':
    case 'FieldExists':
    case 'EnumField':
      return new SearchNot(q);
  }
}

export interface FuseSearchParams {
  savedDecks: SavedDecks;
  cards: Deck;
}

export const DEFAULT_SEARCH_RESULTS = ExodusCardsJson.filter(
  (c) => c.is_default_edition
);
